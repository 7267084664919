import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    marginLeft: '3%',
    marginRight: '3%',
    marginTop: '1rem',
    width: 'auto',
    justifyContent: 'center',
  },
  innerGrid: {
    margin: '0.5rem',
  },
  title: {
    fontSize: '1.5rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: 275,
    backgroundColor: '#DCDCDC'
  },
  cardActions: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  create: {
    marginTop: '2rem',
    textAlign: 'center'
  }
});

import React, {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useGlobalContext} from '../libs/context-lib';
import * as OrgaService from '../services/organisation-service';
import * as UserService from '../services/user-service';

export default function AuthenticatedRoute({children}: any) {
    const {pathname} = useLocation();
    const {search} = useLocation();
    const {
        isAuthenticated,
        currentOrga,
        setCurrentOrga,
        userInfo,
        setCurrContainer,
    } = useGlobalContext();

    // if inviteKey in search params set orga or send request
    useEffect(() => {
        const query = new URLSearchParams(search);
        if (query.get('inviteKey')) {
            setCurrentOrga({id: '', name: ''});
            const key = query.get('inviteKey');
            let alreadyIn = false;
            userInfo.groups.forEach((group) => {
                if (group.substring(0, 36) === key) {
                    alreadyIn = true;
                    OrgaService.getOrgaByGroup(group).then(
                        (data) => {
                            if (data) {
                                setCurrentOrga(data);
                                setCurrContainer(1);
                            }
                        },
                        (e) => console.log(e, 'FetchOrga failed')
                    );
                }
            });
            if (!alreadyIn) {
                UserService.userRequestToGroup(key!, userInfo.sub);
            }
        }
    }, [search, setCurrContainer, setCurrentOrga, userInfo.groups, userInfo.sub]);

    useEffect(() => {
        if (!isAuthenticated) {
            const query = new URLSearchParams(search);
            if (query.get('inviteKey')) {
                localStorage.setItem('inviteKey', query.get('inviteKey')!);
            }
        }
    }, [isAuthenticated, search]);

    return isAuthenticated && currentOrga.id !== '' ? children : isAuthenticated ? (
        <Navigate to={`/select-orga?redirect=${pathname}`}/>
    ) : (
        <Navigate to={`/login?redirect=${pathname}`}/>
    );
}

import {
    Button,
    FormControlLabel,
    Paper,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {IEvent} from '../../models/interfaces/event-interface';
import {getDateDeByTimestamp} from '../../utils/iso-timestamp-converter';
import {ConfirmDialog} from '../shared/confirm-dialog';
import {useStyles} from './dialogs/event-details-styles';
import {useGlobalContext} from '../../libs/context-lib';
import {DialogCreateSchedule} from '../calendar/dialogs/dialog-create-schedule';
import { useTheme } from '@mui/material/styles';

interface IProps {
    event: IEvent;
    events: IEvent[];
    updateEvent: (event: IEvent) => void;
    deleteEvent: (event: IEvent) => void;
    setCurrEvent: (eventId: string) => void;
    openBlockerDialog: () => void;
    openEditScheduleDialog: () => void;
}

export const CurrentEvent = (props: IProps) => {
    const classes = useStyles(useTheme())();
    const {t} = useTranslation();
    const {setCurrentEvent} = useGlobalContext();
    const [events, setEvents] = useState<IEvent[]>();
    const [event, setEvent] = useState<IEvent>();
    const [selectetEvent, setSelectetEvent] = useState<string>();
    const [currEventDialog, setCurrEventDialog] = useState(false);
    const [cancelEventDialog, setCancelEventDialog] = useState(false);
    const [archiveEventDialog, setArchiveEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [openCreateScheduleDialog, setOpenCreateScheduleDialog] = useState(
        false
    );
    const navigate = useNavigate();

    useEffect(() => {
        setSelectetEvent(props.event.id);
        setEvents(props.events);
        setEvent(props.event);
    }, [props.event, props.events]);

    const handleSwitchChange = (input: React.ChangeEvent<HTMLInputElement>) => {
        const name = input.target.name;
        const newEvent = {...event, [name]: !input.target.checked} as IEvent;
        setEvent(newEvent);
        props.updateEvent(newEvent);
    };

    const handleManageRooms = () => {
        navigate('/rooms', {state: {eventId: event!.id}});
    };

    const handleChangeSelect = (
        event: SelectChangeEvent
    ) => {
        setSelectetEvent(event.target.value as string);
    };

    const handleCloseCurrDialog = async (value: boolean) => {
        value && (await props.setCurrEvent(selectetEvent!));
        setCurrEventDialog(false);
    };

    const handleCloseCancelDialog = async (value: boolean) => {
        if (value) {
            const newEvent = {
                ...event,
                eventStatus: 'canceled',
                submitClosed: true,
                votingClosed: true,
            } as IEvent;
            setEvent(newEvent);
            props.updateEvent(newEvent);
        }
        setCancelEventDialog(false);
    };

    const handleCloseArchiveDialog = async (value: boolean) => {
        if (value) {
            const newEvent = {
                ...event,
                eventStatus: 'archived',
                archived: true,
                submitClosed: true,
                votingClosed: true,
            } as IEvent;
            setEvent(newEvent);
            props.updateEvent(newEvent);
        }
        setArchiveEventDialog(false);
    };

    const handleCloseDeleteDialog = async (value: boolean) => {
        if (value) {
            props.deleteEvent(event!);
            setEvent({
                ...event,
                id: '',
            } as IEvent);
        }
        setDeleteEventDialog(false);
    };

    const handleOpenCreateScheduleDialog = () => {
        setOpenCreateScheduleDialog(true);
    };

    const handleStartChange = (newTime: string) => {
        if (event) {
            const newEvent = {...event!, starttime: newTime};
            setEvent(newEvent);
            props.updateEvent(newEvent);
        }
    };

    const handleEndChange = (newTime: string) => {
        if (event) {
            const newEvent = {...event!, endtime: newTime};
            setEvent(newEvent);
            props.updateEvent(newEvent);
        }
    };

    return (
        <>
            {event !== undefined ? (
                <div className={classes.root}>
                    <Paper className={classes.content}>
                        <Paper className={classes.button}>
                            <Typography
                                variant='h5'
                                style={{textAlign: 'center', margin: '0rem 2rem 0rem'}}
                            >
                                {t('label.currentEvent')}
                            </Typography>
                            <Select
                                native
                                value={selectetEvent}
                                onChange={handleChangeSelect}
                            >
                                <option aria-label='None' value=''>
                                    {t('label.none')}
                                </option>
                                {events?.map((event) => (
                                    <option value={event.id} key={event.id}>
                                        {getDateDeByTimestamp(event.date)}
                                    </option>
                                ))}
                            </Select>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setCurrEventDialog(true);
                                }}
                                style={{margin: '14px'}}
                            >
                                {t('label.save')}
                            </Button>
                        </Paper>
                        {props.event.id !== '' && (
                            <>
                                <div>
                                    {t('label.eventStatus')}: {t(`label.${event.eventStatus}`)}
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!event.submitClosed}
                                                onChange={handleSwitchChange}
                                                name='submitClosed'
                                                color='primary'
                                                disabled={
                                                    event.archived || event.eventStatus === 'canceled'
                                                }
                                            />
                                        }
                                        label={t('text.sessioncreationEnabling')}
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!event.votingClosed}
                                                onChange={handleSwitchChange}
                                                name='votingClosed'
                                                color='primary'
                                                disabled={
                                                    event.archived || event.eventStatus === 'canceled'
                                                }
                                            />
                                        }
                                        label={t('text.votingEnabling')}
                                    />
                                </div>
                                <div>
                                    <div
                                        style={{
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <TextField
                                            id='starttime-tf'
                                            label={t('label.starttime')}
                                            type='time'
                                            value={event.starttime}
                                            onChange={(change) =>
                                                handleStartChange(change.target.value)
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{marginRight: '1rem'}}
                                        />
                                        <TextField
                                            id='endtime-tf'
                                            label={t('label.endtime')}
                                            type='time'
                                            value={event.endtime}
                                            onChange={(change) =>
                                                handleEndChange(change.target.value)
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.button}>
                                    <Button variant='contained' onClick={handleManageRooms}>
                                        {t('label.manageRooms')}
                                    </Button>
                                </div>
                                <div className={classes.button}>
                                    <Button variant='contained' onClick={props.openBlockerDialog}>
                                        {t('label.manageBlocker')}
                                    </Button>
                                </div>
                                <div className={classes.button}>
                                    <Button
                                        variant='contained'
                                        onClick={handleOpenCreateScheduleDialog}
                                    >
                                        {t('label.createSchedule')}
                                    </Button>
                                </div>
                                <div className={classes.button}>
                                    <Button
                                        variant='contained'
                                        onClick={props.openEditScheduleDialog}
                                    >
                                        {t('label.editSchedule')}
                                    </Button>
                                </div>
                                <div className={classes.button} style={{paddingTop: '2rem'}}>
                                    <Button
                                        onClick={() => {
                                            setCancelEventDialog(true);
                                        }}
                                        variant='contained'
                                        disabled={
                                            event.archived || event.eventStatus === 'canceled'
                                        }
                                    >
                                        {t('label.cancelEvent')}
                                    </Button>
                                </div>
                                <div className={classes.button}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            setArchiveEventDialog(true);
                                        }}
                                        disabled={
                                            event.archived || event.eventStatus === 'canceled'
                                        }
                                    >
                                        {t('label.archiveEvent')}
                                    </Button>
                                </div>
                                <div className={classes.button}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            setDeleteEventDialog(true);
                                        }}
                                        disabled={event.archived}
                                    >
                                        {t('label.deleteEvent')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Paper>
                    <ConfirmDialog
                        open={cancelEventDialog}
                        handleClose={handleCloseCancelDialog}
                        title={t('title.cancelEventDialog')}
                        text={t('text.cancelEventDialog')}
                    />
                    <ConfirmDialog
                        open={currEventDialog}
                        handleClose={handleCloseCurrDialog}
                        title={t('title.setAsCurrEventDialog')}
                        text={t('text.setAsCurrEventDialog')}
                    />
                    <ConfirmDialog
                        open={archiveEventDialog}
                        handleClose={handleCloseArchiveDialog}
                        title={t('title.archiveEventDialog')}
                        text={t('text.archiveEventDialog')}
                    />
                    <ConfirmDialog
                        open={deleteEventDialog}
                        handleClose={handleCloseDeleteDialog}
                        title={t('title.deleteEventDialog')}
                        text={t('text.deleteEventDialog')}
                    />
                    <DialogCreateSchedule
                        open={openCreateScheduleDialog}
                        handleUpdateEvent={setCurrentEvent}
                        handleClose={setOpenCreateScheduleDialog}
                    />
                </div>
            ) : (
                <div>... loading</div>
            )}
        </>
    );
};

import {
    AppBar,
    Button,
    Dialog,
    FormControlLabel,
    IconButton,
    Paper,
    Switch,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, {useEffect, useState} from 'react';
import {ConfirmDialog} from '../../shared/confirm-dialog';
import {IEvent} from '../../../models/interfaces/event-interface';
import {useNavigate} from 'react-router-dom';
import {useStyles} from './event-details-styles';
import {useTranslation} from 'react-i18next';
import Transition from '../../../utils/transition';
import {useGlobalContext} from '../../../libs/context-lib';
import { useTheme } from '@mui/material/styles';

interface IProps {
    event: IEvent;
    open: boolean;
    handleClose: () => void;
    handleSetAsCurrent: (eventId: string) => void;
    updateEvent: (event: IEvent) => void;
    deleteEvent: (eventId: string) => void;
}

export const EventDetailsFDialog = (props: IProps) => {
    const classes = useStyles(useTheme())();
    const {t} = useTranslation();
    const [currentDialog, setCurrentDialog] = useState(false);
    const [cancelEventDialog, setCancelEventDialog] = useState(false);
    const [archiveEventDialog, setArchiveEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [event, setEvent] = useState(props.event);
    const {currentEvent} = useGlobalContext();
    const navigate = useNavigate();

    useEffect(() => {
        setEvent(props.event);
    }, [props.event]);

    const handleSetAsCurrent = () => {
        setCurrentDialog(true);
    };

    const setAsCurrent = (value: boolean) => {
        value && props.handleSetAsCurrent(event.id);
        setCurrentDialog(false);
    };

    const handleCloseCancelDialog = async (value: boolean) => {
        if (value) {
            const newEvent = {
                ...event,
                eventStatus: 'canceled',
                submitClosed: true,
                votingClosed: true,
            } as IEvent;
            setEvent(newEvent);
            props.updateEvent(newEvent);
        }
        setCancelEventDialog(false);
    };

    const handleCloseArchiveDialog = async (value: boolean) => {
        if (value) {
            const newEvent = {
                ...event,
                eventStatus: 'archived',
                archived: true,
                submitClosed: true,
                votingClosed: true,
            } as IEvent;
            setEvent(newEvent);
            props.updateEvent(newEvent);
        }
        setArchiveEventDialog(false);
    };

    const handleCloseDeleteDialog = async (value: boolean) => {
        if (value) {
            props.deleteEvent(event!.id);
            setEvent({
                ...event,
                id: '',
            } as IEvent);
        }
        setDeleteEventDialog(false);
    };

    const handleSwitchChange = (input: React.ChangeEvent<HTMLInputElement>) => {
        const name = input.target.name;
        const newEvent = {...event, [name]: !input.target.checked};
        setEvent(newEvent);
        props.updateEvent(newEvent);
    };

    const handleStartChange = (newTime: string) => {
        const newEvent = {...event, starttime: newTime};
        setEvent(newEvent);
        props.updateEvent(newEvent);
    };

    const handleEndChange = (newTime: string) => {
        const newEvent = {...event, endtime: newTime};
        setEvent(newEvent);
        props.updateEvent(newEvent);
    };

    const handleManageRooms = () => {
        navigate('/rooms', {state: {eventId: event.id}});
    };

    return (
        <>
            {event !== undefined ? (
                <Dialog
                    fullScreen
                    open={props.open}
                    onClose={props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={props.handleClose}
                                aria-label='close'
                                style={{border: '1px solid'}}
                            >
                                <ArrowBackIcon/>
                            </IconButton>
                            <Typography variant='h6' className={classes.title}>
                                {t('label.event')} {new Date(event.date).toLocaleDateString()}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.root}>
                        <Paper className={classes.content}>
                            <div>
                                {t('label.eventStatus')}: {t(`label.${event.eventStatus}`)}
                            </div>
                            <div className={classes.button}>
                                <Button
                                    variant='contained'
                                    onClick={handleSetAsCurrent}
                                    disabled={event.archived || event.eventStatus === 'canceled'}
                                    style={
                                        event.id === currentEvent.id
                                            ? {display: 'none'}
                                            : {display: 'inline-flex'}
                                    }
                                >
                                    {t('label.setEventAsCurrent')}
                                </Button>
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!event.submitClosed}
                                            onChange={handleSwitchChange}
                                            name='submitClosed'
                                            color='primary'
                                            disabled={
                                                event.archived || event.eventStatus === 'canceled'
                                            }
                                        />
                                    }
                                    label={t('text.sessioncreationEnabling')}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!event.votingClosed}
                                            onChange={handleSwitchChange}
                                            name='votingClosed'
                                            color='primary'
                                            disabled={
                                                event.archived || event.eventStatus === 'canceled'
                                            }
                                        />
                                    }
                                    label={t('text.votingEnabling')}
                                />
                            </div>
                            <div>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        textAlign: 'center',
                                    }}
                                >
                                    <TextField
                                        id='starttime-tf'
                                        label={t('label.starttime')}
                                        type='time'
                                        value={event.starttime}
                                        onChange={(change) =>
                                            handleStartChange(change.target.value)
                                        }
                                        disabled={
                                            event.archived || event.eventStatus === 'canceled'
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{marginRight: '1rem'}}
                                    />
                                    <TextField
                                        id='endtime-tf'
                                        label={t('label.endtime')}
                                        type='time'
                                        disabled={
                                            event.archived || event.eventStatus === 'canceled'
                                        }
                                        value={event.endtime}
                                        onChange={(change) => handleEndChange(change.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={classes.button}>
                                <Button variant='contained' onClick={handleManageRooms}>
                                    {t('label.manageRooms')}
                                </Button>
                            </div>
                            <div className={classes.button} style={{paddingTop: '2rem'}}>
                                <Button
                                    variant='contained'
                                    disabled={event.archived || event.eventStatus === 'canceled'}
                                    onClick={() => {
                                        setCancelEventDialog(true);
                                    }}
                                >
                                    {t('label.cancelEvent')}
                                </Button>
                            </div>
                            <div className={classes.button}>
                                <Button
                                    variant='contained'
                                    disabled={event.archived || event.eventStatus === 'canceled'}
                                    onClick={() => {
                                        setArchiveEventDialog(true);
                                    }}
                                >
                                    {t('label.archiveEvent')}
                                </Button>
                            </div>
                            <div className={classes.button}>
                                <Button
                                    variant='contained'
                                    disabled={event.archived}
                                    onClick={() => {
                                        setDeleteEventDialog(true);
                                    }}
                                >
                                    {t('label.deleteEvent')}
                                </Button>
                            </div>
                        </Paper>
                    </div>
                    <ConfirmDialog
                        open={currentDialog}
                        handleClose={setAsCurrent}
                        title={t('title.setAsCurrEvent')}
                        text={t('text.reallySetAsCurrEvent')}
                    />
                    <ConfirmDialog
                        open={cancelEventDialog}
                        handleClose={handleCloseCancelDialog}
                        title={t('title.cancelEventDialog')}
                        text={t('text.cancelEventDialog')}
                    />
                    <ConfirmDialog
                        open={archiveEventDialog}
                        handleClose={handleCloseArchiveDialog}
                        title={t('title.archiveEventDialog')}
                        text={t('text.archiveEventDialog')}
                    />
                    <ConfirmDialog
                        open={deleteEventDialog}
                        handleClose={handleCloseDeleteDialog}
                        title={t('title.deleteEventDialog')}
                        text={t('text.deleteEventDialog')}
                    />
                </Dialog>
            ) : (
                <Dialog
                    fullScreen
                    open={props.open}
                    onClose={props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={props.handleClose}
                                aria-label='close'
                            >
                                <ArrowBackIcon/>
                            </IconButton>
                        </Toolbar>
                        <div>...waiting for data</div>
                    </AppBar>
                </Dialog>
            )}
        </>
    );
};

import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect } from 'react';
import { useStyles } from './editable-table-styles';
import { useTranslation } from 'react-i18next';

interface IProps {
  dataSource: any[]; // table data
  columns: { prop: string; colSpan?: number; type?: string }[]; // only defined columns will displayed
  addDataRow(): any; // init new row in dataSource
  updateDataSource(data: any): void; // update dataSource
  handleUpdateData(newRow: any, oldRow: any): void; // handler update in database
  handleCreateData(row: any, oldRow: any): void; // handler create in database
  handleDeleteData?(row: any): void; // handler delete in database (optional)
  handleTableButtonClick?(row: any, col: string): void;
}

/**
 * Dynamic editable table with useTranslation i18next.
 * Recieve data and headers, headers === data.properties.
 * Header naming will be set in translation files.
 */
export const EditableTable = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>(); // manipulated DataSource version
  const [onEdit, setOnEdit] = useState<any>({ tableId: 0 }); // default tableId: to check if empty
  const [onDelete, setOnDelete] = useState(0); // default tableId: to check if empty
  const [onCreate, setOnCreate] = useState(false); // to handle create and edit line diff
  const [oldRow, setOldRow] = useState<any>(); // saveOld Version to Replace in DataSource

  useEffect(() => {
    if (props.dataSource) {
      // deep clone
      const newData: any = [];
      props.dataSource.forEach((val) => newData.push(Object.assign({}, val)));

      for (let i = 0; i < newData.length; i++) {
        newData[i].tableId = i + 1;
      }
      setData(newData);
    }
  }, [props.dataSource, props.columns]);

  // update data if edited row get changes
  useEffect(() => {
    if (data) {
      if (onEdit.tableId !== 0 && onCreate === false) {
        const newData = [...data];
        const index = newData.indexOf(oldRow!);
        if (index !== -1) {
          newData[index] = onEdit;
          setData(newData);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEdit, oldRow]);

  // handle add new row
  const handleAdd = () => {
    setOnCreate(true);
    const newData = props.addDataRow();
    newData.tableId = props.dataSource.length + 1;
    setOldRow(newData);
    setOnEdit(newData);
  };

  // handle save edited row
  const handleSave = () => {
    delete onEdit.tableId;
    delete oldRow.tableId;
    if (onCreate) {
      props.handleCreateData(onEdit, oldRow);
      setOnCreate(false);
    } else {
      props.handleUpdateData(onEdit, oldRow);
    }
    setOnEdit({ tableId: 0 });
  };

  // handle cancel edite row, restore old version
  const handleEditCancel = () => {
    if (onCreate) {
      // deep clone
      const newDataS: any = [];
      data!.forEach((val) => {
        newDataS.push(Object.assign({}, val));
      });
      const index = newDataS!.findIndex(
        (x: any) => x.tableId === oldRow.tableId
      );
      newDataS!.splice(index, index >= 0 ? 1 : 0);

      // remove tableId
      newDataS.forEach((element: any) => {
        delete element.tableId;
      });

      props.updateDataSource(newDataS);
      setOnCreate(false);
    } else {
      // deep clone & assign new table index
      const newDataS: any = [];
      props.dataSource.forEach((val) => newDataS.push(Object.assign({}, val)));
      for (let i = 0; i < newDataS.length; i++) {
        newDataS[i].tableId = i + 1;
      }

      setData(newDataS);
    }
    setOnEdit({ tableId: 0 });
  };

  // set to delete row
  const handleSetOnDelete = (rowId: any) => {
    setOnDelete(rowId);
  };

  // handle delete row
  const handleDeleteRow = (row: any) => {
    const toDelete = { ...row };
    delete toDelete.tableId;
    props.handleDeleteData!(toDelete);
    setOnDelete(0);
  };

  // set selected row onEdit & save old version for restore
  const handleEdit = (data: any) => {
    setOldRow(data);
    setOnEdit(data);
  };

  // on change set new state for edited row
  const handleChange = (value: string, prop: string) => {
    //setOldRow(row);
    setOnEdit({
      ...onEdit,
      [prop]: value,
    });
  };

  // set 0 default on cancel
  const handleDeleteCancel = () => {
    setOnDelete(0);
  };

  const handleTableButton = (row: any, col: string) => {
    if (props.handleTableButtonClick) {
      const clickedRow = { ...row };
      delete clickedRow.tableId;
      props.handleTableButtonClick(clickedRow, col);
    } else {
      console.warn('define a on Click event "handleTableButtonClick"'!);
    }
  };

  const handleCheckboxClick = (row: any, col: string) => {
    // deep copy
    const newDataS: any = [];
    data!.forEach((val) => newDataS.push(Object.assign({}, val)));
    for (let i = 0; i < newDataS.length; i++) {
      newDataS[i].tableId = i + 1;
    }
    const index = newDataS!.findIndex((x: any) => x.tableId === row.tableId);
    let newElement = { ...newDataS![index] };

    // change value
    newElement[col] = !newElement[col];
    newDataS[index] = newElement;

    // replace states
    setOnEdit({ ...onEdit, [col]: newElement[col] });
    setData(newDataS);
  };

  // default row icons
  const EditDeleteIcons = (iconProps: any) => {
    return (
      <>
        <IconButton onClick={() => handleEdit(iconProps.row)}>
          <EditIcon
            fontSize='large'
            style={{ paddingRight: '0.5rem', color: 'green' }}
          />
        </IconButton>
        {props.handleDeleteData === undefined ? (
          <></>
        ) : (
          <IconButton onClick={() => handleSetOnDelete(iconProps.row.tableId)}>
            <DeleteIcon fontSize='large' style={{ color: 'red' }} />
          </IconButton>
        )}
      </>
    );
  };

  // icons if row is on edit
  const SaveCancelIcons = () => {
    return (
      <>
        <IconButton onClick={handleSave}>
          <SaveIcon
            fontSize='large'
            style={{ paddingRight: '0.5rem', color: 'green' }}
          />
        </IconButton>
        <IconButton onClick={handleEditCancel}>
          <CancelIcon fontSize='large' style={{ color: 'red' }} />
        </IconButton>
      </>
    );
  };

  // icons if row is on delete
  const DeleteCancelIcons = (iconProps: any) => {
    return (
      <>
        <IconButton onClick={() => handleDeleteRow(iconProps.row)}>
          <DeleteForeverIcon
            fontSize='large'
            style={{ paddingRight: '0.5rem', color: 'darkred' }}
          />
        </IconButton>
        <IconButton onClick={handleDeleteCancel}>
          <CancelIcon fontSize='large' style={{ color: 'red' }} />
        </IconButton>
      </>
    );
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      {data === undefined ? (
        <div>... not Data found</div>
      ) : (
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {props.columns.map((col) => (
                <TableCell
                  key={col.prop}
                  colSpan={col.colSpan ? col.colSpan : 0}
                >
                  {t(`tableHead.${col.prop}`)}
                </TableCell>
              ))}
              <TableCell style={{ width: '5rem', textAlign: 'center' }}>
                <IconButton onClick={handleAdd} disabled={onCreate}>
                  <AddBoxIcon
                    fontSize='large'
                    color={onCreate ? 'disabled' : 'primary'}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={data.indexOf(row)}>
                {props.columns.map((col) => (
                  <TableCell
                    key={col.prop}
                    colSpan={col.colSpan ? col.colSpan : 0}
                    style={{
                      backgroundColor:
                        row.tableId === onDelete ? '#FFCCCC' : '',
                    }}
                  >
                    {col.type === 'button' ? (
                      <Button
                        disabled={onCreate}
                        variant='outlined'
                        onClick={() => handleTableButton(row, col.prop)}
                      >
                        {t(`label.${col.prop}.prop`)}
                      </Button>
                    ) : col.type === 'checkbox' ? (
                      <Checkbox
                        checked={row[col.prop]}
                        disabled={row.tableId !== onEdit.tableId ? true : false}
                        onClick={() => handleCheckboxClick(row, col.prop)}
                        style={{
                          color:
                            row.tableId !== onEdit.tableId ? 'grey' : 'green',
                        }}
                      />
                    ) : row.tableId !== onEdit.tableId ? (
                      row[col.prop]
                    ) : (
                      <>
                        <TextField
                          type={col.type ? col.type : 'text'}
                          id={`${col.prop}'tfid`}
                          key={`${col.prop}'tfkey`}
                          value={onEdit[col.prop]}
                          onChange={(e) => {
                            handleChange(e.target.value, col.prop);
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                ))}
                <TableCell style={{ padding: '0px' }}>
                  {row.tableId !== onEdit.tableId ? (
                    row.tableId === onDelete ? (
                      <DeleteCancelIcons row={row} />
                    ) : (
                      <EditDeleteIcons row={row} />
                    )
                  ) : (
                    <SaveCancelIcons />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      '& div': {
        margin: '0.2rem',
      },
    },
    confirmButton: {
      paddingTop: '2rem',
    }
  })
}
);

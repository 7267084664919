import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Transition from '../../../utils/transition';
import { ChangePassword } from '../change-password';

interface IProps {
  open: boolean;
  handleClose: (oldPw: string, newPw: string) => void;
  submittingNewPw: boolean;
}

export const ProfilePwChangeDialog = (props: IProps) => {
  const { t } = useTranslation();
  const [submittingNewPw, setSubmittingNewPw] = useState(false);

  // reset loading spinner after successful change
  useEffect(()=> {
    setSubmittingNewPw(false);
  },[props.open]); 

  // set loadingSpinner on api call
  useEffect(()=> {
    setSubmittingNewPw(props.submittingNewPw);
  },[props.submittingNewPw]);

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        {t('header.changePassword')}
      </DialogTitle>
      <DialogContent>
        {props.open && (
          <ChangePassword
            isLoading={submittingNewPw}
            handleSubmit={props.handleClose}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => props.handleClose('', '')}>
          {t('label.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

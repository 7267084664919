import {
    AppBar,
    Button,
    Checkbox,
    Chip,
    Dialog,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {ISession} from '../../../models/interfaces/session-interface';
import {ITag} from '../../../models/interfaces/tag-interface';
import {IUser} from '../../../models/interfaces/user-interface';
import {useGlobalContext} from '../../../libs/context-lib';
import {useStyles} from './session-details-styles';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import Transition from '../../../utils/transition';
import {useTheme} from '@mui/material/styles';

const PRESENTATION = 'presentation';
const LIGHTTALK = 'lightningtalk';
const DISCUSSION = 'discussion';

interface IProps {
    session?: ISession;
    handleClose: () => void;
    tags: ITag[];
    handleSubmitCreate: (session: ISession) => void;
    handleSubmitUpdate: (session: ISession) => void;
    orgaUsers: IUser[];
}

export const DialogSessionForm = (props: IProps) => {
    const classes = useStyles(useTheme())();
    const {t} = useTranslation();
    const [session, setSession] = useState<ISession>();
    const {userInfo, currentOrga} = useGlobalContext();
    const [customDurr, setCustomDurr] = useState(false);
    const [orgaUsers, setOrgaUsers] = useState<IUser[]>([]);

    useEffect(() => {
        // TODO Workaround für Unique UserIds ersetzten
        let uniqueSet = new Set(props.orgaUsers)
        setOrgaUsers(Array.from(uniqueSet.values()));
    }, [props.orgaUsers]);

    useEffect(() => {
        if (props.session === undefined) {
            setSession({
                id: uuidv4(),
                eventId: currentOrga.currentEvent!,
                creator: userInfo.sub,
                description: '',
                title: '',
                type: 'presentation',
                starttime: '',
                endtime: '',
                roomId: '',
                speaker: [],
                tags: [],
                duration: 30,
                extern: false,
                mandatory: false,
                canceled: false,
            });
        } else {
            setSession(props.session);
            const durr = props.session.duration;
            if (durr !== 15 && durr !== 30 && durr !== 45) {
                setCustomDurr(true);
            }
        }
    }, [props.session, currentOrga, userInfo]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'title': {
                setSession({...session!, title: event.target.value});
                break;
            }
            case 'description': {
                setSession({...session!, description: event.target.value});
                break;
            }
            case 'extern': {
                setSession({...session!, extern: !session!.extern});
                break;
            }
            case 'mandatory': {
                if (session!.mandatory) {
                    setSession({
                        ...session!,
                        starttime: '',
                        endtime: '',
                        mandatory: !session!.mandatory,
                    });
                } else {
                    setSession({...session!, mandatory: !session!.mandatory});
                }

                break;
            }
            case 'duration': {
                setSession({...session!, duration: parseInt(event.target.value)});
                break;
            }
            case 'starttime': {
                setSession({...session!, starttime: event.target.value});
                break;
            }
            case 'endtime': {
                setSession({...session!, endtime: event.target.value});
                break;
            }
            default: {
                alert('Something went wrong!');
            }
        }
    };

    const onSpeakerChange = (_event: ChangeEvent<{}>, values: IUser[]) => {
        const userIds = [] as string[];
        values.forEach((user) => {
            userIds.push(user.sub);
        });
        setSession({...session!, speaker: userIds});
    };

    const getUsersById = (): IUser[] => {
        const newUsers: IUser[] = [];

        session?.speaker.forEach((sub) => {
            orgaUsers?.forEach((user) => {
                sub === user.sub && newUsers.push(user);
            });
        });
        return newUsers;
    };

    const submitSession = () => {
        props.session === undefined
            ? props.handleSubmitCreate(session!)
            : props.handleSubmitUpdate(session!);
    };

    return (
        <>
            {session !== undefined ? (
                <Dialog
                    fullScreen
                    open={true}
                    onClose={props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={props.handleClose}
                                aria-label='close'
                                style={{border: '1px solid'}}
                            >
                                <CancelIcon/>
                            </IconButton>
                            <Typography variant='h6' className={classes.title}>
                                {props.session === undefined
                                    ? t('label.createSession')
                                    : t('label.editSession')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.root}>
                        <Paper className={classes.formContent}>
                            <TextField
                                className={classes.titleInput}
                                required
                                name='title'
                                label={t('label.title')}
                                value={session.title}
                                onChange={handleChange}
                                variant='outlined'
                            />
                            <TextField
                                name='description'
                                label={t('label.description')}
                                multiline
                                maxRows={6}
                                value={session.description}
                                onChange={handleChange}
                                variant='outlined'
                            />
                            <Autocomplete
                                multiple
                                id='speakers'
                                options={orgaUsers}
                                getOptionLabel={(option) =>
                                    `${option.given_name} ${option.family_name}`
                                }
                                value={getUsersById()}
                                onChange={onSpeakerChange}
                                renderTags={(value: IUser[], getTagProps) =>
                                    value.map((option: IUser, index: number) => (
                                        <Chip
                                            variant='outlined'
                                            label={`${option.given_name} ${option.family_name}`}
                                            {...getTagProps({index})}
                                            key={option.sub}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={t('label.speaker')}
                                    />
                                )}
                            />
                            <FormControlLabel
                                value={session.extern}
                                control={
                                    <Checkbox
                                        name='extern'
                                        checked={session.extern}
                                        onChange={handleChange}
                                    />
                                }
                                label={t('label.extern')}
                                labelPlacement='start'
                            />
                            <FormControlLabel
                                value={session.mandatory}
                                control={
                                    <Checkbox
                                        checked={session.mandatory}
                                        name='mandatory'
                                        onChange={handleChange}
                                    />
                                }
                                label={t('label.mandatory')}
                                labelPlacement='start'
                            />
                            <div
                                style={{
                                    margin: 'auto',
                                    display: session.mandatory ? 'flex' : 'none',
                                }}
                            >
                                <FormControlLabel
                                    style={{margin: '0.5rem'}}
                                    label={t('label.starttime')}
                                    labelPlacement='start'
                                    control={
                                        <TextField
                                            variant='outlined'
                                            type='time'
                                            name='starttime'
                                            onChange={handleChange}
                                            value={session.starttime}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    style={{margin: '0.5rem'}}
                                    label={t('label.endtime')}
                                    labelPlacement='start'
                                    control={
                                        <TextField
                                            variant='outlined'
                                            type='time'
                                            name='endtime'
                                            onChange={handleChange}
                                            value={session.endtime}
                                        />
                                    }
                                />
                            </div>
                            {!session.mandatory && (
                                <FormControl component='fieldset'>
                                    <FormLabel component='legend'>
                                        {t('label.duration')}
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label='duration'
                                        name='duration'
                                        value={customDurr ? 5 : session.duration}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            id='15id'
                                            value={15}
                                            control={<Radio onClick={() => setCustomDurr(false)}/>}
                                            label={'15min'}
                                        />
                                        <FormControlLabel
                                            id='30id'
                                            value={30}
                                            control={<Radio onClick={() => setCustomDurr(false)}/>}
                                            label={'30min'}
                                        />
                                        <FormControlLabel
                                            value={5}
                                            control={
                                                <Radio
                                                    onClick={() => {
                                                        setCustomDurr(true);
                                                    }}
                                                />
                                            }
                                            label={t('label.custom')}
                                        />
                                        <TextField
                                            variant='outlined'
                                            type='number'
                                            inputProps={{min: 5, max: 1440, step: 5}}
                                            name='duration'
                                            onChange={handleChange}
                                            value={session.duration}
                                            style={{
                                                display: customDurr ? '' : 'none',
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}

                            <div style={{margin: '2rem 0.5rem 2rem'}}>
                                <Button
                                    color='primary'
                                    variant={
                                        session.type === PRESENTATION ? 'contained' : 'outlined'
                                    }
                                    onClick={() => {
                                        setSession({...session!, type: PRESENTATION});
                                    }}
                                >
                                    {t('label.presentation')}
                                </Button>
                                <Button
                                    color='primary'
                                    variant={
                                        session.type === LIGHTTALK ? 'contained' : 'outlined'
                                    }
                                    onClick={() => {
                                        setSession({...session!, type: LIGHTTALK});
                                    }}
                                >
                                    {t('label.lightningtalk')}
                                </Button>
                                <Button
                                    color='primary'
                                    variant={
                                        session.type === DISCUSSION ? 'contained' : 'outlined'
                                    }
                                    onClick={() => {
                                        setSession({...session!, type: DISCUSSION});
                                    }}
                                >
                                    {t('label.discussion')}
                                </Button>
                            </div>
                            <Autocomplete
                                freeSolo
                                multiple
                                id='tags'
                                options={props.tags.map((option) => option.label)}
                                value={session.tags}
                                onChange={(_event: ChangeEvent<{}>, values: string[]) =>
                                    setSession({...session!, tags: values})
                                }
                                renderTags={(value: string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        <Chip
                                            variant='outlined'
                                            label={option}
                                            {...getTagProps({index})}
                                            key={index}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={t('label.tags')}
                                    />
                                )}
                            />
                            <Button
                                variant='contained'
                                onClick={submitSession}
                                disabled={
                                    session.title === '' ||
                                    session.speaker.length === 0 ||
                                    (session.mandatory
                                        ? session.starttime === '' || session.endtime === ''
                                        : session.duration % 5 !== 0)
                                }
                            >
                                {t('label.submit')}
                            </Button>
                        </Paper>
                    </div>
                </Dialog>
            ) : (
                <Dialog
                    fullScreen
                    open={true}
                    onClose={props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={props.handleClose}
                                aria-label='close'
                            >
                                <CancelIcon/>
                            </IconButton>
                        </Toolbar>
                        <div>...waiting for data</div>
                    </AppBar>
                </Dialog>
            )}
        </>
    );
};

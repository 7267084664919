import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      '& h6': {
        margin: '0rem 0.2rem 1rem',
      },
    },
    linkButton: {
      margin: '0.5rem',
    },
    chip: {
      marginRight: '0.2rem',
      marginBottom: '0.2rem',
    },
  })
}
);

import React, {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useGlobalContext} from '../libs/context-lib';

function querystring(name: string, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/*export default function UnauthenticatedRoute({children, ...rest}: IProps) {
    const {isAuthenticated} = useGlobalContext();
    const redirect = querystring('redirect');
    const {search} = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(search);
        if (query.get('inviteKey')) {
            localStorage.setItem('inviteKey', query.get('inviteKey')!);
        }
    }, [search]);

    return (
        <Route {...rest}>
            {!isAuthenticated ? (
                children
            ) : (
                <Navigate to={redirect === '' || redirect === null ? '/' : redirect}/>
            )}
        </Route>
    );
}*/

export default function UnauthenticatedRoute({children}: any) {
    const {isAuthenticated} = useGlobalContext();
    const redirect = querystring('redirect');
    const {search} = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(search);
        if (query.get('inviteKey')) {
            localStorage.setItem('inviteKey', query.get('inviteKey')!);
        }
    }, [search]);
    return !isAuthenticated ? children : <Navigate to={redirect === '' || redirect === null ? '/' : redirect}/>;
}

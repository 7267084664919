import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      textAlign: 'center',
      paddingTop: '2%',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
      textAlign: 'center',
      paddingRight: '3rem',
    },
    content: {
      maxWidth: '40rem',
      display: 'inline-grid',
      textAlign: 'left',
      padding: '1rem 5% ',
      marginBottom: '4rem',
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    button: {
      textAlign: 'center',
    },
    expand: {
      color: 'black',
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    expandHead: {
      color: 'black',
      font: 'bold',
      fontSize: '1.3rem',
    },
  })
 }
);

import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { IRoomLink } from '../models/interfaces/room-link-interface';
import { listRoomLinks } from '../graphql/queries';
import { ListRoomLinksQuery } from '../API';
import {
  createRoomLink,
  deleteRoomLink,
  updateRoomLink,
} from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';

export const listRoomLinksByRoomId = async (
  referenceId: string
): Promise<IRoomLink[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listRoomLinks, {
      filter: {
        ReferenceId: {
          eq: referenceId,
        },
      },
      limit: 500,
    })
  )) as GraphQLResult<ListRoomLinksQuery>;

  const roomLinkList: IRoomLink[] = [];

  if (retrievedList.data !== undefined && retrievedList.data !== null) {
    retrievedList.data?.listRoomLinks!.items!.forEach((roomLink) => {
      if (roomLink?.Id)
        roomLinkList.push({
          id: roomLink!.Id,
          referenceId: roomLink!.ReferenceId,
          label: roomLink?.Label!,
          link: roomLink?.Link!,
          acrossRoom: roomLink?.AcrossRoom!,
          activityDetail: roomLink?.ActivityDetail!,
        });
    });
  }

  return roomLinkList;
};

export const listRoomLinksByEventId = async (
  eventId: string
): Promise<IRoomLink[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listRoomLinks, {
      filter: {
        ReferenceId: {
          eq: eventId,
        },
      },
      limit: 500,
    })
  )) as GraphQLResult<ListRoomLinksQuery>;

  const roomLinkList: IRoomLink[] = [];

  if (retrievedList.data !== undefined && retrievedList.data !== null) {
    retrievedList.data?.listRoomLinks!.items!.forEach((roomLink) => {
      if (roomLink?.Id)
        roomLinkList.push({
          id: roomLink!.Id,
          referenceId: roomLink!.ReferenceId,
          label: roomLink?.Label!,
          link: roomLink?.Link!,
          acrossRoom: roomLink?.AcrossRoom!,
          activityDetail: roomLink?.ActivityDetail!,
        });
    });
  }

  return roomLinkList;
};

export const createNewRoomLink = async (roomLink: IRoomLink) => {
  await API.graphql(
    graphqlOperation(createRoomLink, {
      input: {
        Id: roomLink.id,
        ReferenceId: roomLink.referenceId,
        Label: roomLink.label,
        Link: roomLink.link,
        AcrossRoom: roomLink.acrossRoom,
        ActivityDetail: roomLink.activityDetail,
      },
    })
  );
};

export const deleteRoomLinkById = async (
  linkId: string,
  referenceId: string
) => {
  await API.graphql(
    graphqlOperation(deleteRoomLink, {
      input: { Id: linkId, ReferenceId: referenceId },
    })
  );
};

export const updateRoomLinkFull = async (roomLink: IRoomLink) => {
  await API.graphql(
    graphqlOperation(updateRoomLink, {
      input: {
        Id: roomLink.id,
        ReferenceId: roomLink.referenceId,
        Label: roomLink.label,
        Link: roomLink.link,
        AcrossRoom: roomLink.acrossRoom,
        ActivityDetail: roomLink.activityDetail,
      },
    })
  );
};

export const subscriptionCreateRoomLinkByRefId = (ReferenceId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onCreateRoomLink, { ReferenceId })
  );
};

export const subscriptionUpdateRoomLinkByRefId = (ReferenceId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onUpdateRoomLink, { ReferenceId })
  );
};

export const subscriptionDeleteRoomLinkByRefId = (ReferenceId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onDeleteRoomLink, { ReferenceId })
  );
};

import * as EventService from './services/event-service';
import * as OrgaService from './services/organisation-service';
import React, {useEffect, useState} from 'react';
import AppRoutes from './routing/appRoutes';
import {userMapper} from './services/mapper/user-mapper';
import {Auth} from 'aws-amplify';
import {BottomNav} from './components/bottom-nav/bottom-nav';
import {CircularProgress, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {GlobalContext} from './libs/context-lib';
import {GraphQLResult} from '@aws-amplify/api';
import {HashRouter} from 'react-router-dom';
import {Header} from './components/header/header';
import {IEvent} from './models/interfaces/event-interface';
import {IOrganization} from './models/interfaces/organization-interface';
import {IUser} from './models/interfaces/user-interface';
import {OnUpdateEventIdFilterSubscription, OnUpdateOrgaSubscription,} from './API';
import {customTheme, defaultTheme} from './theme';
import {eventUpdateSubMapper} from './services/mapper/event-mapper';
import {orgaUpdateSubMapper} from './services/mapper/orga-mapper';
import { getUserInGroupAsAdmin } from './services/user-service';

const getInitContainer = (): number => {
    let value = 1;
    value = parseInt(localStorage.getItem('currContainer') || '1');
    return value;
};

function App() {
    const [currContainer, setCurrContainer] = useState(getInitContainer());
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [userInfo, setUserInfo] = useState<IUser>({
        sub: '',
        email: '',
        groups: [],
    });
    const [currentOrga, setCurrentOrga] = useState<IOrganization>({
        id: '',
        name: '',
    });
    const [currentEvent, setCurrentEvent] = useState<IEvent>({
        id: '',
        eventStatus: '',
        date: '',
        organizationId: '',
        starttime: '',
        endtime: '',
        votingClosed: true,
        submitClosed: true,
        archived: false,
    });
    const [theme, setTheme] = useState(defaultTheme);
    const [users, setUsers] = useState<IUser[]>([]);
    const [admins, setAdmins] = useState<IUser[]>([]);
    const [owners, setOwners] = useState<IUser[]>([]);

    // on container change write currContainer in localStorage
    useEffect(() => {
        if (currContainer !== null && currContainer !== undefined) {
            localStorage.removeItem('currContainer');
            localStorage.setItem('currContainer', currContainer.toString());
        } else {
            localStorage.removeItem('currContainer');
            localStorage.setItem('currContainer', 'null');
        }
    }, [currContainer]);

    // get orga  & last position from local storage
    useEffect(() => {
        if (localStorage.getItem('orga')) {
            const orgaJson = JSON.parse(localStorage.getItem('orga') || '{}');
            if (orgaJson.id) {
                const newOrga = {
                    id: orgaJson.id,
                    name: orgaJson.name,
                    primaryColor: orgaJson.primaryColor,
                    secondaryColor: orgaJson.secondaryColor,
                    currentEvent: orgaJson.currentEvent,
                } as IOrganization;
                setCurrentOrga(newOrga);
            }
        }
        if (localStorage.getItem('currContainer')) {
            const position = localStorage.getItem('currContainer') || '1';
            position !== 'null' ? setCurrContainer(parseInt(position)) : setCurrContainer(3);
        }
        onLoad();
    }, []);

    useEffect(() => {
        if (userInfo.sub && currentOrga.id) {
            const fetchOrgas = () => {
                userInfo.groups.forEach(async (group) => {
                    await OrgaService.getOrgaByGroup(group).then((data) => {
                        if (data) {
                            if (data.id === currentOrga.id) {
                                setCurrentOrga(data);
                            }
                        }
                    });
                });
            };
            fetchOrgas();
        }
    }, [userInfo, currentOrga.id]);

    // current orga update subscription
    useEffect(() => {
        let orgaUpdateSubscription: any;
        if (currentOrga.id) {
            orgaUpdateSubscription = OrgaService.subscriptionUpdateOrgaById(
                currentOrga.id!
                // @ts-ignore
            ).subscribe({
                next: (updatetOrga: {
                    value: GraphQLResult<OnUpdateOrgaSubscription>;
                }) => {
                    const newOrga = orgaUpdateSubMapper(updatetOrga.value);
                    setCurrentOrga(newOrga);
                },
            });
        }

        return () => {
            orgaUpdateSubscription?.unsubscribe();
        };
    }, [currentOrga.id]);

    // refresh storage & context & theme after orga change
    useEffect(() => {
        localStorage.removeItem('orga');
        localStorage.setItem('orga', JSON.stringify(currentOrga));
        if (
            currentOrga?.primaryColor !== '' &&
            currentOrga?.secondaryColor !== ''
        ) {
            setTheme(
                customTheme(currentOrga?.primaryColor!, currentOrga?.secondaryColor!)
            );
        }
        const fetchEvent = async () => {
            if (currentOrga.currentEvent) {
                EventService.getEventById(
                    currentOrga.currentEvent!,
                    currentOrga.id
                ).then(
                    (data) => {
                        setCurrentEvent(data);
                    },
                    () => {
                        console.log('No current event available');
                    }
                );
            }
        };

        let eventUpdateSubscription: any;
        // let eventDeleteSubscription: any;

        if (currentOrga.currentEvent) {
            fetchEvent();

            /**
             * Subscriptions current-event
             */
            eventUpdateSubscription = EventService.subscriptionUpdateEventById(
                currentOrga.currentEvent!
                // @ts-ignore
            ).subscribe({
                next: (updatetEvent: {
                    value: GraphQLResult<OnUpdateEventIdFilterSubscription>;
                }) => {
                    const newEvent = eventUpdateSubMapper(updatetEvent.value);
                    setCurrentEvent(newEvent);
                },
            });
        } else {
            setCurrentEvent({
                id: '',
                eventStatus: '',
                date: '',
                organizationId: '',
                starttime: '',
                endtime: '',
                votingClosed: true,
                submitClosed: true,
                archived: false,
            });
        }

        return () => {
            eventUpdateSubscription?.unsubscribe();
        };
    }, [currentOrga]);

    // check auth user available
    const onLoad = async () => {
        try {
            await Auth.currentAuthenticatedUser({bypassCache: true}).then(
                (userInfo) => {
                    setUserInfo(userMapper(userInfo));
                }
            );
            userHasAuthenticated(true);
        } catch (e) {
            console.log('No current user');
        }
        setIsAuthenticating(false);
    };

    useEffect(() => {
        const fetchUsersRequests = async () => {
            const usersData = await getUserInGroupAsAdmin(currentOrga.id, userInfo.sub, 'user');
            setUsers(usersData);
        }
    
        fetchUsersRequests();
    }, [currentOrga.id, userInfo.sub]);

    useEffect(() => {

        console.log("fetch admins in App.tsx");
        const fetchAdminsRequests = async () => {
          const adminsData = await getUserInGroupAsAdmin(currentOrga.id, userInfo.sub, 'admin');
          setAdmins(adminsData);
        }
    
        fetchAdminsRequests();
    }, [currentOrga.id, userInfo.sub]);

    useEffect(() => {

        console.log("fetch owners in App.tsx");
        const fetchOwnersRequests = async () => {
          const ownersData = await getUserInGroupAsAdmin(currentOrga.id, userInfo.sub, 'owner');
          setOwners(ownersData);
        }
    
        fetchOwnersRequests();
    }, [currentOrga.id, userInfo.sub]);

    return (
        <HashRouter>
            {isAuthenticating && currentOrga !== undefined ? (
                <div>
                    <CircularProgress/>
                </div>
            ) : (
                <GlobalContext.Provider
                    value={{
                        isAuthenticated,
                        userHasAuthenticated,
                        userInfo,
                        setUserInfo,
                        currentOrga,
                        setCurrentOrga,
                        currentEvent,
                        setCurrentEvent,
                        currContainer,
                        setCurrContainer,
                        users,
                        admins,
                        owners,
                        setUsers,
                        setAdmins,
                        setOwners
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        {isAuthenticated && <Header setHomeIndex={setCurrContainer}/>}
                        <AppRoutes/>
                        {isAuthenticated && (
                            <BottomNav
                                setMenuIndex={setCurrContainer}
                                menuIndex={currContainer}
                                currentOrga={currentOrga!}
                            />
                        )}
                    </ThemeProvider>
                </GlobalContext.Provider>
            )}
        </HashRouter>
    );
}

export default App;

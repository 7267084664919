import * as EventService from '../../services/event-service';
import * as OrgaService from '../../services/organisation-service';
import React, { useEffect, useState } from 'react';
import { EventListManage } from '../../components/event/event-list-manage';
import { GraphQLResult } from '@aws-amplify/api';
import { IEvent } from '../../models/interfaces/event-interface';
import { IOrganization } from '../../models/interfaces/organization-interface';
import {
  OnCreateEventSubscription,
  OnDeleteEventSubscription,
  OnUpdateEventSubscription,
} from '../../API';
import { useGlobalContext } from '../../libs/context-lib';
import {
  eventCreateSubByOrgaMapper,
  eventUpdateSubByOrgaMapper,
} from '../../services/mapper/event-mapper';

export const EventListContainer = () => {
  const { currentOrga, setCurrentOrga } = useGlobalContext();
  const [events, setEvents] = useState<IEvent[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let newEvents: IEvent[] = [];
    const fetchData = async () => {
      const retrievedData = await EventService.listEventsByOrgaId(
        currentOrga.id
      );
      if (retrievedData !== null && retrievedData !== undefined) {
        setEvents(retrievedData);
        newEvents = retrievedData;
      }
      setLoading(false);
    };

    const eventCreateSubscription = EventService.subscriptionCreateEventByOrga(
      currentOrga.id
      // @ts-ignore
    ).subscribe({
      next: (createdEvent: {
        value: GraphQLResult<OnCreateEventSubscription>;
      }) => {
        const newEvent = eventCreateSubByOrgaMapper(createdEvent.value);
        // deep copy
        const newData: IEvent[] = [];
        newEvents.forEach((val) => {
          newEvent.id !== val.id && newData.push(Object.assign({}, val));
        });
        newData.push(newEvent);
        newEvents = newData;
        setEvents([...newData]);
      },
    });

    const eventUpdateSubscription = EventService.subscriptionUpdateEventByOrga(
      currentOrga.id
      // @ts-ignore
    ).subscribe({
      next: (updatetEvent: {
        value: GraphQLResult<OnUpdateEventSubscription>;
      }) => {
        const newEvent = eventUpdateSubByOrgaMapper(updatetEvent.value);
        const newList: IEvent[] = [];
        // deep copy
        newEvents.forEach((val) => newList.push(Object.assign({}, val)));

        const index = newList.findIndex((x: IEvent) => x.id === newEvent.id);
        if (index !== -1) {
          newList[index] = newEvent;
          setEvents(newList);
          newEvents = newList;
        }
      },
    });

    const eventDeleteSubscription = EventService.subscriptionDeleteEventByOrga(
      currentOrga.id
      // @ts-ignore
    ).subscribe({
      next: (deletedEvent: {
        value: GraphQLResult<OnDeleteEventSubscription>;
      }) => {
        const toDeleteId = deletedEvent.value.data?.onDeleteEvent?.Id;

        // deep copy without deleted
        const newData: IEvent[] = [];
        newEvents.forEach((val) => {
          val.id !== toDeleteId && newData.push(Object.assign({}, val));
        });
        newEvents = newData;
        setEvents([...newData]);
      },
    });

    fetchData();

    return () => {
      eventUpdateSubscription.unsubscribe();
      eventCreateSubscription.unsubscribe();
      eventDeleteSubscription.unsubscribe();
    };
  }, [currentOrga.id]);

  const handleDeleteEvent = async (eventId: string) => {
    await EventService.deleteEventById(eventId, currentOrga.id).then(
      () => {
        const index = events.findIndex((x: IEvent) => x.id === eventId);
        const newEvents = [...events];
        newEvents.splice(index, index >= 0 ? 1 : 0);
        setEvents(newEvents);
        eventId === currentOrga.currentEvent && setCurrentEvent('');
      },
      (error) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleUpdateEvent = async (event: IEvent) => {
    await EventService.updateEventFull(event).then(
      () => {},
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const setCurrentEvent = async (eventId: string) => {
    const newOrga = {
      id: currentOrga.id,
      name: currentOrga.name,
      primaryColor: currentOrga.primaryColor,
      secondaryColor: currentOrga.secondaryColor,
      currentEvent: eventId,
    } as IOrganization;

    await OrgaService.updateOrgaFull(newOrga).then(
      () => {
        setCurrentOrga(newOrga);
      },
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const handleCreateEvent = async (event: IEvent, asCurrent?: boolean) => {
    await EventService.createNewEvent(event).then(
      async () => {
        if (asCurrent) {
          const newOrga = {
            id: currentOrga.id,
            name: currentOrga.name,
            primaryColor: currentOrga.primaryColor,
            secondaryColor: currentOrga.secondaryColor,
            currentEvent: event.id,
          } as IOrganization;
          await OrgaService.updateOrgaFull(newOrga).then(
            () => {
              setEvents([...events, event]);
              setCurrentOrga(newOrga);
            },
            (error) => {
              console.log(error);
              alert('Update failed!');
            }
          );
        } else {
          setEvents([...events, event]);
        }
      },
      (error) => {
        console.log(error);
        alert('Create failed!');
      }
    );
  };

  return (
    <>
      {loading ? (
        <div>...waiting for data</div>
      ) : (
        <EventListManage
          events={events}
          deleteEvent={handleDeleteEvent}
          createEvent={handleCreateEvent}
          setCurrentEvent={setCurrentEvent}
          updateEvent={handleUpdateEvent}
        />
      )}
    </>
  );
};

import { useContext, createContext } from 'react';
import { IEvent } from '../models/interfaces/event-interface';
import { IOrganization } from '../models/interfaces/organization-interface';
import { IUser } from '../models/interfaces/user-interface';
import { IVote } from '../models/interfaces/vote-interface';

// context for the whole app
interface IGlobalContext {
  isAuthenticated: boolean;
  userHasAuthenticated: (value: boolean) => void;
  userInfo: IUser;
  setUserInfo: (value: IUser) => void;
  currentOrga: IOrganization;
  setCurrentOrga: (value: IOrganization) => void;
  currentEvent: IEvent;
  setCurrentEvent: (value: IEvent) => void;
  currContainer: number;
  setCurrContainer: (value: number) => void;
  users: IUser[];
  admins: IUser[];
  owners: IUser[];
  setUsers: (value: IUser[]) => void;
  setAdmins: (value: IUser[]) => void;
  setOwners: (value: IUser[]) => void; 
}

export const GlobalContext = createContext({} as IGlobalContext);

export function useGlobalContext() {
  return useContext(GlobalContext);
}

// context for specific event
interface ISessionContext {
  eventVotes: IVote[];
}

export const EventContext = createContext({} as ISessionContext);

export function useEventContext() {
  return useContext(EventContext);
}

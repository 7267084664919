import { IUser } from '../../models/interfaces/user-interface';

// amplify response mapper
export const userMapper = (userData: any): IUser => {
  if (userData.attributes === undefined) {
    const newUser: IUser = {
      sub: userData.username,
      email: userData.challengeParam.userAttributes.email,
      groups: userData.signInUserSession.accessToken.payload['cognito:groups'],
      given_name: userData.challengeParam.userAttributes.given_name || '',
      family_name: userData.challengeParam.userAttributes.family_name || '',
      picture: userData.challengeParam.userAttributes.picture || '',
    };

    return newUser;
  } else {
    const newUser: IUser = {
      sub: userData.username,
      email: userData.attributes.email,
      groups: userData.signInUserSession.accessToken.payload['cognito:groups'],
      given_name: userData.attributes.given_name || '',
      family_name: userData.attributes.family_name || '',
      picture: userData.attributes.picture || '',
    };

    return newUser;
  }
};

export const getUsersByGroupMapper = (userData: any): IUser[] => {
  const users: IUser[] = [];

  if (userData.data.getUsersByGroup) {
    userData.data.getUsersByGroup.forEach((user: any) => {
      const newUser: IUser = {
        sub: user.Username,
        email: user.email,
        family_name: user.family_name,
        given_name: user.given_name,
        picture: user.picture,
        groups: [],
      };
      users.push(newUser);
    });
  }

  return users;
};

export const getUsersByOrgaMapper = (userData: any): IUser[] => {
  const users: IUser[] = [];

  if (userData.data.getUsersByOrga) {
    userData.data.getUsersByOrga.forEach((user: any) => {
      const newUser: IUser = {
        sub: user.Username,
        email: user.email,
        family_name: user.family_name,
        given_name: user.given_name,
        picture: user.picture,
        groups: [],
      };
      users.push(newUser);
    });
  }

  return users;
};

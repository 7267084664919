import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@mui/material';
import { useStyles } from './loading-view-styles';

interface IProps {
  open: boolean;
}

export const ScheduleLoading = (props: IProps) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={props.open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent className={classes.progressContainer}>
        <CircularProgress className={classes.circularProgress}/>
      </DialogContent>
    </Dialog>
  );
};

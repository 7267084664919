import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    margin: '3%',
    width: 'auto',
    overflow: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    '& th': {
      width: 'auto',
      padding: '16px 0px 16px 8px',
      letterSpacing: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& td': {
      textOverflow: 'ellipsis',
      width: 'auto',
      overflow: 'hidden',
      padding: '16px 0px 16px 8px',
      letterSpacing: 0,
      // whiteSpace: 'nowrap',
    },
    '& button': {
      padding: '0px',
    },
  },
  row: {
    '&:hover': {
      backgroundColor: '#d3d3d3',
    },
  },
  curs: {
    cursor: 'pointer',
  },
  dotCell: {
    textAlign: 'center',
    padding: '0px',
    lineHeight: '50%',
  },
});

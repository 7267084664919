import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import React, { FormEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import googleImgFocus from '../../images/btn_google_signin_dark_focus_web.png';
import googleImgNormal from '../../images/btn_google_signin_dark_normal_web.png';
import googleImgPressed from '../../images/btn_google_signin_dark_pressed_web.png';
import { Auth } from 'aws-amplify';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useStyles } from './login-styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface IProps {
  isLoading: boolean;
  handleSubmit: (email: string, password: string) => void;
}

export const Login = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.handleSubmit(email, password);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Grid>
          <Paper style={{ padding: '1rem 2rem' }}>
            <div>
              {!isLoading ? (
                <>
                  <button
                    className={classes.googleButton}
                    onClick={() => {
                      setIsLoading(true);
                      // @ts-ignore
                      Auth.federatedSignIn({ provider: 'Google' });
                    }}
                  >
                    <img
                      className={classes.googleImg}
                      src={googleImgNormal}
                      alt={'googleLogin'}
                      onMouseOver={(e) =>
                        (e.currentTarget.src = googleImgFocus)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = googleImgNormal)
                      }
                      onMouseDown={(e) =>
                        (e.currentTarget.src = googleImgPressed)
                      }
                      onMouseUp={(e) => {
                        e.currentTarget.src = googleImgNormal;
                      }}
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </button>
                </>
              ) : (
                <CircularProgress />
              )}
            </div>
            <Divider component="div" style={{marginBottom: "1rem"}}/>
            <div>
              <Button
                href='#/register'
                variant='contained'
                color='primary'
                type='submit'
              >
                Registrieren
              </Button>
            </div>
            <Divider component="div" style={{margin: "1rem 0rem"}}/>
            <div>
              <Typography variant='subtitle1'>
                <Box fontWeight='fontWeightBold'>
                  {t('label.copeLogin')}
                </Box>
              </Typography>
            </div>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <div>
                <TextField
                  label='EMail'
                  variant='outlined'
                  autoFocus
                  type='email'
                  value={email}
                  autoComplete='current-email'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <div className={classes.registerContainer}>
                  <TextField
                    label={t('label.password')}
                    variant='outlined'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete='current-password'
                    type='password'
                  />
                </div>
              </div>
              <div>
                {props.isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button variant='contained' color='primary' type='submit'>
                    Login
                  </Button>
                )}
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

import { ListBlockersQuery } from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { IBlocker } from '../../models/interfaces/blocker-interface';
import { ICalendarEvent } from '../../models/interfaces/calendar-event-interface';
import { parseTimeToISODate } from '../../utils/iso-timepicker-converter';

export const listBlockersMapper = (
  blockerList: GraphQLResult<ListBlockersQuery>
): IBlocker[] => {
  const mappedBlockers: IBlocker[] = [];

  if (blockerList !== undefined && blockerList.data !== null) {
    blockerList.data?.listBlockers!.items!.forEach((blocker) => {
      if (blocker?.Id) {
        mappedBlockers.push({
          id: blocker?.Id!,
          eventId: blocker?.EventId!,
          name: blocker?.Name!,
          starttime: blocker?.Starttime!,
          endtime: blocker?.Endtime!,
          userId: blocker?.UserId!,
        });
      }
    });
  }

  return mappedBlockers;
};

export const mapBlockersToCalendarEvents = (
  eventDay: string,
  blockerList: IBlocker[],
  referredRoomId: string
) => {
  let newCalendarBlockerEvents: ICalendarEvent[] = [];

  blockerList.forEach((blocker: IBlocker) => {
    newCalendarBlockerEvents.push({
      id: blocker.id,
      title: blocker.userId === '' ? blocker.name : 'Personal Blocker',
      start: parseTimeToISODate(eventDay, blocker.starttime),
      end: parseTimeToISODate(eventDay, blocker.endtime),
      editable: false,
      startEditable: false,
      durationEditable: false,
      resourceEditable: false,
      roomId: referredRoomId,
      roomLinks: [],
      speaker: [],
      type: '',
      description: '',
      tags: [],
      canceled: false,
      userId: blocker.userId,
      backgroundColor: 'rgb(63, 68, 68)',
      borderColor: 'rgb(63, 68, 68)',
      mandatory: false,
      textColor: 'white',
    });
  });

  return newCalendarBlockerEvents;
};

export const mapBlockersToCalendarEventsGeneral = (
  eventDay: string,
  blockerList: IBlocker[]
) => {
  let newCalendarBlockerEvents: ICalendarEvent[] = [];
  blockerList.forEach((blocker: IBlocker) => {
    newCalendarBlockerEvents.push({
      id: blocker.id,
      title: blocker.userId === '' ? blocker.name : 'Personal Blocker',
      start: parseTimeToISODate(eventDay, blocker.starttime),
      end: parseTimeToISODate(eventDay, blocker.endtime),
      editable: false,
      startEditable: false,
      durationEditable: false,
      resourceEditable: false,
      roomId: '',
      roomLinks: [],
      speaker: [],
      type: '',
      description: '',
      tags: [],
      canceled: false,
      userId: blocker.userId,
      backgroundColor: 'rgb(63, 68, 68)',
      borderColor: 'rgb(63, 68, 68)',
      mandatory: false,
      textColor: 'white',
    });
  });
  return newCalendarBlockerEvents;
};

export const blockerToEntityMapper = (blocker: IBlocker) => {
  const mappedBlocker = {
    EventId: blocker.eventId,
    Id: blocker.id,
    UserId: blocker.userId,
    Name: blocker.name,
    Starttime: blocker.starttime,
    Endtime: blocker.endtime,
  };

  return mappedBlocker;
};

export const blockerListToEntityListMapper = (blockerList: IBlocker[]) => {
  const mappedBlockers: any = [];

  blockerList.forEach((blocker) => {
    mappedBlockers.push({
      EventId: blocker.eventId,
      Id: blocker.id,
      UserId: blocker.userId,
      Name: blocker.name,
      Starttime: blocker.starttime,
      Endtime: blocker.endtime,
    });
  });

  return mappedBlockers;
};

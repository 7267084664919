import { ListVotesQuery } from '../API';
import { IVote } from '../models/interfaces/vote-interface';
import { GraphQLResult } from '@aws-amplify/api';
import { listVotes } from '../graphql/queries';
import { createVote, deleteVote } from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../graphql/subscriptions';

export const listVotesByEventId = async (eventId: string): Promise<IVote[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listVotes, {
      filter: {
        EventId: {
          eq: eventId,
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListVotesQuery>;

  const votes: IVote[] = [];

  if (retrievedList.data !== undefined && retrievedList.data !== null) {
    await retrievedList.data?.listVotes!.items!.forEach((vote) => {
      if (vote?.Id) {
        votes.push({
          id: vote?.Id!,
          sessionId: vote?.SessionId!,
          userId: vote?.UserId!,
          eventId: vote?.EventId!,
        });
      }
    });
  }
  return votes;
};

export const deleteVoteById = async (id: string, sessionId: string) => {
  await API.graphql(
    graphqlOperation(deleteVote, {
      input: { Id: id, SessionId: sessionId },
    })
  );
};

export const createNewVote = async (vote: IVote) => {
  await API.graphql(
    graphqlOperation(createVote, {
      input: {
        Id: vote.id,
        SessionId: vote.sessionId,
        UserId: vote.userId,
        EventId: vote.eventId,
      },
    })
  );
};

export const subscriptionCreateVote = (EventId: string) => {
  return API.graphql(graphqlOperation(subscriptions.onCreateVote, { EventId }));
};

export const subscriptionDeleteVote = (EventId: string) => {
  return API.graphql(graphqlOperation(subscriptions.onDeleteVote, { EventId }));
};

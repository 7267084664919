import { Tooltip } from '@mui/material';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import { styled } from '@mui/material/styles';


export const useStyles = makeStyles(() =>
  createStyles({
    gridContainer: {
      marginTop: '1rem',
    },
    gridItem: {
      marginTop: '1rem',
    },
    root: {
      textAlign: 'center',
    },
    submitButton: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    logo: {
      marginTop: '0.95rem',
    },
    registerLink: {
      color: '#0000EE',
    },
  })
);

export const LightTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
  }
}))



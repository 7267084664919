import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';


export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    avatar: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      margin: theme.spacing(2) + 'px auto',
      fontSize: '3rem',
    },
    personalInfo: {
      margin: 'auto',
      textAlign: 'left',
      padding: '0.5rem 1rem 0rem',
      lineBreak: 'anywhere',
    },
    paper: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
      padding: '0.5rem 1rem',
      cursor: 'pointer',
      height: 'calc(100% - 3rem)',
      '&:hover': {
        backgroundColor: '#64A70B',
      },
    },

    paper_no_hover: {
      margin: theme.spacing(2),
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
      padding: '0.5rem 1rem',
      height: 'calc(100% - 3rem)',
    },

    'paper-action-icon': {
      marginTop: '-2rem',
      float: 'right',
      color: theme.palette.secondary.main,
    },
    p: {
      margin: '0.25rem 0rem',
    },
    editBtnGrid: {
      textAlign: 'right',
    },
    usrEditDialogContent: {
      '& div': {
        margin: theme.spacing(1),
      }
    }
  })
}
);

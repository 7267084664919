import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { OrgaForm } from '../../components/organization/orga-form';
import { OrgaManage } from '../../components/organization/orga-manage';
import { useGlobalContext } from '../../libs/context-lib';
import { Location } from 'history';
import * as OrgaService from '../../services/organisation-service';
import { IOrganization } from '../../models/interfaces/organization-interface';

export const OrgaManageContainer = () => {
  const location = useLocation() as Location<{ orga: IOrganization }>;
  const { currentOrga, setCurrentOrga } = useGlobalContext();
  const [orga, setOrga] = useState<IOrganization>(currentOrga);
  const [onEdit, setOnEdit] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    location.state
      ? setOrga(location.state.orga as IOrganization)
      : setOrga(currentOrga);
  }, [location.state, currentOrga]);

  const handleSubmitUpdate = async (newOrga: IOrganization) => {
    setUpdating(true);
    await OrgaService.updateOrgaFull(newOrga).then(
      () => {
        setUpdating(false);
        setOnEdit(false);
        if (newOrga.id === currentOrga.id) {
          setCurrentOrga(newOrga);
        }
      },
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
    setUpdating(false);
  };

  return (
    <>
      {onEdit ? (
        <OrgaForm
          orga={orga}
          handleSubmit={handleSubmitUpdate}
          setOnEdit={setOnEdit}
          submitting={updating}
        />
      ) : (
        <OrgaManage orga={orga} setOnEdit={setOnEdit} />
      )}
    </>
  );
};

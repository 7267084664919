/**
 * Convert ISO Timestamp to "hh:mm" if date=today else "dd.mm.yyyy hh:mm"
 * @param timestamp ISO-timestamp as string
 * @output string
 */
export const getChatTimestamp = (timestamp: string): string => {
  const newDate = new Date(timestamp);
  let minutes = newDate.getMinutes().toString();
  let hours = newDate.getHours().toString();
  let day = newDate.getDate().toString();
  // getMonth start with 0 (January)
  let month = (newDate.getMonth() + 1).toString();
  let year = newDate.getFullYear().toString();

  if (minutes.length < 2) {
    minutes = '0' + minutes;
  }
  if (hours.length < 2) {
    hours = '0' + hours;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  if (month.length < 2) {
    month = '0' + month;
  }

  let newString = '';

  if (
    new Date().getFullYear().toString() === year &&
    new Date().getMonth() === newDate.getMonth() &&
    new Date().getDate() === newDate.getDate()
  ) {
    newString = `${hours}:${minutes}`;
  } else {
    newString = `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  return newString;
};

/**
 * Convert ISO Timestamp to "dd.mm.yyyy"
 * @param timestamp ISO-timestamp as string
 * @output string
 */
export const getDateDeByTimestamp = (timestamp: string): string => {
  const newDate = new Date(timestamp);
  let day = newDate.getDate().toString();
  // getMonth start with 0 (January)
  let month = (newDate.getMonth() + 1).toString();
  let year = newDate.getFullYear().toString();

  if (day.length < 2) {
    day = '0' + day;
  }
  if (month.length < 2) {
    month = '0' + month;
  }

  return `${day}.${month}.${year}`;
};

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      textAlign: 'center',
      flexGrow: 1,
      marginTop: '5px',
    },
    logo: {
        height: '2rem'
    },
    icons: {
      color: theme.palette.primary.contrastText
    }
  })
);

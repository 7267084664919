import React, { useState, useEffect } from 'react';
import { EventCalendar } from '../event-calendar';
import { ISession } from '../../../models/interfaces/session-interface';
import { IBlocker } from '../../../models/interfaces/blocker-interface';
import * as SessionService from '../../../services/session-service';
import * as RoomService from '../../../services/room-service';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGlobalContext } from '../../../libs/context-lib';
import { useTranslation } from 'react-i18next';
import Transition from '../../../utils/transition';
import { useStyles } from './dialog-edit-schedule-styles';
import { IRoom } from '../../../models/interfaces/room-interface';
import { IUser } from '../../../models/interfaces/user-interface';
import { useTheme } from '@mui/material/styles';

interface IProps {
  handleClose: () => void;
  blockers: IBlocker[];
  orgaUsers: IUser[];
}

export const EditScheduleDialog = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { userInfo, currentEvent } = useGlobalContext();
  const { t } = useTranslation();
  const [scheduledSessions, setScheduledSessions] = useState<ISession[]>([]);
  const [rooms, setRooms] = useState<IRoom[]>([]);

  useEffect(() => {
    if (currentEvent.id !== (null || undefined)) {
      const fetchScheduledSessions = () => {
        SessionService.listSessionsByEventIdScheduled(currentEvent.id).then(
          (data: ISession[]) => {
            let sessions: ISession[] = [];
            data.forEach((session) => {
              sessions.push(Object.assign({}, session));
            });
            setScheduledSessions(sessions);
          }
        );
      };

      const fetchRooms = () => {
        RoomService.listRoomsByEventId(currentEvent.id).then(
          (data: IRoom[]) => {
            let rooms: IRoom[] = [];
            data.forEach((room) => {
              rooms.push(Object.assign({}, room));
            });
            setRooms(rooms);
          }
        );
      };

      fetchScheduledSessions();
      fetchRooms();
    }
  }, [currentEvent.id]);

  return (
    <>
      {scheduledSessions !== undefined ? (
        <Dialog
          fullScreen
          open={true}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
                style={{ border: '1px solid' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6' className={classes.title}>
                {t('label.schedule')}
              </Typography>
            </Toolbar>
          </AppBar>
          <EventCalendar
            oragUsers={props.orgaUsers || []}
            editMode={true}
            sessions={scheduledSessions}
            blockers={props.blockers.filter(
              (blocker) =>
                blocker.userId === '' || blocker.userId === userInfo.sub
            )}
            rooms={rooms}
            roomlinks={[]}
          />
        </Dialog>
      ) : (
        <div>...loading</div>
      )}
    </>
  );
};

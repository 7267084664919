import { GraphQLResult } from '@aws-amplify/api';
import { ListMessagesQuery, OnCreateMessageSubscription } from '../../API';
import { IMessage } from '../../models/interfaces/message-interface';

export const listMessagesMapper = (
  listedSessions: GraphQLResult<ListMessagesQuery>
): IMessage[] => {
  const newMessages: IMessage[] = [];

  listedSessions.data?.listMessages?.items?.forEach((message) => {
    newMessages.push({
      id: message?.Id!,
      userId: message?.UserId!,
      timestamp: message?.Timestamp!,
      content: message?.Content!,
      referenceId: message?.ReferenceId!,
    });
  });
  return newMessages;
};

export const messageCreateSubMapper = (
  message: GraphQLResult<OnCreateMessageSubscription>
): IMessage => {
  const newMessage: IMessage = {
    id: message?.data?.onCreateMessage?.Id!,
    userId: message?.data?.onCreateMessage?.UserId!,
    timestamp: message?.data?.onCreateMessage?.Timestamp!,
    content: message?.data?.onCreateMessage?.Content!,
    referenceId: message?.data?.onCreateMessage?.ReferenceId!,
  };

  return newMessage;
};

import { IUser } from '../models/interfaces/user-interface';

export const checkPrivilegAdmin = (
  userInfo: IUser,
  currentOrgaId: string
): boolean => {
  if (userInfo.groups) {
    for (let i = 0; i < userInfo.groups.length; i++) {
      if (userInfo.groups[i] === `${currentOrgaId}_admin`) {
        return true;
      }
    }
  }

  return false;
};

export const checkPrivilegOwner = (
  userInfo: IUser,
  currentOrgaId: string
): boolean => {
  if (userInfo.groups) {
    for (let i = 0; i < userInfo.groups.length; i++) {
      if (userInfo.groups[i] === `${currentOrgaId}_owner`) {
        return true;
      }
    }
  }

  return false;
};

export const checkPrivilegAdminOrOwner = (
  userInfo: IUser,
  currentOrgaId: string
): boolean => {
  if (userInfo.groups) {
    for (let i = 0; i < userInfo.groups.length; i++) {
      if (
        userInfo.groups[i] === `${currentOrgaId}_owner` ||
        userInfo.groups[i] === `${currentOrgaId}_admin`
      ) {
        return true;
      }
    }
  }

  return false;
};

export const checkPrivilegCreator = (userInfo: IUser): boolean => {
  if (userInfo.groups) {
    for (let i = 0; i < userInfo.groups.length; i++) {
      if (userInfo.groups[i] === 'organisationCreator') {
        return true;
      }
    }
  }

  return false;
};

export const checkPrivilegUser = (
  userInfo: IUser,
  currentOrgaId: string
): boolean => {
  if (userInfo.groups) {
    for (let i = 0; i < userInfo.groups.length; i++) {
      if (userInfo.groups[i] === `${currentOrgaId}_user`) {
        return true;
      }
    }
  }
  return false;
};

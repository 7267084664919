import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      textAlign: 'center',
      paddingTop: '2%',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
      textAlign: 'center',
      paddingRight: '3rem',
    },
    content: {
      maxWidth: '50rem',
      width: '95%',
      display: 'inline-block',
      padding: '2rem',
      textAlign: 'left',
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    formContent: {
      maxWidth: '50rem',
      display: 'inline-grid',
      padding: '2rem',
      textAlign: 'left',
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    desc: {
      '& textarea': {
        color: 'black',
      },
      width: '100%',
    },
    chatRoot: {
      width: '100%',
      paddingTop: '4%',
    },
    chatContainer: {
      width: '90%',
      maxWidth: '60rem',
      display: 'inline-grid',
      padding: '1rem',
    },
    messageContainer: {
      width: '95%',
      marginTop: '0.3rem',
      marginBottom: '0.3rem',

      textAlign: 'left',
    },
    messagePaper: {
      padding: '0.3rem',
    },
    messageInputContainer: {
      width: '90%',
      display: 'inline-table',
      maxWidth: '60rem',
      marginBottom: '1rem',
    },
    rootInput: {
      /* margin: '0% 2% 0% 4%', */
      display: 'flex',
      alignItems: 'center',
      padding: '2px 4px',
      backgroundColor: '#BEBEBE',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    titleInput: {
      '& input': {
        fontSize: '1.5rem',
      },
    },
    buttons: {
      margin: '0.5rem',
    },
    chip: {
      marginRight: '0.2rem',
      marginBottom: '0.2rem',
    },
    description: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '16.5px 14px',
      margin: '0',
    }
  })
}
);

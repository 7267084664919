import React, { useEffect, useState } from 'react';
import { OnCreateMessageSubscription } from '../../API';
import { Chat } from '../../components/chat/chat';
import { useGlobalContext } from '../../libs/context-lib';
import { IMessage } from '../../models/interfaces/message-interface';
import { messageCreateSubMapper } from '../../services/mapper/message-mapper';
import { GraphQLResult } from '@aws-amplify/api';
import * as MessageService from '../../services/message-service';
import { ChatMembers } from '../../components/chat/chat-members';
import { IUser } from '../../models/interfaces/user-interface';

interface IProps {
  orgaUsers: IUser[];
}

export const HomeChatContainer = (props: IProps) => {
  const { currentOrga } = useGlobalContext();

  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    let newMessages: IMessage[] = [];
    const fetchMessages = async () => {
      await MessageService.listMessagesByRefId(currentOrga.id).then(
        (data) => {
          setMessages(data);
          newMessages = data;
        },
        (error: any) => {
          console.log(error);
          alert('Get Messages failed!');
        }
      );
    };
    fetchMessages();

    const createMessageSubscription = MessageService.subscriptionCreateMessageByRefId(
      currentOrga.id
      // @ts-ignore
    ).subscribe({
      next: (createdMessage: {
        value: GraphQLResult<OnCreateMessageSubscription>;
      }) => {
        const newMessage = messageCreateSubMapper(createdMessage.value);
        // deep copy
        const newData: IMessage[] = [];
        newMessages!.forEach((val) => newData.push(Object.assign({}, val)));
        newData.push(newMessage);
        newMessages = newData;
        setMessages([...newData]);
      },
    });
    return () => {
      createMessageSubscription.unsubscribe();
    };
  }, [currentOrga.id]);

  const handleCreateMessage = async (message: IMessage) => {
    MessageService.createNewMessage(message).then(
      () => {},
      (error: any) => {
        console.log(error);
        alert('Create Message failed!');
      }
    );
  };

  return (
    <div>
      <div />
      <ChatMembers orgaUsers={props.orgaUsers} />
      <Chat
        orgaUsers={props.orgaUsers}
        messages={messages}
        createMessage={handleCreateMessage}
      />
    </div>
  );
};

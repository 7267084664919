import './index.css';
import './i18n';
import * as serviceWorker from './serviceWorker';
import Amplify, {Auth} from 'aws-amplify';
import App from './App';
import React, {Suspense} from 'react';
import ReactDOM from "react-dom";
import awsExports from './config/aws-exports';
import cognitoConfig from './config/cognito-config';
import oauth from './config/oauth-config';

Amplify.configure(awsExports);
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        identityPoolRegion: cognitoConfig.REGION,
        userPoolId: cognitoConfig.USER_POOL_ID,
        identityPoolId: cognitoConfig.IDENTITY_POOL_ID,
        userPoolWebClientId: cognitoConfig.APP_CLIENT_ID,
    },
    Storage: {
        identityPoolId: cognitoConfig.IDENTITY_POOL_ID,
    },
});
Auth.configure({oauth});

// const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
ReactDOM.render(
    <Suspense fallback="loading">
        <App/>
    </Suspense>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

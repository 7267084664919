import {
  AppBar,
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISession } from '../../../models/interfaces/session-interface';
import { useStyles } from './session-details-styles';
import TagIcon from '@mui/icons-material/LocalOffer';
import { DialogDeleteConfirm } from '../../shared/confirm-delete-dialog';
import Transition from '../../../utils/transition';
import { VoteContainer } from '../../../container/vote-container/vote-container';
import { IMessage } from '../../../models/interfaces/message-interface';
import SendIcon from '@mui/icons-material/Send';
import { useEventContext, useGlobalContext } from '../../../libs/context-lib';
import { v4 as uuidv4 } from 'uuid';
import { getChatTimestamp } from '../../../utils/iso-timestamp-converter';
import { IUser } from '../../../models/interfaces/user-interface';
import { checkPrivilegAdminOrOwner } from '../../../utils/check-privilege';
import { ConfirmDialog } from '../../shared/confirm-dialog';
import { useTheme } from '@mui/material/styles';

interface IProps {
  fetchingMessages: boolean;
  handleClose: () => void;
  handleDelete: (sessionId: string, eventId: string) => void;
  handleEdit: () => void;
  handleNewMessage: (message: IMessage) => void;
  handleStateChange: (session: ISession) => void;
  messages: IMessage[];
  orgaUsers: IUser[];
  session: ISession;
}

export const DialogSessionDetails = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();
  const { userInfo, currentOrga } = useGlobalContext();
  const { eventVotes } = useEventContext();
  const [session, setSession] = useState(props.session);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [orgaUsers, setOrgaUsers] = useState<IUser[]>([]);

  useEffect(() => {
    props.orgaUsers && setOrgaUsers(props.orgaUsers);
  }, [props.orgaUsers]);

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages]);

  useEffect(() => {
    setSession(props.session);
  }, [props.session]);

  const handleDelete = (value: boolean) => {
    value && props.handleDelete(session.id, session.eventId);

    setDeleteDialog(false);
  };

  const handleCancel = (value: boolean) => {
    if (value) {
      let newSession: ISession = session;
      newSession.canceled = true;
      props.handleStateChange(newSession);
    }

    setCancelDialog(false);
  };

  const handleReenable = () => {
    let newSession: ISession = session;
    newSession.canceled = false;
    props.handleStateChange(newSession);
    setCancelDialog(false);
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageValue(event.target.value);
  };

  const submitMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSendMessage();
  };

  const handleSendMessage = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event?.preventDefault();
    if (messageValue) {
      const newMessage: IMessage = {
        id: uuidv4(),
        userId: userInfo.sub,
        timestamp: new Date().toISOString(),
        content: messageValue,
        referenceId: session.id,
      };
      props.handleNewMessage(newMessage);
      setMessageValue('');
    }
  };

  const handleKeyPress = (target: React.KeyboardEvent<HTMLDivElement>) => {
    target.stopPropagation();
    if (target.key === 'Enter') {
      target.preventDefault();
      handleSendMessage();
    }
  };

  const getSpeakerBySub = (speakerId: string): string => {
    let returnValue = '';
    orgaUsers.forEach((user) => {
      if (user.sub === speakerId) {
        returnValue = `${user.given_name} ${user.family_name}`;
      }
    });
    return returnValue;
  };

  const getAvatarBySub = (speakerId: string): string => {
    let returnValue = '';
    orgaUsers.forEach((user) => {
      if (user.sub === speakerId) {
        returnValue = user.picture || '';
      }
    });
    return returnValue;
  };

  const getWriter = (writerId: string): string => {
    let returnValue = '';

    orgaUsers.forEach((user) => {
      if (user.sub === writerId) {
        returnValue = `${user.given_name} ${user.family_name}`;
      }
    });

    // add ananymous data if User ist not anymore in Orga
    if (!returnValue) {
      returnValue = `${t('label.removedUser')}`;
    }
    return returnValue;
  };

  return (
    <>
      {session !== undefined ? (
        <Dialog
          fullScreen
          open={true}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
                style={{ border: '1px solid' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6' className={classes.title}>
                {t('label.sessionDetails')}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <Paper className={classes.content}>
              <div style={{ display: 'flex' }}>
                <Typography style={{ paddingRight: '1rem' }} variant='h6'>
                  {session.title}
                  {session.canceled && ` (${t('label.canceled')})`}
                </Typography>
                <VoteContainer
                  sessionId={session.id}
                  votes={eventVotes.filter(
                    (vote) => vote.sessionId === session.id
                  )}
                />
              </div>
              <FormControlLabel
                style={session.mandatory ? {} : { display: 'none' }}
                control={<Checkbox checked={true} disabled />}
                label={t('label.mandatory')}
                labelPlacement='start'
              />
              <div>
                <Typography variant='subtitle1'>
                  {t('label.speaker')}:
                </Typography>
                {session.speaker.map((userSub) => (
                  // TODO get speaker by Sub
                  <div style={{ padding: '2px' }} key={userSub}>
                    <div style={{ display: 'inline-flex' }}>
                      <Avatar src={getAvatarBySub(userSub)}></Avatar>
                      <Typography
                        variant='subtitle2'
                        style={{ margin: '0.5rem' }}
                      >
                        {getSpeakerBySub(userSub)}
                      </Typography>
                    </div>
                  </div>
                ))}

                <FormControlLabel
                  style={session.extern ? {} : { display: 'none' }}
                  control={<Checkbox checked={true} disabled />}
                  label={t('label.extern')}
                  labelPlacement='start'
                />
              </div>
              {!session.mandatory && (
                <Typography variant='subtitle1'>
                  {t('label.duration')}: {session.duration}
                </Typography>
              )}
              <Typography variant='subtitle1'>
                {t('label.type')}: {t(`label.${session.type}`)}
              </Typography>
              <div>
                <Typography variant='subtitle1'>
                  {session.tags.map((tag) => (
                    <Chip
                      className={classes.chip}
                      key={tag}
                      label={tag}
                      icon={<TagIcon />}
                    />
                  ))}
                </Typography>
              </div>
              <div>
                <Typography variant='subtitle1'>
                  {t('label.description')}:
                </Typography>
                <fieldset className={classes.description}>
                  <Typography variant='body1'>
                    {session.description}
                  </Typography>
                </fieldset>
              </div>
              {(checkPrivilegAdminOrOwner(userInfo, currentOrga.id) ||
                userInfo.sub === session.creator) && (
                <div>
                  <Button
                    variant='contained'
                    onClick={props.handleEdit}
                    className={classes.buttons}
                  >
                    {t('label.edit')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => setDeleteDialog(true)}
                  >
                    {t('label.delete')}
                  </Button>
                  {session.starttime && session.canceled === false && (
                    <Button
                      variant='contained'
                      onClick={() => setCancelDialog(true)}
                      className={classes.buttons}
                    >
                      {t('label.cancelSession')}
                    </Button>
                  )}
                  {session.canceled === true && (
                    <Button
                      variant='contained'
                      onClick={() => handleReenable()}
                      className={classes.buttons}
                    >
                      {t('label.reenableSession')}
                    </Button>
                  )}
                </div>
              )}
            </Paper>
            {!props.fetchingMessages ? (
              <div>
                <div className={classes.chatRoot}>
                  <div>
                    <Typography variant='subtitle1'>
                      {t('label.comments')}
                    </Typography>
                  </div>
                  <Paper variant='outlined' className={classes.chatContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justifyContent='center' spacing={1}>
                          {messages.map((message) => (
                            <div
                              className={classes.messageContainer}
                              style={{ alignContent: 'right' }}
                              key={message.id}
                            >
                              <Grid key={message.id + '-grid'} item xs={12}>
                                <Paper className={classes.messagePaper}>
                                  <Grid container>
                                    <Grid
                                      key={message.id + '-user'}
                                      item
                                      xs={6}
                                    >
                                      {getWriter(message.userId)}
                                    </Grid>
                                    <Grid
                                      key={message.id + '-stamp'}
                                      item
                                      xs={6}
                                      style={{ textAlign: 'right' }}
                                    >
                                      {getChatTimestamp(message.timestamp)}
                                    </Grid>
                                    <Grid
                                      key={message.id + '-text'}
                                      item
                                      xs={10}
                                      style={{ paddingTop: '0.3rem' }}
                                    >
                                      <Typography
                                        variant='body1'
                                        gutterBottom
                                        style={{ whiteSpace: 'pre-line' }}
                                      >
                                        {message.content}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.messageInputContainer}>
                        <form onSubmit={submitMessage}>
                          <Paper className={classes.rootInput}>
                            <InputBase
                              className={classes.input}
                              placeholder='Click here to write'
                              inputProps={{ 'aria-label': 'send-message' }}
                              value={messageValue}
                              onChange={handleChangeMessage}
                              onKeyPress={handleKeyPress}
                            />
                            <IconButton
                              className={classes.iconButton}
                              aria-label='send'
                              onClick={handleSendMessage}
                              type='submit'
                            >
                              <SendIcon />
                            </IconButton>
                          </Paper>
                        </form>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <CircularProgress />
            )}
            <DialogDeleteConfirm
              open={deleteDialog}
              handleClose={handleDelete}
              title={t('titel.deleteSessionDialog')}
              text={t('text.deleteSessionDialog')}
            />
            <ConfirmDialog
              open={cancelDialog}
              handleClose={handleCancel}
              title={t('titel.cancelSessionDialog')}
              text={t('text.cancelSessionDialog')}
            />
          </div>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={true}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
              >
                <ArrowBackIcon />
              </IconButton>
            </Toolbar>
            <div>...waiting for data</div>
          </AppBar>
        </Dialog>
      )}
    </>
  );
};

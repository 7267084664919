/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSession = /* GraphQL */ `
  query GetSession($Id: String!, $EventId: String!) {
    getSession(Id: $Id, EventId: $EventId) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: TableSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        EventId
        Id
        Creator
        Description
        Starttime
        Endtime
        RoomId
        Speaker
        Tags
        Title
        Type
        Duration
        Extern
        Mandatory
        Canceled
      }
      nextToken
    }
  }
`;
export const getBlocker = /* GraphQL */ `
  query GetBlocker($Id: String!, $EventId: String!) {
    getBlocker(Id: $Id, EventId: $EventId) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const listBlockers = /* GraphQL */ `
  query ListBlockers(
    $filter: TableBlockerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        EventId
        Id
        UserId
        Name
        Starttime
        Endtime
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($Id: String!, $OrganisationId: String!) {
    getEvent(Id: $Id, OrganisationId: $OrganisationId) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: TableEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        OrganisationId
        EventStatus
        Date
        Starttime
        Endtime
        SubmitClosed
        VotingClosed
        Archived
      }
      nextToken
    }
  }
`;
export const getOrga = /* GraphQL */ `
  query GetOrga($Id: String!) {
    getOrga(Id: $Id) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const listOrgas = /* GraphQL */ `
  query ListOrgas(
    $filter: TableOrgaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        CurrentEvent
        Name
        PrimaryColor
        SecondaryColor
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($Id: String!, $EventId: String!) {
    getRoom(Id: $Id, EventId: $EventId) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: TableRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        EventId
        Id
        Name
        Seats
      }
      nextToken
    }
  }
`;
export const getRoomLink = /* GraphQL */ `
  query GetRoomLink($Id: String!, $ReferenceId: String!) {
    getRoomLink(Id: $Id, ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const listRoomLinks = /* GraphQL */ `
  query ListRoomLinks(
    $filter: TableRoomLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        ReferenceId
        Label
        Link
        AcrossRoom
        ActivityDetail
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($Id: String!, $OrganisationId: String!) {
    getTag(Id: $Id, OrganisationId: $OrganisationId) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: TableTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        OrganisationId
        Label
        UseCounter
      }
      nextToken
    }
  }
`;
export const getVote = /* GraphQL */ `
  query GetVote($Id: String!) {
    getVote(Id: $Id) {
      Id
      SessionId
      UserId
      EventId
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: TableVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        SessionId
        UserId
        EventId
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($Id: String!, $ReferenceId: String!) {
    getMessage(Id: $Id, ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: TableMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        ReferenceId
        Content
        Timestamp
        UserId
      }
      nextToken
    }
  }
`;

function awsmobile() {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging') {
    return {
      aws_appsync_graphqlEndpoint:
        'https://5w5luh5n45eivgod2cyv2vqhtm.appsync-api.eu-central-1.amazonaws.com/graphql',
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    };
  } else {
    return {
      aws_appsync_graphqlEndpoint:
        'https://e5zhl2wllbd5lo5jz67w5ygcm4.appsync-api.eu-central-1.amazonaws.com/graphql',
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    };
  }
}
export default awsmobile();

import React, { useEffect, useState } from 'react';
import { OrgaListTable } from '../../components/profile/orga-list-table';
import { useGlobalContext } from '../../libs/context-lib';
import { IOrganization } from '../../models/interfaces/organization-interface';
import * as OrgaService from '../../services/organisation-service';
import * as UserService from '../../services/user-service';

export const ProfileOrgasContainer = () => {
  // group of orgas from the user with role & sessions held
  const {
    userInfo,
    setUserInfo,
    currentOrga,
    setCurrentOrga,
  } = useGlobalContext();
  const [orgas, setOrgas] = useState<IOrganization[]>([]);

  useEffect(() => {
    const fetchedOrgas: IOrganization[] = [];
    const fetchOrgas = () => {
      userInfo.groups.forEach(async (group) => {
        await OrgaService.getOrgaByGroup(group).then((data) => {
          if (data) {
            const newData: any = [];
            fetchedOrgas.push(data);
            // deep copy
            fetchedOrgas!.forEach((val) =>
              newData.push(Object.assign({}, val))
            );
            setOrgas(newData);
          }
        });
      });
    };

    fetchOrgas();
  }, [userInfo]);

  const handleRemoveFromOrga = async (orgaPool: string): Promise<boolean> => {
    let value = false;
    await UserService.removeOwnGroup(userInfo.sub, orgaPool).then(() => {
      let newUser = Object.assign({}, userInfo);
      let groups: string[] = [];
      newUser.groups.forEach((group) => {
        if (group !== orgaPool) {
          groups.push(group);
        }
      });
      newUser.groups = groups;
      setUserInfo(newUser);
      if (orgaPool.substring(0, 36) === currentOrga.id) {
        setCurrentOrga({ id: '', name: '' });
      }
      value = true;
    });
    return value;
  };

  return (
    <div style={{ marginBottom: '3rem' }}>
      <OrgaListTable
        orgas={orgas}
        handleRemoveFromOrga={handleRemoveFromOrga}
      />
    </div>
  );
};

import {
    Button,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, {FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LightTooltip, useStyles} from './register-styles';
import HelpIcon from '@mui/icons-material/Help';
import logo from '../../images/CopeLogo.png';
import {RegisterComplDialog} from './dialogs/register-compl-dialog';
import {useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';

interface IProps {
    isLoading: boolean;
    registerComplete: boolean;
    handleSubmit: (
        email: string,
        password: string,
        family_name: string,
        given_name: string,
        orgaKey?: string
    ) => void;
}

export const Register = (props: IProps) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [prename, setPrename] = useState('');
    const [lastname, setLastname] = useState('');
    const [orgaKey, setOrgaKey] = useState('');
    const [openPwTt, setOpenPwTt] = useState(false); // infotext for pw
    const [openKeyTt, setOpenKeyTt] = useState(false); // infotext for orgaKey
    const [isLoading, setIsLoading] = useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [registerCompl, setRegisterCompl] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    useEffect(() => {
        setRegisterCompl(props.registerComplete);
    }, [props.registerComplete]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.handleSubmit(email, password, lastname, prename, orgaKey);
    };

    const handleCloseDialog = () => {
        navigate('/login');
    };

    useEffect(() => {
        const inviteKey = localStorage.getItem('inviteKey');
        if (inviteKey) {
            setOrgaKey(inviteKey);
            localStorage.removeItem('inviteKey');
        }
    }, []);

    const getSubmitTooltipText = (): string => {
        if (
            prename === '' ||
            lastname === '' ||
            email === '' ||
            password === '' ||
            passwordConfirm === ''
        ) {
            return t('tooltip.fillAllReqFields');
        } else if (password !== passwordConfirm) {
            return t('tooltip.passwordsDontMatch');
        } else if (password.length < 6) {
            return t('tooltip.passwordTooShort');
        } else {
            return '';
        }
    };

    return (
        <div className={classes.root}>
            <img className={classes.logo} src={logo} alt='logo'/>
            <Typography variant='h5'>{t('label.register')}</Typography>
            <form onSubmit={handleSubmit} autoComplete='off'>
                <Grid className={classes.gridContainer} container justifyContent='center'>
                    <Grid className={classes.gridItem} item xs={12}>
                        <TextField
                            label={t('label.prename')}
                            variant='outlined'
                            autoFocus
                            type='text'
                            value={prename}
                            autoComplete='prename'
                            onChange={(e) => setPrename(e.target.value)}
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <TextField
                            label={t('label.lastname')}
                            variant='outlined'
                            type='text'
                            value={lastname}
                            autoComplete='lastname'
                            onChange={(e) => setLastname(e.target.value)}
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <TextField
                            label={t('label.email')}
                            variant='outlined'
                            type='email'
                            value={email}
                            autoComplete='email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <TextField
                            label={t('label.password')}
                            variant='outlined'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete='password'
                            type='password'
                            style={{marginLeft: '48px'}}
                        />
                        <ClickAwayListener onClickAway={() => setOpenPwTt(false)}>
                            <LightTooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={() => setOpenPwTt(false)}
                                open={openPwTt}
                                disableFocusListener
                                disableTouchListener
                                disableHoverListener
                                title={t('text.RegisterPwInfo').toString()}
                            >
                                <IconButton
                                    onClick={() => setOpenPwTt(!openPwTt)}
                                    style={{width: '48px'}}
                                >
                                    <HelpIcon/>
                                </IconButton>
                            </LightTooltip>
                        </ClickAwayListener>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <TextField
                            label={t('label.passwordConfirm')}
                            variant='outlined'
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            autoComplete='passwordConfirm'
                            type='password'
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <TextField
                            label={t('label.orgaKeyOptional')}
                            variant='outlined'
                            value={orgaKey}
                            onChange={(e) => setOrgaKey(e.target.value)}
                            autoComplete='key'
                            type='text'
                            style={{marginLeft: '48px'}}
                        />
                        <ClickAwayListener onClickAway={() => setOpenKeyTt(false)}>
                            <LightTooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={() => setOpenKeyTt(false)}
                                open={openKeyTt}
                                disableFocusListener
                                disableTouchListener
                                disableHoverListener
                                title={t('text.RegisterOrgaKeyInfo').toString()}
                            >
                                <IconButton
                                    onClick={() => setOpenKeyTt(!openKeyTt)}
                                    style={{width: '48px'}}
                                >
                                    <HelpIcon/>
                                </IconButton>
                            </LightTooltip>
                        </ClickAwayListener>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={privacyPolicyAccepted}
                                    onChange={(event) => {
                                        setPrivacyPolicyAccepted(event.target.checked);
                                    }}
                                    name='chebkboxPP'
                                />
                            }
                            label={t('lable.iHaveReadThePrivacyPolicy')}
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                        <Link href='#/privacy-policy' variant="body2" target='_blank'>
                            {t('label.privacyPolicy')}
                        </Link>
                    </Grid>
                </Grid>
                {isLoading ? (
                    <CircularProgress className={classes.submitButton}/>
                ) : (
                    <Tooltip title={getSubmitTooltipText()} arrow>
            <span>
              <Button
                  className={classes.submitButton}
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={
                      password !== passwordConfirm ||
                      password.length < 6 ||
                      email === '' ||
                      prename === '' ||
                      lastname === '' ||
                      !privacyPolicyAccepted
                  }
              >
                {t('label.register')}
              </Button>
            </span>
                    </Tooltip>
                )}
                <div>
                    <div style={{textAlign: 'center'}}>
                        <Link
                            className={classes.registerLink}
                            href='#/login'
                            variant='body2'
                        >
                            {t('label.backToLogin')}
                        </Link>
                    </div>
                </div>
            </form>
            <RegisterComplDialog
                open={registerCompl}
                handleClose={handleCloseDialog}
            />
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import Transition from '../../../utils/transition';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../../libs/context-lib';
import { ScheduleLoading } from '../../calendar/loading-view';
import { ISession } from '../../../models/interfaces/session-interface';
import { IEvent } from '../../../models/interfaces/event-interface';
import { IVote } from '../../../models/interfaces/vote-interface';
import { IBlocker } from '../../../models/interfaces/blocker-interface';
import * as BlockerService from '../../../services/blocker-service';
import * as ScheduleService from '../../../services/schedule-service';
import * as RoomService from '../../../services/room-service';
import * as BlockerMapper from '../../../services/mapper/blocker-mapper';
import * as SessionMapper from '../../../services/mapper/session-mapper';
import * as RoomMapper from '../../../services/mapper/room-mapper';
import * as VoteMapper from '../../../services/mapper/vote-mapper';
import * as OrgaMapper from '../../../services/mapper/orga-mapper';
import * as EventMapper from '../../../services/mapper/event-mapper';
import * as VoteService from '../../../services/vote-service';
import * as SessionService from '../../../services/session-service';
import * as EventService from '../../../services/event-service';
import { IRoom } from '../../../models/interfaces/room-interface';

interface IProps {
  open: boolean;
  handleClose: (openDialog: boolean) => void;
  handleUpdateEvent: (event: IEvent) => void;
}

export const DialogCreateSchedule = (props: IProps) => {
  const { t } = useTranslation();
  const { currentOrga, currentEvent } = useGlobalContext();
  const [breakDuration, setBreakDuration] = useState<number>(5);
  const [scheduledSessions, setScheduledSessions] = useState<ISession[]>([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (scheduledSessions.length !== 0) {
      //update sessions
      scheduledSessions.forEach((session) => {
        SessionService.updateSessionFull(session);
      });
      setProcessing(false);
    }
  }, [scheduledSessions]);

  const handleCreate = () => {
    EventService.updateEventFull(currentEvent);
    createSchedule();
    props.handleClose(false);
  };

  const handleChangeBreakDuration = (newBreakDuration: number) => {
    setBreakDuration(newBreakDuration);
  };

  const handleUpdateEventStarttime = (start: string) => {
    props.handleUpdateEvent({ ...currentEvent, starttime: start });
  };

  const handleUpdateEventEndtime = (end: string) => {
    props.handleUpdateEvent({ ...currentEvent, endtime: end });
  };


  const getAllSessions = async () => {
    let allSessions: ISession[] = [];
    await SessionService.listSessionsByEventId(currentEvent.id).then(
      (data: ISession[]) => {
        data.forEach((session) => allSessions.push(Object.assign({}, session)));
      }
    );

    return allSessions;
  };

  const getAllVotes = async (): Promise<IVote[]> => {
    let allVotes: IVote[] = [];

  
      const votes: IVote[] = await getEventVotes();
      votes.forEach((vote) => {
        allVotes.push(Object.assign({}, vote));
      });


    return allVotes;
  };

  const getEventVotes = async () => {
    let votes: IVote[] = [];

    await VoteService.listVotesByEventId(currentEvent.id).then((data: IVote[]) => {
      data.forEach((vote) => votes.push(Object.assign({}, vote)));
    }); 

    return votes;
  };

  const getAllRooms = async () => {
    let rooms: IRoom[] = [];

    await RoomService.listRoomsByEventId(currentEvent.id).then((data: IRoom[]) => {
      data.forEach((room) => rooms.push(Object.assign({}, room)));
    });

    return rooms;
  }

  const getAllBlockers = async () => {
    let allBlockers: IBlocker[] = [];
    await BlockerService.listBlockerByEventId(currentEvent.id).then(
      (data: IBlocker[]) => {
        data.forEach((blocker) => {
          allBlockers.push(Object.assign({}, blocker));
        });
      }
    );

    return allBlockers;
  };


  const localParseTimeToISODate = (event: string, time: string) => {
    //receives 'hh:mm' entry and returns an ISO Date String with the current event date as day
    let splitHoursMinutes: string[] = time.split(':');
    let eventDate: Date = new Date(event);

    let resultDate: Date = new Date(Date.UTC(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate(),
      parseInt(splitHoursMinutes[0]),
      parseInt(splitHoursMinutes[1]),
      0,
      0
    ));
    return resultDate.toISOString();
  };

  const createSchedule = async () => {
    setProcessing(true);

    let sessionsToSchedule: ISession[] = [];
    let votesForSchedule: IVote[] = [];
    let blockersForSchedule: IBlocker[] = [];
    let roomsForSchedule: IRoom[] = [];

    await getAllSessions().then((data: ISession[]) => {
      data.forEach((session) =>
        sessionsToSchedule.push(Object.assign({}, session))
      );
    });

    await getAllVotes().then((data: IVote[]) => {
      data.forEach((vote) => {
        votesForSchedule.push(Object.assign({}, vote));
      });
    });

    await getAllBlockers().then((data: IBlocker[]) => {
      data.forEach((blocker) => {
        blockersForSchedule.push(Object.assign({}, blocker));
      });
    });

    await getAllRooms().then((data: IRoom[]) => {
      data.forEach((room) => {
        roomsForSchedule.push(Object.assign({}, room));
      });
    });

    await ScheduleService.createScheduleAndGetSessions(
      RoomMapper.roomListToEntityListMapper(roomsForSchedule),
      SessionMapper.sessionListToEntityListMapper(sessionsToSchedule),
      BlockerMapper.blockerListToEntityListMapper(blockersForSchedule),
      VoteMapper.voteListToEntityListMapper(votesForSchedule),
      OrgaMapper.orgaToEntityMapper(currentOrga),
      EventMapper.eventToEntityMapper(currentEvent),
      breakDuration,
      localParseTimeToISODate(currentEvent.date, currentEvent.starttime),
      localParseTimeToISODate(currentEvent.date, currentEvent.endtime)
    ).then((data: ISession[]) => {
      setScheduledSessions(data);
    });
  };

  return !processing ? (
    <Dialog open={props.open} TransitionComponent={Transition}>
      <DialogTitle id="alert-dialog-slide-title">
        {t('header.createSchedule')}
      </DialogTitle>
      <DialogContent>
        <TextField
          id="starttime-sc"
          type="time"
          defaultValue={currentEvent.starttime}
          label={t('label.starttime')}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 5,
          }}
          onChange={(change) => handleUpdateEventStarttime(change.target.value)}
        />
        <TextField
          id="endtime-sc"
          type="time"
          defaultValue={currentEvent.endtime}
          label={t('label.endtime')}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 5,
          }}
          onChange={(change) => handleUpdateEventEndtime(change.target.value)}
        />
        <TextField
          id="breaktime-sc"
          type="number"
          defaultValue={breakDuration}
          label={t('text.breakDuration')}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 0,
            //max: 60,
            step: 5,
          }}
          onChange={(change) =>
            handleChangeBreakDuration(parseInt(change.target.value))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{ color: 'red' }}
          onClick={() => {
            props.handleClose(false);
          }}
        >
          {t('label.cancel')}
        </Button>
        <Button variant="outlined" onClick={handleCreate}>
          {t('label.create')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <ScheduleLoading open={processing} />
  );
};

import {Collapse, Divider, IconButton, Menu, MenuItem,} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useGlobalContext} from '../../libs/context-lib';
import {checkPrivilegAdmin, checkPrivilegCreator, checkPrivilegOwner,} from '../../utils/check-privilege';

interface IProps {
    setHomeIndex: Function;
}

export const BurgerMenu = (props: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const {userInfo, currentOrga} = useGlobalContext();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isOrgaOwner, setIsOrgaOwner] = useState(false);
    const [isCreator, setIsCreator] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [
        anchorElBurger,
        setAnchorElBurger,
    ] = React.useState<null | HTMLElement>(null);
    const openBurger = Boolean(anchorElBurger);

    useEffect(() => {
        setIsAdmin(checkPrivilegAdmin(userInfo, currentOrga.id));
        setIsOrgaOwner(checkPrivilegOwner(userInfo, currentOrga.id));
    }, [userInfo, currentOrga.id]);

    useEffect(() => {
        setIsCreator(checkPrivilegCreator(userInfo));
    }, [userInfo]);

    const handleMenuBurger = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElBurger(event.currentTarget);
    };

    const handleNav = (path: string) => {
        setAnchorElBurger(null);
        if (location.pathname !== path) {
            props.setHomeIndex(null);
            navigate(path);
        }
    };

    const setHomeNav = (index: number) => {
        setAnchorElBurger(null);
        props.setHomeIndex(index);
        location.pathname !== '/' && navigate('/');
    };

    const handleCloseBurger = () => {
        setAnchorElBurger(null);
    };

    const handleCollapse = () => {
        setCollapseOpen(!collapseOpen);
    };

    return (
        <>
            <IconButton
                edge='start'
                color='inherit'
                aria-label='menu'
                aria-controls='menu-burger'
                aria-haspopup='true'
                onClick={handleMenuBurger}
            >
                <MenuIcon fontSize='large'/>
            </IconButton>
            <Menu
                id='menu-burger'
                anchorEl={anchorElBurger}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={openBurger}
                onClose={handleCloseBurger}
            >
                <MenuItem
                    onClick={() => {
                        setHomeNav(0);
                    }}
                >
                    {t('label.calendar')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setHomeNav(1);
                    }}
                >
                    {t('label.sessions')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setHomeNav(2);
                    }}
                >
                    {t('label.chat')}
                </MenuItem>
                {/* <MenuItem onClick={() => handleNav('/my-sessions')}>
          {t('label.mySessions')}
        </MenuItem>
        <MenuItem onClick={handleCloseBurger}>
          {t('label.sessionHistory')}
        </MenuItem> */}
                {(isAdmin || isOrgaOwner) && (
                    <div>
                        <Divider/>
                        <Divider/>
                        <MenuItem onClick={handleCollapse}>
                            {t('label.administration')}
                            {collapseOpen ? <ExpandLess/> : <ExpandMore/>}
                        </MenuItem>
                        <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
                            <MenuItem
                                onClick={() => {
                                    handleNav('/manage-users');
                                }}
                            >
                                {t('label.manageUsers')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleNav('/current-event');
                                }}
                            >
                                {t('label.currentEvent')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleNav('/events');
                                }}
                            >
                                {t('label.manageEvents')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleNav('/manage-orga');
                                }}
                            >
                                {t('label.myOrga')}
                            </MenuItem>
                        </Collapse>
                    </div>
                )}
                {isCreator && (
                    <MenuItem
                        onClick={() => {
                            handleNav('/manage-orgas');
                        }}
                    >
                        {t('label.manageOrgas')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './orga-select-list-styles';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';
import { IOrganization } from '../../models/interfaces/organization-interface';

interface IProps {
  orgas: IOrganization[];
  currentOrga: IOrganization;
  setCurrentOrga: (value: IOrganization) => void;
}

export const OrgaSelectList = (props: IProps) => {
  const classes = useStyles();
  const [orgas, setOrgas] = useState<IOrganization[]>([]);

  const handleChange = (selectedOrga: IOrganization) => {
    props.setCurrentOrga(selectedOrga);
  };

  useEffect(() => {
    setOrgas(props.orgas);
  }, [props.orgas]);

  return (
    <>
      <Grid container spacing={0} className={classes.root}>
        {orgas.map((orga) => (
          <Grid item key={orga!.id} className={classes.innerGrid}>
            <Card className={classes.card} onClick={() => handleChange(orga)}>
              <CardContent className={classes.cContent}>
                <Typography gutterBottom variant='h6'>
                  {orga?.name}
                </Typography>
                {props.currentOrga.id === orga.id ? (
                  <RadioButtonCheckedIcon className={classes.radioIcon} />
                ) : (
                  <RadioButtonIcon className={classes.radioIcon} />
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

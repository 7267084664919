let ApiURl: string;

if (process.env.NODE_ENV === 'development') {
    ApiURl =
        'http://cope-userservice-dev-expressjs.eu-central-1.elasticbeanstalk.com/graphql';
    //Ignore prod in naming
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
    ApiURl = 'https://dcoispd3vkcx4.cloudfront.net';
} else {
    ApiURl = 'https://d1y3f2n3zpl7ny.cloudfront.net';
}

export default ApiURl;

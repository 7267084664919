function cognito() {
  if (process.env.NODE_ENV === 'development') {
    return {
      REGION: 'eu-central-1',
      USER_POOL_ID: 'eu-central-1_QuOnV3PgP',
      APP_CLIENT_ID: '4esrpo1alhblpegum1hp5ub0ki',
      IDENTITY_POOL_ID: 'eu-central-1:51057c9a-f589-4a0c-ac05-40b0aa6150c3',
    };
  } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
    return {
      REGION: 'eu-central-1',
      USER_POOL_ID: 'eu-central-1_QuOnV3PgP',
      APP_CLIENT_ID: '4esrpo1alhblpegum1hp5ub0ki',
      IDENTITY_POOL_ID: 'eu-central-1:51057c9a-f589-4a0c-ac05-40b0aa6150c3',
    };
  } else {
    return {
      REGION: 'eu-central-1',
      USER_POOL_ID: 'eu-central-1_Bu4WIBzpW',
      APP_CLIENT_ID: '5p3urg98h6gdfsqmil41jkdath',
      IDENTITY_POOL_ID: 'eu-central-1:33f68c97-6a98-4db4-943e-8696d8253f1b',
    };
  }
}

export default cognito();

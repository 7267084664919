import { IUser } from '../../models/interfaces/user-interface';
import React, {useEffect, useState} from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, TableFooter, TablePagination } from '@mui/material';
import { useStyles } from './user-manage-styles';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../libs/context-lib';
import { ConfirmDialog } from '../shared/confirm-dialog';

interface IProps {
    data: IUser[];
    rowsPerPageOptions: (number | {label: string, value: number})[];
    tableType: string;
    handleAcceptUser: (toAddUserId: string) => void;
    handleDeclineUser: (declinedUserId: string) => void;
    handleRemoveUser: (deletedUserId: string) => void;
    handleSetUserToAdmin: (upgradedUserId: string) => void;
    handleRemoveUserFromAdmin: (removedUserId: string) => void;
    handleSetUserToOwner: (upgradedUserId: string) => void;
    handleRemoveUserFromOwner: (removedUserId: string) => void;
    privilegeCheck? : (userInfo: IUser, currentOrgaId: string) => boolean;
}

export const UserTableWithPagination = (props: IProps) => {
    const [data, setData] = useState<IUser[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [emptyRow, setEmptyRow] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();
    const { userInfo, currentOrga } = useGlobalContext();
    const [dialogTargetId, setDialogTargetId] = useState('');
    const [dialogAction, setDialogAction] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [privilege, setPrivilege] = useState(false);
    const [upgradeDialog, setUpgradeDialog] = useState('');
    const [downgradeDialog, setDowngradeDialog] = useState('');

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        setEmptyRow(rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage));
    }, [rowsPerPage, data, page]);

    useEffect(() => {
        if (props.privilegeCheck !== undefined && props.privilegeCheck !== null) {
            setPrivilege(props.privilegeCheck(userInfo, currentOrga.id));
        }
    }, [userInfo, currentOrga.id, props]);

    useEffect(() => {
        if (props.tableType === 'request') {
            setDowngradeDialog('decline');
        } else if (props.tableType === 'user') {
            setDowngradeDialog('removeUser');
        } else if (props.tableType === 'admin') {
            setUpgradeDialog('setOwner');
            setDowngradeDialog('removeAdmin');
        } else if (props.tableType === 'owner') {
            setDowngradeDialog('removeOwner');
        }
    }, [props.tableType]);

    useEffect(() => {
        if (dialogAction === 'decline') {
            setDialogTitle(t<string>('titel.decline'));
            setDialogText(t<string>('text.decline'));
        } else if (dialogAction === 'removeUser') {
            setDialogTitle(t<string>('titel.remove'));
            setDialogText(t<string>('text.remove'));
        } else if (dialogAction === 'setOwner') {
            setDialogTitle(t<string>('titel.upgradeToOwner'));
            setDialogText(t<string>('text.upgradeToOwner'));
        } else if (dialogAction === 'removeAdmin') {
            setDialogTitle(t<string>('titel.downgrade'));
            setDialogText(t<string>('text.downgrade'));
        } else if (dialogAction === 'removeOwner') {
            setDialogTitle(t<string>('titel.downgradeOwner'));
            setDialogText(t<string>('text.downgradeOwner'));
        }
    }, [dialogAction, t])

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newUsersPage: number) => {
        setPage(newUsersPage);
    }
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleCloseDialog = (value: boolean) => {
        if (value && dialogTargetId !== '') {
            if (dialogAction === 'accept') {
                props.handleAcceptUser(dialogTargetId);
            } else if (dialogAction === 'decline') {
                props.handleDeclineUser(dialogTargetId);
            } else if (dialogAction === 'setAdmin') {
                props.handleSetUserToAdmin(dialogTargetId);
            } else if (dialogAction === 'removeUser') {
                props.handleRemoveUser(dialogTargetId);
            } else if (dialogAction === 'setOwner') {
                props.handleSetUserToOwner(dialogTargetId);
            } else if (dialogAction === 'removeAdmin') {
                props.handleRemoveUserFromAdmin(dialogTargetId);
            } else if (dialogAction === 'removeOwner') {
                props.handleRemoveUserFromOwner(dialogTargetId);
            }
            
            setPage(0);
            setDialogTargetId('');
            setDialogAction('');
        }
    }
    
    return (
        <Table aria-label='simple table'>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell>{t('label.email')}</TableCell>
                    <TableCell>{t('label.prename')}</TableCell>
                    <TableCell>{t('label.lastname')}</TableCell>
                    <TableCell align='center'></TableCell>
                    <TableCell align='center'></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, (page + 1) * rowsPerPage) : data).map((dataPoint) => (
                    <TableRow key={dataPoint.sub}>
                        <TableCell component='th' scope='row'>
                            {dataPoint.email}
                        </TableCell>
                        <TableCell>{dataPoint.given_name}</TableCell>
                        <TableCell>{dataPoint.family_name}</TableCell>
                        <TableCell align='center'>
                            {(props.tableType === 'request' || props.tableType === 'user') ? (
                                <Button
                                    className={classes.acceptButton}
                                    variant='outlined'
                                    onClick={() => {
                                        (props.tableType === 'request') ? 
                                        props.handleAcceptUser(dataPoint.sub) : props.handleSetUserToAdmin(dataPoint.sub);
                                        setPage(0);
                                    }}
                                >
                                    {(props.tableType === 'request') ? t('label.accept') : t('label.setAsAdmin')}
                                </Button>
                            ) : (
                                <>
                                    {(props.tableType === 'admin' && privilege) && (
                                    <Button
                                        className={classes.acceptButton}
                                        variant='outlined'
                                        onClick={() => {
                                            setDialogAction(upgradeDialog);
                                            setDialogTargetId(dataPoint.sub);
                                        }}
                                    >
                                        {t('label.upgradeToOwner')}
                                    </Button>)}
                                </>
                            )}
                        </TableCell>
                        <TableCell align='center'>
                            {(props.tableType === 'request' || props.tableType === 'user') ? (
                                <Button
                                    className={classes.deleteButton}
                                    variant='outlined'
                                    onClick={() => {
                                        setDialogAction(downgradeDialog);
                                        setDialogTargetId(dataPoint.sub);
                                    }}
                                >
                                    {(props.tableType === 'request') ? t('label.decline') : t('label.remove')}
                                </Button>
                            ) : (
                                <>
                                    {(props.tableType === 'admin' || (props.tableType === 'owner' && privilege)) && (
                                    <Button
                                        className={classes.downgradeButton}
                                        variant='outlined'
                                        onClick={() => {
                                            setDialogAction(downgradeDialog);
                                            setDialogTargetId(dataPoint.sub);
                                        }}
                                    >
                                        {(props.tableType === 'admin') ? t('label.removeAdminRight') : t('label.downgradeToAdmin')}
                                    </Button>)}
                                </>
                            )}
                        </TableCell>
                        
                    </TableRow>
                ))}
                {emptyRow > 0 && (
                    <TableRow style={{height: 53 * emptyRow}}>
                        <TableCell component='th' scope='row'></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={props.rowsPerPageOptions}
                        colSpan={5}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('label.rowsPerPage')}
                        labelDisplayedRows={({from, to, count}) => 
                        `${from}-${to} ${t('label.of')} ${(count !== -1) ? (count) : `more than ${to}`}`}
                    />
                </TableRow>
            </TableFooter>
            {dialogTargetId !== '' && (
                <ConfirmDialog
                    open={true}
                    handleClose={handleCloseDialog}
                    title={dialogTitle}
                    text={dialogText}
                />
            )}
        </Table>
    )
}

import React, { ReactNode } from "react"

interface IProps {
    index: string,
    value: string,
    children: ReactNode
}

export const TabPanel = (props: IProps) => {

    const {index, value, children} = props;

    return(
        <div>
            {index === value && children}
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { IEvent } from '../models/interfaces/event-interface';
import { EditableTable } from './shared/editable-table';

const dataSource: IEvent[] = [
  {
    id: 'id1',
    eventStatus: 'created',
    date: '2020-12-11',
    organizationId: 'orgaId',
    starttime: '12:00',
    endtime: '17:00',
    votingClosed: false,
    submitClosed: false,
    archived: false,
  },
  {
    id: 'id2',
    eventStatus: 'status',
    date: '2020-12-24',
    organizationId: 'orgaId',
    starttime: '10:00',
    endtime: '12:00',
    votingClosed: false,
    submitClosed: false,
    archived: false,
  },
];

const cols = [
  { prop: 'date', type: 'date', colSpan: 2 },
  { prop: 'eventStatus' },
  { prop: 'starttime', type: 'time' },
  { prop: 'endtime', type: 'time' },
  { prop: 'archived', type: 'button', colSpan: 2 },
  { prop: 'votingClosed', type: 'checkbox' },
];

export const EventManageList = () => {
  const [dataS, setDataS] = useState<IEvent[]>();

  useEffect(() => {
    setDataS(dataSource);
  }, []);

  const handleAddRow = (): IEvent => {
    const newData = {
      id: 'id3',
      eventStatus: 'status',
      date: '2020-12-11',
      organizationId: 'orgaId',
      starttime: '10:00',
      endtime: '17:00',
      votingClosed: false,
      submitClosed: false,
      archived: false,
    } as IEvent;

    setDataS([...dataS!, newData]);

    return newData;
  };

  const handleUpdateData = (newRow: IEvent, oldRow: IEvent) => {
    updateDataSource(newRow, oldRow);
  };

  const handleCreateData = (newRow: IEvent, oldRow: IEvent) => {
    updateDataSource(newRow, oldRow);
  };

  const updateDataSource = (newRow: IEvent, oldRow: IEvent) => {
    const newData: any = [];
    // deep copy
    dataS!.forEach((val) => newData.push(Object.assign({}, val)));
    const index = newData.findIndex((x: IEvent) => x.id === oldRow.id);
    if (index !== -1) {
      newData[index] = newRow;
      setDataS(newData);
    }
  };

  const handleDeleteData = (row: IEvent) => {
    const newData: any = [];
    // deep copy
    dataS!.forEach((val) => newData.push(Object.assign({}, val)));
    const index = newData!.findIndex((x: IEvent) => x.id === row.id);
    newData!.splice(index, index >= 0 ? 1 : 0);
    setDataS([...newData]);
  };

  const handleStatusClick = (row: IEvent, col: string) => {
    alert(
      `Click on ${row.date} button col: ${col}. Wach logs you got the full Object back!`
    );
  };

  return (
    <div>
      <div> Editable Table: React TypeScript mit MUI.</div>
      <div>
        Labels/Bezeichnungen werden mit i18n translations für jede sprache in
        einer .json festgelegt z.B. de-DE
      </div>
      <div>
        <a href='https://www.npmjs.com/package/i18n'>
          https://www.npmjs.com/package/i18n
        </a>
      </div>
      <EditableTable
        handleUpdateData={handleUpdateData}
        handleCreateData={handleCreateData}
        handleDeleteData={handleDeleteData}
        handleTableButtonClick={handleStatusClick}
        updateDataSource={setDataS}
        addDataRow={handleAddRow}
        dataSource={dataS!}
        columns={cols}
      />

      <div>
        <div style={{ paddingLeft: '0.5rem' }}>&lt;EditableTable</div>
        <div style={{ paddingLeft: '2rem' }}>
          handleUpdateData={'{handleUpdateData}'}
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          handleCreateData={'{handleCreateData}'}
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          {
            // optional, wenn undefined}
          }
          handleDeleteData={'{handleDeleteData}'}
          keine löschen buttons
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          handleTableButtonClick={'{handleArchivedClick}'}
          {
            // optional, nur nötig wen buttons in zeilen
          }
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          updateDataSource={'{setData}'}
        </div>
        <div style={{ paddingLeft: '2rem' }}>addDataRow={'{handleAddRow}'}</div>
        <div style={{ paddingLeft: '2rem' }}>dataSource={'{data!}'}</div>
        <div style={{ paddingLeft: '2rem' }}>
          columns={'{cols}'}
          {
            // nur angegebene cols werden dargestellt, in deR gleichen reihenfolge
          }

        </div>
        <div style={{ paddingLeft: '0.5rem' }}>/{'>'}</div>
      </div>
      <div>
        <div style={{ paddingLeft: '0.5rem', paddingTop: '2rem' }}>
          {'const cols = ['}
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          {"{ prop: 'date', type: 'date', colSpan: 2 },"}
        </div>
        <div style={{ paddingLeft: '2rem' }}>{"{ prop: 'eventStatus' },"}</div>
        <div style={{ paddingLeft: '2rem' }}>
          {"{ prop: 'starttime', type: 'time' },"}
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          {"{ prop: 'endtime', type: 'time' },"}
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          {"{ prop: 'archived', type: 'button', colSpan: 2 },"}
        </div>
        <div style={{ paddingLeft: '2rem' }}>
          {"{ prop: 'votingClosed', type: 'checkbox' },"}
        </div>
        <div style={{ paddingLeft: '0.5rem' }}>{'];'}</div>
      </div>
    </div>
  );
};

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import FilledRingIcon from '@mui/icons-material/FiberManualRecord';
import React, { useEffect, useState } from 'react';
import RingIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { IEvent } from '../../models/interfaces/event-interface';
import { useStyles } from './event-manage-styles';
import { useTranslation } from 'react-i18next';
import { EventDeleteDialog } from './dialogs/event-delete-dialog';
import { DialogCreateEvent } from './dialogs/event-create-dialog';
import { useGlobalContext } from '../../libs/context-lib';
import { EventDetailsFDialog } from './dialogs/event-details-full-dialog';
import { getDateDeByTimestamp } from '../../utils/iso-timestamp-converter';

interface IProps {
  events: IEvent[];
  deleteEvent: (id: string) => void;
  createEvent: (event: IEvent, asCurrent?: boolean) => void;
  setCurrentEvent: (eventId: string) => void;
  updateEvent: (event: IEvent) => void;
}

export const EventListManage = (props: IProps) => {
  const { currentOrga } = useGlobalContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<IEvent[]>();
  const [selectedEvent, setSelectedEvent] = useState(currentOrga.currentEvent);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IEvent>();

  useEffect(() => {
    props.events && setData(props.events);
    setSelectedEvent(currentOrga.currentEvent);
  }, [props.events, currentOrga.currentEvent]);

  const handleDeleteRow = (row: IEvent) => {
    setSelectedRow(row);
    setDeleteDialog(true);
  };

  const handleCreateEvent = () => {
    setCreateDialog(true);
  };

  const handleRowClick = (event: IEvent) => {
    setSelectedRow(event);
    setDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setDetailsDialog(false);
  };

  const handleCloseDeleteDialog = (value: boolean, eventId: string) => {
    value && props.deleteEvent(eventId);
    setDeleteDialog(false);
  };

  const handleSetAsCurrent = (eventId: string) => {
    props.setCurrentEvent(eventId);
  };

  const handleCloseCreateDialog = (
    event: IEvent | null,
    asCurrent?: boolean
  ) => {
    event && props.createEvent(event, asCurrent);
    setCreateDialog(false);
  };

  return (
    <>
      <Typography
        variant='h5'
        style={{ textAlign: 'center', margin: '2rem 2rem 0rem' }}
      >
        {t('label.manageEvents')}
      </Typography>
      <TableContainer className={classes.root} component={Paper}>
        {data !== undefined ? (
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: '2rem', textAlign: 'center' }}
                ></TableCell>
                <TableCell>{t('label.date')}</TableCell>
                <TableCell>{t('label.status')}</TableCell>
                <TableCell style={{ width: '3rem', textAlign: 'center' }}>
                  <IconButton onClick={handleCreateEvent}>
                    <AddBoxIcon fontSize='large' style={{ color: 'green' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell className={classes.dotCell}>
                    {selectedEvent === row.id ? (
                      <FilledRingIcon style={{ color: 'green' }} />
                    ) : (
                      <RingIcon />
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.curs}
                    onClick={() => handleRowClick(row)}
                  >
                    {getDateDeByTimestamp(row.date)}
                  </TableCell>
                  <TableCell
                    className={classes.curs}
                    onClick={() => handleRowClick(row)}
                  >
                    {t(`label.${row.eventStatus}`)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', padding: '0px' }}>
                    <IconButton
                      onClick={() => handleDeleteRow(row)}
                      style={{ padding: '6px' }}
                      disabled={row.archived}
                    >
                      <DeleteIcon
                        fontSize='large'
                        style={
                          row.archived
                            ? { color: 'grey' }
                            : { color: 'darkred' }
                        }
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <EventDeleteDialog
              open={deleteDialog}
              handleClose={handleCloseDeleteDialog}
              row={selectedRow!}
            />
            {createDialog && (
              <DialogCreateEvent
                open={createDialog}
                handleClose={handleCloseCreateDialog}
              />
            )}
            <EventDetailsFDialog
              open={detailsDialog}
              event={selectedRow!}
              handleClose={handleCloseDetailsDialog}
              handleSetAsCurrent={handleSetAsCurrent}
              updateEvent={props.updateEvent}
              deleteEvent={(eventId: string) => {
                props.deleteEvent(eventId);
                setDetailsDialog(false);
              }}
            />
          </Table>
        ) : (
          <div>...loading</div>
        )}
      </TableContainer>
    </>
  );
};

import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './orga-manage-styles';
import logo from '../../images/qrCode.png';
import { IOrganization } from '../../models/interfaces/organization-interface';

interface IProps {
  orga: IOrganization;
  setOnEdit: (onEdit: boolean) => void;
}

export const OrgaManage = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleEditSubmit = () => {
    props.setOnEdit(true);
  };

  return (
    <div>
      <div className={classes.root}>
        <Typography variant='h5'>
          {t('label.orga')}: {props.orga.name}
        </Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.colors}>
            <Typography>{t('label.theme')}:</Typography>
            <TextField
              disabled
              type='color'
              id='filled-basic'
              label={t('label.primaryColor')}
              variant='outlined'
              size='small'
              style={{ width: '6rem', margin: '0.5rem' }}
              value={props.orga.primaryColor}
            />
            <TextField
              disabled
              type='color'
              id='filled-basic'
              label={t('label.secondaryColor')}
              variant='outlined'
              size='small'
              style={{ width: '6rem', margin: '0.5rem' }}
              value={props.orga.secondaryColor}
            />
          </div>
          <div className={classes.requestKey}>
            <TextField
              disabled
              type='text'
              id='filled-basic'
              label={t('label.requestKey')}
              variant='outlined'
              size='medium'
              style={{ margin: '0.5rem', width: '100%' }}
              value={props.orga.id}
            />
          </div>
          <div className={classes.requestKey}>
            <TextField
              disabled
              type='text'
              id='filled-basic'
              label={t('label.inviteUrl')}
              variant='outlined'
              size='medium'
              style={{ margin: '0.5rem', width: '100%' }}
              value={
                'https://campapp.esentri.com/#/?inviteKey=' + props.orga.id
              }
            />
          </div>
          <div className={classes.colors}>
            <Typography>QR-Code:</Typography>
          </div>
          <img src={logo} alt='logo' className={classes.logo} />
        </div>
      </div>

      <div className={classes.root}>
        <Button variant='contained' onClick={handleEditSubmit}>
          {t('label.edit')}
        </Button>
      </div>
      <div className={classes.root}>
        <Button variant='contained'>{t('label.manageTags')}</Button>
      </div>
    </div>
  );
};

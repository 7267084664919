import * as RoomLinkService from '../../services/room-link-service';
import * as RoomService from '../../services/room-service';
import React, { useEffect, useState } from 'react';
import { EditableTable } from '../../components/shared/editable-table';
import { GraphQLResult } from '@aws-amplify/api';
import { IRoom } from '../../models/interfaces/room-interface';
import { IRoomLink } from '../../models/interfaces/room-link-interface';
import { Location } from 'history';
import {
  OnCreateRoomLinkSubscription,
  OnCreateRoomSubscription,
  OnDeleteRoomLinkSubscription,
  OnDeleteRoomSubscription,
  OnUpdateRoomLinkSubscription,
  OnUpdateRoomSubscription,
} from '../../API';
import { RoomLinkDialog } from '../../components/room/dialogs/room-link-dialog';
import { Typography } from '@mui/material';
import { useGlobalContext } from '../../libs/context-lib';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  roomCreateSubMapper,
  roomUpdateSubMapper,
} from '../../services/mapper/room-mapper';
import {
  roomLinkCreateSubMapper,
  roomLinkUpdateSubMapper,
} from '../../services/mapper/room-link-mapper';

const roomCols = [
  { prop: 'name', colSpan: 2 },
  { prop: 'seats', type: 'number' },
  { prop: 'links', type: 'button', colSpan: 2 },
];

const crossLinkCols = [{ prop: 'label' }, { prop: 'link', colSpan: 2 }];

export const RoomListContainer = () => {
  const { t } = useTranslation();
  const location = useLocation() as Location<{ eventId: string }>;
  const [eventId, setEventId] = useState('');
  const [rooms, setRooms] = useState<IRoom[]>();
  const [roomLinks, setRoomLinks] = useState<IRoomLink[]>([]);
  const [crossRoomLinks, setCrossRoomLinks] = useState<IRoomLink[]>([]);
  const { currentOrga } = useGlobalContext();
  const [roomLinkDialog, setRoomLinkDialog] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<IRoom>();

  // subscriptions for dialog for cleanup
  let createRoomLinkSubscriptionByRoom: any;
  let updateRoomLinkSubscriptionByRoom: any;
  let deleteRoomLinkSubscriptionByRoom: any;

  useEffect(() => {
    let newRooms: IRoom[] = [];
    let newCrossRoomLinks: IRoomLink[] = [];
    let newEventId: string;

    if (location.state) {
      setEventId(location.state.eventId as string);
      newEventId = location.state.eventId as string;
    } else {
      setEventId(currentOrga.currentEvent!);
      newEventId = currentOrga.currentEvent!;
    }
    const fetchRooms = async () => {
      await RoomService.listRoomsByEventId(
        location.state?.eventId || currentOrga.currentEvent!
      ).then(
        (data) => {
          newRooms = data;
          setRooms(data);
        },
        (error) => {
          console.log(error);
          alert('Get failed!');
        }
      );
    };

    const fetchCrossLinks = async () => {
      await RoomLinkService.listRoomLinksByRoomId(
        location.state?.eventId || currentOrga.currentEvent!
      ).then(
        (data) => {
          newCrossRoomLinks = data;
          setCrossRoomLinks(data);
        },
        (error) => {
          console.log(error);
          alert('Get failed!');
        }
      );
    };

    const createRoomSubscription = RoomService.subscriptionCreateRoomByEventId(
      newEventId
      // @ts-ignore
    ).subscribe({
      next: (createdRoom: {
        value: GraphQLResult<OnCreateRoomSubscription>;
      }) => {
        const newRoom = roomCreateSubMapper(createdRoom.value);

        // deep copy
        const newData: IRoom[] = [];
        newRooms.forEach((val) => {
          if (newRoom.id !== val.id) {
            newData.push(Object.assign({}, val));
          }
        });
        newData.push(newRoom);
        newRooms = newData;
        setRooms([...newData]);
      },
    });

    const updateRoomSubscription = RoomService.subscriptionUpdateRoomByEventId(
      newEventId
      // @ts-ignore
    ).subscribe({
      next: (updatedRoom: {
        value: GraphQLResult<OnUpdateRoomSubscription>;
      }) => {
        const newRoom = roomUpdateSubMapper(updatedRoom.value);

        // deep copy
        const newData: IRoom[] = [];
        newRooms.forEach((val) => {
          val.id === newRoom.id
            ? newData.push(newRoom)
            : newData.push(Object.assign({}, val));
        });
        newRooms = newData;
        setRooms([...newData]);
      },
    });

    const deleteRoomSubscription = RoomService.subscriptionDeleteRoomByEventId(
      newEventId
      // @ts-ignore
    ).subscribe({
      next: (deletedRoom: {
        value: GraphQLResult<OnDeleteRoomSubscription>;
      }) => {
        const toDeleteId = deletedRoom.value.data?.onDeleteRoom?.Id;
        // deep copy
        const newData: IRoom[] = [];
        newRooms.forEach((val) => {
          if (val.id !== toDeleteId) {
            newData.push(Object.assign({}, val));
          }
        });
        newRooms = [...newData];
        setRooms([...newData]);
      },
    });

    const createRoomLinkSubscription = RoomLinkService.subscriptionCreateRoomLinkByRefId(
      newEventId
      // @ts-ignore
    ).subscribe({
      next: (createdRoomLink: {
        value: GraphQLResult<OnCreateRoomLinkSubscription>;
      }) => {
        const newRoomLink = roomLinkCreateSubMapper(createdRoomLink.value);
        // deep copy
        const newData: IRoomLink[] = [];
        newCrossRoomLinks.forEach((val) => {
          if (newRoomLink.id !== val.id) {
            newData.push(Object.assign({}, val));
          }
        });
        newData.push(newRoomLink);
        newCrossRoomLinks = newData;
        setCrossRoomLinks([...newData]);
      },
    });

    const updateRoomLinkSubscription = RoomLinkService.subscriptionUpdateRoomLinkByRefId(
      newEventId
      // @ts-ignore
    ).subscribe({
      next: (updatedRoomLink: {
        value: GraphQLResult<OnUpdateRoomLinkSubscription>;
      }) => {
        const newRoomLink = roomLinkUpdateSubMapper(updatedRoomLink.value);

        // deep copy
        const newData: IRoomLink[] = [];
        newCrossRoomLinks.forEach((val) => {
          val.id === newRoomLink.id
            ? newData.push(newRoomLink)
            : newData.push(Object.assign({}, val));
        });
        newCrossRoomLinks = newData;
        setCrossRoomLinks([...newData]);
      },
    });

    const deleteRoomLinkSubscription = RoomLinkService.subscriptionDeleteRoomLinkByRefId(
      newEventId
      // @ts-ignore
    ).subscribe({
      next: (deletedRoomLink: {
        value: GraphQLResult<OnDeleteRoomLinkSubscription>;
      }) => {
        const toDeleteId = deletedRoomLink.value.data?.onDeleteRoomLink?.Id;

        // deep copy
        const newData: IRoomLink[] = [];
        newCrossRoomLinks.forEach((val) => {
          if (val.id !== toDeleteId) {
            newData.push(Object.assign({}, val));
          }
        });
        newCrossRoomLinks = newData;
        setCrossRoomLinks([...newData]);
      },
    });

    fetchRooms();
    fetchCrossLinks();

    return () => {
      createRoomSubscription.unsubscribe();
      updateRoomSubscription.unsubscribe();
      deleteRoomSubscription.unsubscribe();
      createRoomLinkSubscription.unsubscribe();
      updateRoomLinkSubscription.unsubscribe();
      deleteRoomLinkSubscription.unsubscribe();
    };
  }, [location.state, currentOrga]);

  const handleAddRoomRow = (): IRoom => {
    const newData = {
      id: uuidv4(),
      eventId: eventId,
      seats: 0,
      name: '',
    } as IRoom;

    setRooms([...rooms!, newData]);
    return newData;
  };

  const handleUpdateRoom = async (room: IRoom, oldRoom: IRoom) => {
    await RoomService.updateRoomFull(room).then(
      () => {
        updateDataSource(room, oldRoom);
      },
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const handleCreateRoom = async (room: IRoom, oldRoom: IRoom) => {
    await RoomService.createNewRoom(room).then(
      () => {
        updateDataSource(room, oldRoom);
      },
      (error) => {
        console.log(error);
        alert('Create failed!');
      }
    );
  };

  const updateDataSource = (newRow: IRoom, oldRow: IRoom) => {
    const newData: any = [];
    // deep copy
    rooms!.forEach((val) => newData.push(Object.assign({}, val)));
    const index = newData.findIndex((x: IRoom) => x.id === oldRow.id);
    if (index !== -1) {
      newData[index] = newRow;
      setRooms(newData);
    }
  };

  const handleDeleteRoom = async (room: IRoom) => {
    await RoomService.deleteRoomById(room.id, room.eventId).then(
      () => {
        const newData: any = [];
        // deep copy
        rooms!.forEach((val) => newData.push(Object.assign({}, val)));
        const index = newData!.findIndex((x: IRoom) => x.id === room.id);
        newData!.splice(index, index >= 0 ? 1 : 0);
        setRooms([...newData]);
      },
      (error) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleLinksClick = async (room: IRoom) => {
    let newRoomLinks: IRoomLink[] = [];
    await RoomLinkService.listRoomLinksByRoomId(room.id).then(
      (data) => {
        setSelectedRoom(room);
        setRoomLinks(data);
        newRoomLinks = data;
        setRoomLinkDialog(true);
      },
      (error) => {
        console.log(error);
        alert('Get failed!');
      }
    );

    createRoomLinkSubscriptionByRoom = RoomLinkService.subscriptionCreateRoomLinkByRefId(
      room.id
      // @ts-ignore
    ).subscribe({
      next: (createdRoomLink: {
        value: GraphQLResult<OnCreateRoomLinkSubscription>;
      }) => {
        const newRoomLink = roomLinkCreateSubMapper(createdRoomLink.value);
        // deep copy
        const newData: IRoomLink[] = [];
        newRoomLinks.forEach((val) => {
          if (newRoomLink.id !== val.id) {
            newData.push(Object.assign({}, val));
          }
        });
        newData.push(newRoomLink);
        newRoomLinks = newData;
        setRoomLinks([...newData]);
      },
    });

    updateRoomLinkSubscriptionByRoom = RoomLinkService.subscriptionUpdateRoomLinkByRefId(
      room.id
      // @ts-ignore
    ).subscribe({
      next: (updatedRoomLink: {
        value: GraphQLResult<OnUpdateRoomLinkSubscription>;
      }) => {
        const newRoomLink = roomLinkUpdateSubMapper(updatedRoomLink.value);

        // deep copy
        const newData: IRoomLink[] = [];
        newRoomLinks.forEach((val) => {
          val.id === newRoomLink.id
            ? newData.push(newRoomLink)
            : newData.push(Object.assign({}, val));
        });
        newRoomLinks = newData;
        setRoomLinks([...newData]);
      },
    });

    deleteRoomLinkSubscriptionByRoom = RoomLinkService.subscriptionDeleteRoomLinkByRefId(
      room.id
      // @ts-ignore
    ).subscribe({
      next: (deletedRoomLink: {
        value: GraphQLResult<OnDeleteRoomLinkSubscription>;
      }) => {
        const toDeleteId = deletedRoomLink.value.data?.onDeleteRoomLink?.Id;

        // deep copy
        const newData: IRoomLink[] = [];
        newRoomLinks.forEach((val) => {
          if (val.id !== toDeleteId) {
            newData.push(Object.assign({}, val));
          }
        });
        newRoomLinks = newData;
        setRoomLinks([...newData]);
      },
    });
  };

  const handleDialogClose = () => {
    setRoomLinkDialog(false);

    try {
      // cleanup subscriptions
      createRoomLinkSubscriptionByRoom?.unsubscribe();
      updateRoomLinkSubscriptionByRoom?.unsubscribe();
      deleteRoomLinkSubscriptionByRoom?.unsubscribe();
    } catch (error) {
      console.log(error, 'Subscription cleanup failed!');
    }
  };

  const handleCreateLink = async (link: IRoomLink) => {
    await RoomLinkService.createNewRoomLink(link).then(
      () => {},
      (error) => {
        console.log(error);
        alert('Create failed!');
      }
    );
  };

  const handleDeleteRoomLink = async (linkId: string, referenceId: string) => {
    await RoomLinkService.deleteRoomLinkById(linkId, referenceId).then(
      () => {
        const newData: any = [];
        // deep copy
        roomLinks!.forEach(
          (val) => val.id !== linkId && newData.push(Object.assign({}, val))
        );
        setRoomLinks([...newData]);
      },
      (error) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleDeleteCrossRoomLink = async (
    linkId: string,
    referenceId: string
  ) => {
    await RoomLinkService.deleteRoomLinkById(linkId, referenceId).then(
      () => {
        const newData: any = [];
        // deep copy
        crossRoomLinks!.forEach((val) => newData.push(Object.assign({}, val)));
        const index = newData!.findIndex((x: IRoomLink) => x.id === linkId);
        newData!.splice(index, index >= 0 ? 1 : 0);
        setCrossRoomLinks([...newData]);
      },
      (error) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleUpdateRoomLink = async (roomLink: IRoomLink) => {
    await RoomLinkService.updateRoomLinkFull(roomLink).then(
      () => {},
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const handleAddLinkRow = (): IRoomLink => {
    const newData = {
      id: uuidv4(),
      referenceId: eventId,
      label: '',
      link: '',
      acrossRoom: true,
      activityDetail: false,
    } as IRoomLink;

    setCrossRoomLinks([...crossRoomLinks!, newData]);
    return newData;
  };

  const handleUpdateLink = (link: IRoomLink, oldLink: IRoomLink) => {
    handleUpdateRoomLink(link);
    updateCrossLinkSource(link, oldLink);
  };

  const handleCreateCrossLink = (link: IRoomLink, oldLink: IRoomLink) => {
    handleCreateLink(link);
    updateCrossLinkSource(link, oldLink);
  };

  const handleDeleteLink = (link: IRoomLink) => {
    handleDeleteCrossRoomLink(link.id, link.referenceId);
  };

  const updateCrossLinkSource = (newRow: IRoomLink, oldRow: IRoomLink) => {
    const newData: any = [];
    // deep copy
    crossRoomLinks!.forEach((val) => newData.push(Object.assign({}, val)));
    const index = newData.findIndex((x: IRoomLink) => x.id === oldRow.id);
    if (index !== -1) {
      newData[index] = newRow;
      setCrossRoomLinks(newData);
    }
  };

  return (
    <>
      {rooms !== undefined ? (
        <div style={{ maxWidth: '60rem', margin: 'auto', paddingBottom: '5rem' }}>
          <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
            <Typography variant='h6'>{t('label.rooms')}</Typography>
          </div>
          <EditableTable
            dataSource={rooms!}
            columns={roomCols}
            addDataRow={handleAddRoomRow}
            updateDataSource={setRooms}
            handleUpdateData={handleUpdateRoom}
            handleCreateData={handleCreateRoom}
            handleDeleteData={handleDeleteRoom}
            handleTableButtonClick={handleLinksClick}
          />
          <RoomLinkDialog
            open={roomLinkDialog}
            handleClose={handleDialogClose}
            roomLinks={roomLinks!}
            room={selectedRoom!}
            createRoomLink={handleCreateLink}
            deleteRoomLink={handleDeleteRoomLink}
            updateRoomLink={handleUpdateRoomLink}
          />
          <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
            <Typography variant='subtitle1'>
              {t('label.crossRoomLinks')}
            </Typography>
          </div>
          <EditableTable
            dataSource={crossRoomLinks!}
            columns={crossLinkCols}
            addDataRow={handleAddLinkRow}
            updateDataSource={setCrossRoomLinks}
            handleUpdateData={handleUpdateLink}
            handleCreateData={handleCreateCrossLink}
            handleDeleteData={handleDeleteLink}
          />
        </div>
      ) : (
        <div>...loading</div>
      )}
    </>
  );
};

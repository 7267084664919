import {
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './orga-manage-styles';
import { v4 as uuidv4 } from 'uuid';
import { IOrganization } from '../../models/interfaces/organization-interface';

interface IProps {
  orga?: IOrganization;
  handleSubmit: (newOrga: IOrganization) => void;
  setOnEdit: (onEdit: boolean) => void;
  submitting: boolean;
}

export const OrgaForm = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [label, setLabel] = useState(props.orga?.name || '');
  const [primaryColor, setPrimaryColor] = useState(
    props.orga?.primaryColor || '#3F4444'
  );
  const [secondaryColor, setSecondaryColor] = useState(
    props.orga?.secondaryColor || '#64A70B'
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.orga !== undefined) {
      const newOrga = {
        id: props.orga.id,
        name: label,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        currentEvent: props.orga.currentEvent,
      } as IOrganization;
      props.handleSubmit(newOrga);
    } else {
      const newOrga = {
        id: uuidv4(),
        name: label,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        currentEvent: '',
      } as IOrganization;
      props.handleSubmit(newOrga);
    }
  };

  const handleCancel = () => {
    props.setOnEdit(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <TextField
          label={t('label.orga')}
          required
          id='filled-basic'
          variant='outlined'
          value={label}
          onChange={(event) => setLabel(event.target.value)}
        />
      </div>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.colors}>
            <TextField
              type='color'
              id='filled-basic'
              label={t('label.primaryColor')}
              variant='outlined'
              size='small'
              style={{ width: '6rem', margin: '0.5rem' }}
              value={primaryColor}
              onChange={(event) => setPrimaryColor(event.target.value)}
            />
            <TextField
              type='color'
              id='filled-basic'
              label={t('label.secondaryColor')}
              variant='outlined'
              size='small'
              style={{ width: '6rem', margin: '0.5rem' }}
              value={secondaryColor}
              onChange={(event) => setSecondaryColor(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={classes.root}>
        <Button
          style={{ color: 'red' }}
          variant='contained'
          className={classes.button}
          onClick={handleCancel}
        >
          {t('label.cancel')}
        </Button>
        {props.submitting ? (
          <CircularProgress />
        ) : (
          <Button variant='contained' className={classes.button} type='submit'>
            {props.orga === undefined ? t('label.create') : t('label.save')}
          </Button>
        )}
      </div>
    </form>
  );
};

import React, { useEffect, useState } from 'react';
import * as VoteService from '../../services/vote-service';
import { ButtonThumbUp } from '../../components/shared/button-thumb-up';
import { useGlobalContext } from '../../libs/context-lib';
import { CircularProgress } from '@mui/material';
import { IVote } from '../../models/interfaces/vote-interface';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  sessionId: string;
  votes: IVote[];
}

export const VoteContainer = (props: IProps) => {
  const [votedId, setVotedId] = useState('');
  const { userInfo, currentEvent } = useGlobalContext();
  const [votes, setVotes] = useState<IVote[]>([]);

  useEffect(() => {
    setVotedId('');
    props.votes?.forEach((vote) => {
      if (vote.userId === userInfo.sub) {
        setVotedId(vote.id);
      }
    });
    const newData: IVote[] = [];
    // deep copy
    props.votes?.forEach((val) => newData.push(Object.assign({}, val)));
    setVotes(newData);
  }, [props.votes, userInfo.sub]);

  const handleVoteClick = () => {
    if (votedId) {
      setVotedId('');
      VoteService.deleteVoteById(votedId, props.sessionId).then(
        () => {},
        (error: any) => {
          console.log(error);
          alert('Vote change failed! Please refresh the page!');
        }
      );
    } else {
      const newVote: IVote = {
        id: uuidv4(),
        sessionId: props.sessionId,
        userId: userInfo.sub,
        eventId: currentEvent.id,
      };
      setVotedId(newVote.id);
      VoteService.createNewVote(newVote).then(
        () => {},
        (error: any) => {
          console.log(error);
          alert('Vote change failed! Please refresh the page!');
        }
      );
    }
  };

  return currentEvent.id !== '' ? (
    <ButtonThumbUp
      handleClick={handleVoteClick}
      count={votes.length}
      disabled={currentEvent.votingClosed}
      voted={votedId !== '' ? true : false}
    />
  ) : (
    <CircularProgress />
  );
};

import { Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './chat-styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { useGlobalContext } from '../../libs/context-lib';
import { IMessage } from '../../models/interfaces/message-interface';
import { getChatTimestamp } from '../../utils/iso-timestamp-converter';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from '../../models/interfaces/user-interface';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface IProps {
  messages: IMessage[];
  orgaUsers: IUser[];
  createMessage: (message: IMessage) => void;
}
export const Chat = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();
  const { userInfo, currentOrga } = useGlobalContext();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [orgaUsers, setOrgaUsers] = useState<IUser[]>([]);
  const [messageValue, setMessageValue] = useState('');
  const divRef = useRef<any>(null);

  useEffect(() => {
    setOrgaUsers(props.orgaUsers);
  }, [props.orgaUsers]);

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages]);

  useEffect(() => {
    divRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, [messages]);

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageValue(event.target.value);
  };

  const handleCreateMessage = () => {
    if (messageValue) {
      const newMessage: IMessage = {
        id: uuidv4(),
        userId: userInfo.sub,
        timestamp: new Date().toISOString(),
        content: messageValue,
        referenceId: currentOrga.id,
      };

      props.createMessage(newMessage);
      setMessageValue('');
    }
  };

  // create message on enter press
  const handleKeyPress = (target: React.KeyboardEvent<HTMLDivElement>) => {
    target.stopPropagation();
    if (target.key === 'Enter') {
      target.preventDefault();
      handleCreateMessage();
    }
  };

  const getWriter = (writerId: string): string => {
    let returnValue = '';

    orgaUsers.forEach((user) => {
      if (user.sub === writerId) {
        returnValue = `${user.given_name} ${user.family_name}`;
      }
    });

    // add ananymous data if User ist not anymore in Orga
    if (!returnValue) {
      returnValue = `${t('label.removedUser')}`;
    }
    return returnValue;
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center'>
        <Grid item sm={2} md={2} />
        <Grid item xs={12} sm={8} md={8}>
          <Paper variant='outlined' className={classes.chatPaperRoot}>
            <Grid container spacing={1} justifyContent='center'>
              {messages.map((message) => (
                <React.Fragment key={message.id + '-fragment'}>
                  {userInfo.sub === message.userId ? (
                    <>
                      <Grid item xs={1} key={message.id + 'space'}></Grid>
                      <Grid item xs={11} key={message.id}>
                        <Paper
                          variant='elevation'
                          elevation={3}
                          className={classes.myMessagePaper}
                        >
                          <Grid container key={message.id + '-container'}>
                            <Grid
                              key={message.id + '-user'}
                              item
                              xs={6}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {getWriter(message.userId)}
                            </Grid>
                            <Grid
                              key={message.id + '-stamp'}
                              item
                              xs={6}
                              style={{ textAlign: 'right' }}
                            >
                              {getChatTimestamp(message.timestamp)}
                            </Grid>
                            <Grid
                              key={message.id + '-text'}
                              item
                              xs={12}
                              style={{ paddingTop: '0.3rem' }}
                            >
                              <Typography
                                variant='body2'
                                gutterBottom
                                className={classes.messageContent}
                              >
                                {message.content}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={11} key={message.id}>
                        <Paper
                          variant='elevation'
                          elevation={3}
                          className={classes.messagePaper}
                        >
                          <Grid container key={message.id + '-container'}>
                            <Grid
                              key={message.id + '-user'}
                              item
                              xs={6}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {getWriter(message.userId)}
                            </Grid>
                            <Grid
                              key={message.id + '-stamp'}
                              item
                              xs={6}
                              style={{ textAlign: 'right' }}
                            >
                              {getChatTimestamp(message.timestamp)}
                            </Grid>
                            <Grid
                              key={message.id + '-text'}
                              item
                              xs={10}
                              style={{ paddingTop: '0.3rem' }}
                            >
                              <Typography
                                variant='body2'
                                gutterBottom
                                className={classes.messageContent}
                              >
                                {message.content}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} key={message.id + 'space'}></Grid>
                    </>
                  )}
                </React.Fragment>
              ))}
              <Grid
                item
                xs={12}
                style={{ height: '0.5rem' }}
                ref={divRef}
              ></Grid>
            </Grid>
          </Paper>
          <Paper component='form' className={classes.rootInput}>
            <InputBase
              className={classes.input}
              placeholder='Click here to write'
              inputProps={{ 'aria-label': 'send-message' }}
              value={messageValue}
              onChange={handleChangeMessage}
              onKeyPress={handleKeyPress}
            />
            <IconButton
              className={classes.iconButton}
              aria-label='send'
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                handleCreateMessage();
              }}
            >
              <SendIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item sm={2} md={2} />
      </Grid>
    </div>
  );
};

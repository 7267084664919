import { GraphQLResult } from '@aws-amplify/api';
import { OnCreateRoomSubscription, OnUpdateRoomSubscription } from '../../API';
import { IRoom } from '../../models/interfaces/room-interface';

export const roomCreateSubMapper = (
  input: GraphQLResult<OnCreateRoomSubscription>
): IRoom => {
  const newRoom = {
    id: input.data?.onCreateRoom?.Id!,
    eventId: input.data?.onCreateRoom?.EventId!,
    name: input.data?.onCreateRoom?.Name!,
    seats: input.data?.onCreateRoom?.Seats!,
  };
  return newRoom;
};

export const roomUpdateSubMapper = (
  input: GraphQLResult<OnUpdateRoomSubscription>
): IRoom => {
  const newRoom = {
    id: input.data?.onUpdateRoom?.Id!,
    eventId: input.data?.onUpdateRoom?.EventId!,
    name: input.data?.onUpdateRoom?.Name!,
    seats: input.data?.onUpdateRoom?.Seats!,
  };
  return newRoom;
};

export const roomToEntityMapper = (room: IRoom) => {
  const mappedRoom = {
    EventId: room.eventId,
    Id: room.id,
    Name: room.name,
    Seats: room.seats,
  };

  return mappedRoom;
};

export const roomListToEntityListMapper = (roomList: IRoom[]) => {
  const mappedRooms: any = [];

  roomList.forEach((room) => {
    mappedRooms.push({
      EventId: room.eventId,
      Id: room.id,
      Name: room.name,
      Seats: room.seats,
    });
  });

  return mappedRooms;
};

import React, { useState } from 'react';
import logo from '../../images/CopeApp.png';
import { Auth } from 'aws-amplify';
import { ChangePassword } from '../../components/profile/change-password';
import { Login } from '../../components/login/login';
import { useGlobalContext } from '../../libs/context-lib';
import { userMapper } from '../../services/mapper/user-mapper';
import { UnauthHeader } from '../../components/header/unauth-header';

export const LoginContainer = () => {
  const { userHasAuthenticated, setUserInfo } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [forcePWchange, setForcePWchange] = useState(false);
  const [userMail, setUserMail] = useState('');


  const handleSubmitLogin = async (email: string, password: string) => {
    setIsLoading(true);

    try {
      await Auth.signIn(email, password).then((userResponse) => {
        if (userResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserMail(email);
          setIsLoading(false);
          setForcePWchange(true);
        } else {
          setUserInfo(userMapper(userResponse));
          userHasAuthenticated(true);
        }
      });
    } catch (e: any) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  const handleSubmitChangePW = async (
    oldPassword: string,
    password: string
  ) => {
    await Auth.signIn(userMail, oldPassword)
      .then((user) => {
        if (forcePWchange) {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            password // the new password
          )
            .then((changedUser) => {
              setUserInfo(userMapper(changedUser));
              userHasAuthenticated(true);
              setForcePWchange(false);
            })
            .catch((e) => {
              alert(e.message);
              setIsLoading(false);
            });
        } else {
          alert('Something went wrong, Login again');
          setForcePWchange(false);
        }
      })
      .catch((e) => {
        alert(e.message);
        setIsLoading(false);
      });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {!forcePWchange ? (
        <>
          <img src={logo} alt='logo' />
          <Login handleSubmit={handleSubmitLogin} isLoading={isLoading} />
        </>
      ) : (
        <>
          <UnauthHeader setForcePWchange={setForcePWchange} />
          <div style={{ marginTop: '3rem' }}>
            <ChangePassword
              handleSubmit={handleSubmitChangePW}
              isLoading={isLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

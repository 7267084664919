import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import React from 'react';
import { useStyles } from './bottom-nav-styles';
import EventIcon from '@mui/icons-material/Event';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ForumIcon from '@mui/icons-material/Forum';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { IOrganization } from '../../models/interfaces/organization-interface';
import { useTheme } from '@mui/material/styles';

interface IProps {
  menuIndex: number;
  setMenuIndex: (value: number) => void;
  currentOrga: IOrganization;
}

export const BottomNav = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <BottomNavigation
      value={props.menuIndex}
      onChange={(event, newValue) => {
        event.preventDefault();
        location.pathname !== '/' && navigate('/');
        props.setMenuIndex(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        className={classes.icons}
        label={t('label.schedule')}
        icon={<EventIcon />}
      />
      <BottomNavigationAction
        className={classes.icons}
        label={t('label.sessions')}
        icon={<PlaylistAddIcon />}
      />
      <BottomNavigationAction
        className={classes.icons}
        label={t('label.chat')}
        icon={<ForumIcon />}
      />
    </BottomNavigation>
  );
};

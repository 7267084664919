import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Profile } from '../../components/profile/profile';
import { useGlobalContext } from '../../libs/context-lib';
import { OrgaForceChangeContainer } from '../orga-container/orga-force-change-container';
import * as UserService from '../../services/user-service';

export const ProfileNoOrgaContainer = () => {
  const { userInfo } = useGlobalContext();
  const [submittingNewPw, setSubmittingNewPw] = useState(false);
  const [pwRefreshFinished, setPwRefreshFinished] = useState(false);

  const handleSubmitChangePW = async (
    oldPassword: string,
    newPassword: string
  ) => {
    await Auth.signIn(userInfo.email, oldPassword)
      .then((user) => {
        Auth.changePassword(user, oldPassword, newPassword)
          .then(() => {
            setPwRefreshFinished(true);
          })
          .catch((e) => {
            alert(e.message);
            setSubmittingNewPw(false);
          });
      })
      .catch((e) => {
        alert(e.message);
        setSubmittingNewPw(false);
      });
  };

  const handleDeleteProfile = () => {
    UserService.deleteOwnProfile(userInfo.sub).then(() => {
      localStorage.clear();
      window.location.reload();
    });
  };

  return (
    <>
      <Profile
        handleDeleteProfile={handleDeleteProfile}
        submittingNewPw={submittingNewPw}
        setSubmittingNewPw={setSubmittingNewPw}
        handleChangePw={handleSubmitChangePW}
        pwRefreshFinished={pwRefreshFinished}
        userTagsArray={undefined}
      />
      <OrgaForceChangeContainer />
    </>
  );
};

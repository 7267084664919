import { createSchedule } from '../graphql/mutations';
import { CreateScheduleMutation } from '../API';
import { ISession } from '../models/interfaces/session-interface';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';

export const createScheduleAndGetSessions = async (
  roomlist: any,
  sessionlist: any,
  blockerlist: any,
  votelist: any,
  organization: any,
  event: any,
  breakDuration: any,
  startISO: string,
  endISO: string
): Promise<ISession[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(createSchedule, {
      input: {
        roomList: roomlist,
        sessionGeneralList: sessionlist,
        blockerList: blockerlist,
        voteList: votelist,
        organization: organization,
        event: event,
        breakDuration: breakDuration,
        start: startISO,
        end: endISO,
      },
      limit: 100,
    })
  )) as GraphQLResult<CreateScheduleMutation>;

  const newSessions: ISession[] = [];
  let parsedItems: any[] = [];

  if (
    retrievedList.data?.createSchedule !== undefined &&
    retrievedList.data?.createSchedule !== null
  ) {
    parsedItems = JSON.parse(retrievedList.data?.createSchedule);

    parsedItems.forEach((session) => {
      newSessions.push({
        id: session?.Id!,
        eventId: session?.EventId!,
        creator: session?.Creator!,
        description: session?.Description!,
        title: session?.Title!,
        type: session?.Type!,
        starttime: session?.Starttime!,
        endtime: session?.Endtime!,
        roomId: session?.RoomId!,
        speaker: session?.Speaker! as string[],
        tags: session?.Tags! as string[],
        duration: session?.Duration!,
        extern: session?.Extern!,
        mandatory: session?.Mandatory!,
        canceled: session?.Canceled!,
      });
    });
  }

  return newSessions;
};

import * as OrgaService from '../../services/organisation-service';
import * as UserService from '../../services/user-service';
import HelpIcon from '@mui/icons-material/Help';
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { DialogHelpFirstLogin } from '../../components/help-overlays/first-login-dialog';
import { DialogMembershipRequest } from '../../components/organization/dialogs/membership-request-dialog';
import { IOrganization } from '../../models/interfaces/organization-interface';
import { MembershipRequests } from '../../components/organization/membership-requests';
import { OrgaSelectList } from '../../components/organization/orga-select-list';
import { useGlobalContext } from '../../libs/context-lib';
import { useTranslation } from 'react-i18next';
import { userMapper } from '../../services/mapper/user-mapper';

export const OrgaForceChangeContainer = () => {
  const { currentOrga, setCurrentOrga, userInfo } = useGlobalContext();
  const [orgas, setOrgas] = useState<IOrganization[]>();
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [helpInfo, setHelpInfo] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const refreshUserGroups = async () => {
      try {
        await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
          (userInfo) => {
            const refUser = userMapper(userInfo);
            setUserGroups(refUser.groups);
          }
        );
      } catch (e) {
        console.log('fetch userinfo failed', e);
      }
      setTimeout(function () {
        // refresh groups only if tab is active
        if (document.visibilityState === 'visible') {
          refreshUserGroups();
        }
      }, 3000);
    };

    refreshUserGroups();

    if (
      localStorage.getItem('helperChangeOrga') !== null &&
      localStorage.getItem('helperChangeOrga') === 'false'
    ) {
      setHelpInfo(false);
    }
  }, []);

  useEffect(() => {
    const fetchOrgas = async () => {
      if (userGroups) {
        const fetchedOrgas: IOrganization[] = [];
        for (let i = 0; i < userGroups.length; i++) {
          if (userGroups[i].length > 35) {
            await OrgaService.getOrgaByGroup(userGroups[i]).then(
              (data) => {
                if (data) {
                  fetchedOrgas.push(data);
                }
              },
              (e) => console.log(e, 'FetchOrga failed')
            );
          }
        }
        const newData: IOrganization[] = [];
        // deep copy
        fetchedOrgas.forEach((val) => newData.push(Object.assign({}, val)));
        setOrgas(newData);
      }
    };

    fetchOrgas();
    setLoading(false);
  }, [userGroups]);

  const handleMembershipRequest = (orgaKey: string) => {
    if (orgaKey) {
      UserService.userRequestToGroup(orgaKey, userInfo.sub).then(() => {
        userGroups
          ? setUserGroups([...userGroups, `${orgaKey}_request`])
          : setUserGroups([`${orgaKey}_request`]);
      });
    }
  };

  const handleCancelRequest = (orgaId: string) => {
    UserService.removeOwnGroup(userInfo.sub, `${orgaId}_request`).then(() => {
      const newGroups: string[] = [];
      userGroups.forEach((val) => {
        if (val !== `${orgaId}_request`) {
          newGroups.push(val);
        }
      });
      setUserGroups([...newGroups]);
    });
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <IconButton
          onClick={() => {
            setHelpInfo(true);
          }}
        >
          <HelpIcon style={{ color: '#ffb74d' }} />
        </IconButton>
      </div>
      {!loading ? (
        <div>
          <Typography
            variant='h5'
            style={{ textAlign: 'center', margin: '0rem 2rem 2rem' }}
          >
            {t('text.forceOrgaSelect')}
          </Typography>

          {orgas !== undefined ? (
            <OrgaSelectList
              orgas={orgas}
              currentOrga={currentOrga}
              setCurrentOrga={setCurrentOrga}
            />
          ) : (
            <Typography
              variant='h6'
              style={{ textAlign: 'center', margin: '2rem' }}
            >
              {t('text.noOrgasToSelect')}
            </Typography>
          )}
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '5rem 0rem 1rem',
            }}
          >
            <DialogMembershipRequest handleClose={handleMembershipRequest} />
          </div>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '1rem 0rem 1rem',
            }}
          >
            <MembershipRequests
              userGroups={userGroups}
              handleCancelRequest={handleCancelRequest}
            />
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
      <DialogHelpFirstLogin
        open={helpInfo}
        handleClose={() => {
          setHelpInfo(false);
          localStorage.setItem('helperChangeOrga', 'false');
        }}
      />
    </>
  );
};

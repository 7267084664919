import { TableCell, TableRow } from '@mui/material';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import { styled } from '@mui/material/styles';

// TODO styles
export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2.5rem',
    },
  })
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}))

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { IEvent } from '../../../models/interfaces/event-interface';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { useStyles } from './event-create-styles';
import { useGlobalContext } from '../../../libs/context-lib';
import Transition from '../../../utils/transition';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

interface IProps {
  open: boolean;
  handleClose: (event: IEvent | null, asCurrent?: boolean) => void;
}


export const DialogCreateEvent = (props: IProps) => {
  const { currentOrga } = useGlobalContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const [locale, setLocale] = useState('de');
  const [asCurrent, setAsCurrent] = useState(false);

  const formatDate = (date: DateTime): string => {
    // date is formatted as ISO string without the timezone suffix and interpreted as UTC
    return date.toISO({ includeOffset: false }) + 'Z';
  }

  const [event, setEvent] = useState<IEvent>({
    id: uuidv4(),
    eventStatus: 'created',
    date: formatDate(DateTime.now()),
    organizationId: currentOrga.id,
    starttime: '10:00',
    endtime: '17:00',
    votingClosed: true,
    submitClosed: true,
    archived: false,
  });

  useEffect(() => {
    setLocale(navigator.language.substring(0,2));
  }, []);
  

  const handleDateChange = (date: DateTime | null) => {
    date && setEvent({ ...event, date: formatDate(date) });
  };

  const handleSwitchChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    const name = input.target.name;
    setEvent({ ...event, [name]: !input.target.checked });
  };

  const handleCancel = () => {
    props.handleClose(null);
  };

  const handleCreate = () => {
    props.handleClose(event, asCurrent);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      className={classes.root}
    >
      <DialogTitle id='alert-dialog-slide-title'>
        {t('header.createEvent')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
          <MobileDatePicker
            value={event.date}
            renderInput={(params) => <TextField {...params} />}
            onChange={handleDateChange}
            label={t('label.date')}
          />
        </LocalizationProvider>
        
        <div>
          <span className={classes.span}>
            <TextField
              id='starttime-tf'
              label={t('label.starttime')}
              type='time'
              value={event.starttime}
              onChange={(change) =>
                setEvent({ ...event, starttime: change.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </span>
          <TextField
            id='endtime-tf'
            label={t('label.endtime')}
            type='time'
            value={event.endtime}
            onChange={(change) =>
              setEvent({ ...event, endtime: change.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={asCurrent}
                onChange={() => setAsCurrent(!asCurrent)}
                name='current'
                style={{ color: 'green' }}
              />
            }
            label={t('label.setAsCurrentEvent')}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!event.submitClosed}
                onChange={handleSwitchChange}
                name='submitClosed'
                color='primary'
              />
            }
            label={t('text.sessioncreationEnabling')}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!event.votingClosed}
                onChange={handleSwitchChange}
                name='votingClosed'
                color='primary'
              />
            }
            label={t('text.votingEnabling')}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          style={{ color: 'red' }}
          onClick={handleCancel}
        >
          {t('label.cancel')}
        </Button>
        <Button variant='outlined' onClick={handleCreate}>
          {t('label.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

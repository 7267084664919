import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      textAlign: 'center',
      paddingTop: '2%',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
      textAlign: 'center',
      paddingRight: '3rem',
    },
    content: {
      display: 'inline-grid',
      textAlign: 'left',
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    button: {
      textAlign: 'center',
    },
  })
}
);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them)


i18n
.use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: navigator.language,
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'en-EN',
    debug: false,
    defaultNS: 'translation',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      // useSuspense: false,
      // wait: false
    }
  });

  export default i18n;

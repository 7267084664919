import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
  userGroups: string[];
  handleCancelRequest: (orgaId: string) => void;
}

export const MembershipRequests = (props: IProps) => {
  const [requests, setRequests] = useState<string[]>([]);

  useEffect(() => {
    const reqGroups: string[] = [];
    if (props.userGroups !== undefined) {
      props.userGroups.forEach((group) => {
        if (group.substring(37, 44) === 'request') {
          const newString = group.substring(0, 36);
          reqGroups.push(newString);
        }
      });
    }

    setRequests(reqGroups);
  }, [props.userGroups]);

  return (
    <div style={{ width: '96%', textAlign: 'center', margin: '2rem 2% 2rem' }}>
      <div>Meine offene Anfragen ({requests.length})</div>
      <TableContainer
        component={Paper}
        style={{ maxWidth: '25rem', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Table aria-label='simple table'>
          <TableBody>
            {requests.map((row) => (
              <TableRow key={row}>
                <TableCell component='th' scope='row' align='center'>
                  {row}
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  <IconButton onClick={() => props.handleCancelRequest(row)}>
                    <CancelIcon style={{ color: 'red' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      textAlign: 'center',
      width: '98%',
      marginBottom: '4rem',
    },
    chatPaperRoot: {
      margin: '0% 2% 0% 4%',
      backgroundColor: '#D9D9D9',
      height: '62vh',
      '@media (min-height:710px)': {
        height: '67vh',
      },
      '@media (min-height:820px)': {
        height: '69vh',
      },
      '@media (min-height:890px)': {
        height: '73vh',
      },
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingBottom: '1rem',
    },
    messagePaper: {
      maxWidth: '96%',
      padding: '0.2rem',
      width: 'fit-content',
      margin: '0.3rem 0.5rem 0rem',
      textAlign: 'left',
    },
    myMessagePaper: {
      maxWidth: '96%',
      padding: '0.2rem',
      width: 'fit-content',
      textAlign: 'left',
      margin: '0.3rem 0.3rem 0rem auto',
      backgroundColor: '#e9ffdf',
    },
    messageContent: {
      overflowWrap: 'break-word',
      hiteSpace: 'pre-line',
    },
    rootInput: {
      margin: '0% 2% 0% 4%',
      display: 'flex',
      alignItems: 'center',
      padding: '2px 4px',
      backgroundColor: '#BEBEBE',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
}
);

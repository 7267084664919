import React, { useEffect, useState } from 'react';
import { DialogEventBlocker } from '../../components/blocker/dialogs/event-blocker-dialog';
import { EditScheduleDialog } from '../../components/calendar/dialogs/dialog-edit-schedule';
import { CurrentEvent } from '../../components/event/current-event';
import { useGlobalContext } from '../../libs/context-lib';
import { IBlocker } from '../../models/interfaces/blocker-interface';
import { IEvent } from '../../models/interfaces/event-interface';
import { IOrganization } from '../../models/interfaces/organization-interface';
import * as EventService from '../../services/event-service';
import * as OrgaService from '../../services/organisation-service';
import * as BlockerService from '../../services/blocker-service';

export const CurrentEventContainer = () => {
  const {
    currentOrga,
    setCurrentOrga,
    currentEvent,
    users,
    admins
  } = useGlobalContext();
  const [event, setEvent] = useState<IEvent>();
  const [events, setEvents] = useState<IEvent[]>();
  const [blockers, setBlockers] = useState<IBlocker[]>([]);
  const orgaUsers = [...users, ...admins];
  const [blockerDialog, setBlockerDialog] = useState(false);
  const [editScheduleDialog, setEditScheduleDialog] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      await EventService.listEventsByOrgaId(currentOrga.id).then(
        (data) => {
          setEvents(data);
        },
        (error) => {
          console.log(error);
          alert('Get failed!');
        }
      );
    };

    fetchEvents();
  }, [currentOrga]);


  useEffect(() => {
    const getBlockers = async () => {
      await BlockerService.listBlockerByEventIdUserId(
        currentOrga.currentEvent!,
        ''
      ).then(
        (data) => {
          setBlockers(data);
        },
        (error: any) => {
          console.log(error);
          alert('Get failed!');
        }
      );
    };

    getBlockers();
  }, [currentOrga.currentEvent]);

  useEffect(() => {
    setEvent(currentEvent);
  }, [currentEvent]);

  const handleUpdateEvent = async (newEvent: IEvent) => {
    await EventService.updateEventFull(newEvent).then(
      () => {},
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const updateBlocker = async (newRow: IBlocker) => {
    BlockerService.updateBlockerFull(newRow).then(
      () => {
        const index = blockers.findIndex((x: IBlocker) => x.id === newRow.id);
        const newList = [...blockers];
        newList[index] = newRow;
        setBlockers(newList);
      },
      (error: any) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const createBlocker = async (newRow: IBlocker) => {
    BlockerService.createNewBlocker(newRow).then(
      () => {
        setBlockers([...blockers, newRow]);
      },
      (error: any) => {
        console.log(error);
        alert('Create Blocker failed!');
      }
    );
  };

  const deleteBlocker = async (row: IBlocker) => {
    BlockerService.deleteBlockerById(row.id, row.eventId).then(
      () => {
        const newData: any = [];
        // deep copy
        blockers.forEach((val) => newData.push(Object.assign({}, val)));
        const index = newData!.findIndex((x: IBlocker) => x.id === row.id);
        newData!.splice(index, index >= 0 ? 1 : 0);
        setBlockers([...newData]);
      },
      (error: any) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleDeleteEvent = async (toDelete: IEvent) => {
    handleSetCurrEvent('');
    await EventService.deleteEventById(
      toDelete.id,
      toDelete.organizationId
    ).then(
      () => {},
      (error) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleSetCurrEvent = async (eventId: string) => {
    const newOrga = {
      id: currentOrga.id,
      name: currentOrga.name,
      primaryColor: currentOrga.primaryColor,
      secondaryColor: currentOrga.secondaryColor,
      currentEvent: eventId,
    } as IOrganization;
    await OrgaService.updateOrgaFull(newOrga).then(
      () => {
        setCurrentOrga(newOrga);
      },
      (error) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  return event !== undefined ? (
    <>
      <CurrentEvent
        event={event!}
        events={events!}
        updateEvent={handleUpdateEvent}
        deleteEvent={handleDeleteEvent}
        setCurrEvent={handleSetCurrEvent}
        openBlockerDialog={() => setBlockerDialog(true)}
        openEditScheduleDialog={() => setEditScheduleDialog(true)}
      />
      {blockerDialog && (
        <DialogEventBlocker
          blockers={blockers}
          handleClose={() => setBlockerDialog(false)}
          handleUpdateData={updateBlocker}
          handleCreateData={createBlocker}
          handleDeleteData={deleteBlocker}
        />
      )}
      {editScheduleDialog && (
        <EditScheduleDialog
          orgaUsers={orgaUsers}
          handleClose={() => setEditScheduleDialog(false)}
          blockers={blockers}
        />
      )}
    </>
  ) : (
    <div>...loading</div>
  );
};

let runtimeURL;
let domainURL;

if (process.env.NODE_ENV === 'development') {
    runtimeURL = 'http://localhost:3000';
    domainURL = 'eventapp-dev.auth.eu-central-1.amazoncognito.com';
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
    runtimeURL = 'https://d1bi5vrvwmriv2.cloudfront.net';
    domainURL = 'eventapp-dev.auth.eu-central-1.amazoncognito.com';
} else {
    runtimeURL = 'https://campapp.esentri.com';
    domainURL = 'cope-pool.auth.eu-central-1.amazoncognito.com';
}

const oauth = {
    domain: domainURL,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: runtimeURL,
    redirectSignOut: runtimeURL,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
};

export default oauth;

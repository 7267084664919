import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Transition from '../../../utils/transition';

interface IProps {
  handleClose: (orgaKey: string) => void;
}

export const DialogMembershipRequest = (props: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orgaKey, setOrgaKey] = useState('esentri-duesentrieb-camp-ger-000-000');
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={() => setDialogOpen(true)}
      >
        {t('label.requestMembership')}
      </Button>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {t('label.requestMembership')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {t('text.requestNewMembership')}
          </DialogContentText>
          <TextField
            value={orgaKey}
            id='orgaKeyInput'
            label={t('label.orgaKey')}
            variant='outlined'
            helperText={`${t('labe.eg')} XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX`}
            onChange={(e) => setOrgaKey(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            style={{ color: 'red' }}
            onClick={() => {
              setOrgaKey('');
              props.handleClose('');
              setDialogOpen(false);
            }}
          >
            {t('label.cancel')}
          </Button>
          <Button
            onClick={() => {
              props.handleClose(orgaKey);
              setDialogOpen(false);
            }}
            variant='outlined'
            color='primary'
            disabled={orgaKey.length !== 36}
          >
            {t('label.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';


export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      textAlign: 'center',
      paddingTop: '2%',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
      textAlign: 'center',
      paddingRight: '3rem',
    },
    content: {
      display: 'inline-grid',
      textAlign: 'left',
      '& > div': {
        maxWidth: '25rem',
        justifySelf: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 275,
      width: 'fit-content',
      margin: 'auto',
      padding: '1%'
    },
    cardActions: {
      display: 'flex',
      flex: '1 0 auto',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    helperText:{
      padding: '0% 2% 0% 2%',
      textAlign: 'left',
      width: 'fit-content',
      margin: 'auto',
    }
  })
 }
);

import ApiURl from '../config/user-service-config';
import {
  addUserToRequestGroup,
  adminAddUserToGroup,
  adminCreateOrga,
  adminRemoveUserFromGroup,
  deleteSelf,
  getUsersByGroup,
  getUsersByOrga,
  removeSelfFromGroup,
} from '../graphql-user-service/user-queries';
import {
  getUsersByGroupMapper,
  getUsersByOrgaMapper,
} from './mapper/user-mapper';

const POST = 'POST';
const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getUserInGroupAsAdmin = async (
  orgaId: string,
  userSub: string,
  group: string
) => {
  let dataResult: any;
  await fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: getUsersByGroup,
      variables: { groupName: `${orgaId}_${group}`, sub: userSub },
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      dataResult = getUsersByGroupMapper(data);
    })
    .catch((e) => {
      console.log('Request failed', e);
    });

  return dataResult;
};

export const getUsersByOrgaId = async (orgaId: string, userSub: string) => {
  let dataResult: any;
  await fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: getUsersByOrga,
      variables: { requestSub: userSub, orga_id: orgaId },
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      dataResult = getUsersByOrgaMapper(data);
    })
    .catch((e) => {
      console.log('Request failed', e);
    });

  return dataResult;
};

export const addUserToGroupAsAdmin = async (
  groupName: string,
  toAddSub: string,
  requestSub: string
) => {
  fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: adminAddUserToGroup,
      variables: { sub: toAddSub, requestSub, groupName },
    }),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.log('Right Update failed', e);
    });
};

export const userRequestToGroup = async (orgaKey: string, userId: string) => {
  fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: addUserToRequestGroup,
      variables: { orga_id: orgaKey, sub: userId },
    }),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.log('Request failed', e);
    });
};

export const deleteUserFromGroup = async (
  groupName: string,
  requestSub: string,
  removeSub: string
) => {
  fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: adminRemoveUserFromGroup,
      variables: {
        sub: removeSub,
        requestSub,
        groupName,
      },
    }),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.log('Remove failed', e);
    });
};

export const createOrgaGroups = async (orgaId: string, userId: string) => {
  fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: adminCreateOrga,
      variables: { sub: userId, orga_id: orgaId },
    }),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.log('Request failed', e);
    });
};

export const removeOwnGroup = async (sub: string, groupName: string) => {
  fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: removeSelfFromGroup,
      variables: {
        sub: sub,
        requestSub: sub,
        groupName: groupName,
      },
    }),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.log('Remove failed', e);
    });
};

export const deleteOwnProfile = async (sub: string) => {
  fetch(ApiURl, {
    method: POST,
    headers: HEADERS,
    body: JSON.stringify({
      query: deleteSelf,
      variables: {
        sub: sub,
        requestSub: sub,
      },
    }),
  })
    .then((r) => {
      r.json();
    })
    .catch((e) => {
      console.log('Remove failed', e);
    });
};

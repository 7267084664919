import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      width: 'fit-content',
      backgroundColor: theme.palette.background.paper,
      padding: '0px 0.5rem 0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '40rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '50rem',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '60rem',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '70rem',
      },
    },
    nested: {
      paddingLeft: theme.spacing(2),
      margin: 'auto',
      maxWidth: 'max-content',
      width: 'max-content',
    },
    avatar: {
      width: '1.5rem',
      height: '1.5rem',
    },
    rootDiv: {
      textAlign: 'center',
      marginTop: '0.45rem',
      marginBottom: '2px',
    },
  })
}
);

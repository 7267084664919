import { GraphQLResult } from '@aws-amplify/api';
import { GetOrgaQuery, OnUpdateOrgaSubscription } from '../../API';
import { IOrganization } from '../../models/interfaces/organization-interface';

export const orgaUpdateSubMapper = (
  input: GraphQLResult<OnUpdateOrgaSubscription>
): IOrganization => {
  const newOrga = {
    id: input.data?.onUpdateOrga?.Id!,
    name: input.data?.onUpdateOrga?.Name!,
    primaryColor: input.data?.onUpdateOrga?.PrimaryColor!,
    secondaryColor: input.data?.onUpdateOrga?.SecondaryColor!,
    currentEvent: input.data?.onUpdateOrga?.CurrentEvent!,
  };
  return newOrga;
};

export const orgaToEntityMapper = (orga: IOrganization) => {
  const mappedOrga = {
    Id: orga.id,
    CurrentEvent: orga.currentEvent,
    Name: orga.name,
    PrimaryColor: orga.primaryColor,
    SecondaryColor: orga.secondaryColor
  };

  return mappedOrga;
}

export const orgaListToEntityListMapper = (orgaList: IOrganization[]) => {
  const mappedOrgas: any = [];

  orgaList.forEach((orga) => {
    mappedOrgas.push({
      Id: orga.id,
      CurrentEvent: orga.currentEvent,
      Name: orga.name,
      PrimaryColor: orga.primaryColor,
      SecondaryColor: orga.secondaryColor
    })
  })

  return mappedOrgas;
}
export const orgaGetByIdMapper = (
  input: GraphQLResult<GetOrgaQuery>
): IOrganization => {
  const newOrga = {
    id: input.data?.getOrga?.Id!,
    name: input.data?.getOrga?.Name!,
    primaryColor: input.data?.getOrga?.PrimaryColor!,
    secondaryColor: input.data?.getOrga?.SecondaryColor!,
    currentEvent: input.data?.getOrga?.CurrentEvent!,
  };
  return newOrga;
};

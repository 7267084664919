import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IRoom} from '../../../models/interfaces/room-interface';
import {IRoomLink} from '../../../models/interfaces/room-link-interface';
import {ISession} from '../../../models/interfaces/session-interface';
import {IUser} from '../../../models/interfaces/user-interface';
import Transition from '../../../utils/transition';
import {useStyles} from './dialog-meeting-detail-styles';
import TagIcon from '@mui/icons-material/LocalOffer';
import {useTheme} from '@mui/material/styles';

interface IProps {
    oragUsers: IUser[];
    editMode: boolean;
    open: boolean;
    handleClose: () => void;
    session: ISession;
    buttonLinks: IRoomLink[];
    handleRoomChange: (newSession: ISession) => void;
    rooms: IRoom[];
}

export const DialogMeetingDetails = (props: IProps) => {
    const {t} = useTranslation();
    const classes = useStyles(useTheme())();
    const [session, setSession] = useState<ISession>();
    const [rooms, setRooms] = useState<IRoom[]>([]);
    const [buttonLinks, setButtonLinks] = useState<IRoomLink[]>([]);
    const [orgaUsers, setOrgaUsers] = useState<IUser[]>([]);

    useEffect(() => {
        setSession(props.session);
    }, [props.session]);

    useEffect(() => {
        setRooms(props.rooms);
    }, [props.rooms]);

    useEffect(() => {
        setButtonLinks(props.buttonLinks);
    }, [props.buttonLinks]);

    useEffect(() => {
        setOrgaUsers(props.oragUsers);
    }, [props.oragUsers]);

    const getSpeakers = (speakerIds: string[]): string => {
        let returnValue = '';

        // TODO hack entfernen um Doppelte User in Sessions zu filtern, Eigentliches Problem angehen (UI Komonenten & User Service)
        new Set(speakerIds).forEach((speakerId) => {
            let found = false;
            orgaUsers.forEach((user) => {
                if (user.sub === speakerId && !found) {
                    found = true;
                    returnValue === ''
                        ? (returnValue = `${user.given_name} ${user.family_name}`)
                        : (returnValue = `${returnValue}, ${user.given_name} ${user.family_name}`);
                }
            });

            // add ananymous data if User ist not anymore in Orga
            if (!found) {
                returnValue === ''
                    ? (returnValue = `${t('label.removedUser')}`)
                    : (returnValue = `${returnValue},  ${t('label.removedUser')}`);
            }
        });
        return returnValue;
    };

    const handleRoomChange = (roomId: string) => {
        const changeSession = {
            ...session!,
            roomId: roomId,
        };
        props.handleRoomChange(changeSession);
        setSession(changeSession);
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
        >
            <DialogTitle id='alert-dialog-slide-title'>
                {t('label.meetingDetails')}
            </DialogTitle>
            <DialogContent className={classes.root}>
                <Typography variant='h6'>
                    {t('label.title')}: {session?.title}
                </Typography>
                {session?.speaker !== undefined && (
                    <Typography variant='subtitle1'>
                        {t('label.speaker')}:{getSpeakers(session?.speaker)}
                    </Typography>
                )}
                <Typography variant='subtitle1'>
                    {t('label.tags')}:
                    {session?.tags.map((tag) => (
                        <Chip
                            className={classes.chip}
                            key={tag}
                            label={tag}
                            icon={<TagIcon/>}
                        />
                    ))}
                </Typography>
                <Typography variant='subtitle1'>
                    {t('label.type')}: {t(`label.${session?.type}`)}
                </Typography>
                <Typography variant='subtitle1'>
                    {t('label.description')}: {session?.description}
                </Typography>
                <div>
                    {buttonLinks.map((buttonLink) => (
                        <Button
                            className={classes.linkButton}
                            href={buttonLink.link}
                            variant='contained'
                            key={buttonLink.id}
                            target='_blank'
                        >
                            {buttonLink.label}
                        </Button>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                {
                    <>
                        {props.editMode && rooms
                            .filter((roomFilter) => session?.roomId !== roomFilter.id)
                            .map((room) => (
                                <Button
                                    key={room.id}
                                    onClick={() => {
                                        handleRoomChange(room.id);
                                    }}
                                    variant='outlined'
                                >
                                    {t('label.move')} {room.name}
                                </Button>
                            ))}
                    </>
                }

                <Button onClick={() => props.handleClose()} variant='outlined'>
                    {t('label.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import ArrowIcon from '@mui/icons-material/CallReceived';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import ArrowNext from '@mui/icons-material/ArrowForwardIos';
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import Transition from '../../utils/transition';
import { useStyles } from './first-login-dialog-styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export const DialogHelpFirstLogin = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  return (
    <Dialog
      className={classes.root}
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar></Toolbar>
      </AppBar>
      {page === 1 ? (
        <div className={classes.welcomeContainer}>
          <Typography variant='h4'>{t('titel.infoWelcomeHead')}</Typography>
          <Typography variant='subtitle2'>
            {t('titel.infoWelcomeHeadSubtitel')}
          </Typography>
          <Typography variant='h6' style={{ marginTop: '0.3rem' }}>
            {t('titel.infoWelcomeSubtitelOrgas')}
          </Typography>
          <Typography variant='body2'>
            {t('text.infoWelcomeTextOrgas')}
          </Typography>
          <Typography variant='h6' style={{ marginTop: '0.3rem' }}>
            {t('titel.infoWelcomeSubtitelOrgasCreate')}
          </Typography>
          <Typography variant='body2'>
            {t('text.infoWelcomeTextOrgasCreate')}
          </Typography>
          <div style={{ textAlign: 'right' }}>
            <IconButton
              onClick={() => setPage(2)}
              aria-label='back'
              style={{ border: '1px solid', margin: '3rem' }}
            >
              <ArrowNext />
            </IconButton>
          </div>
        </div>
      ) : (
        <>
          <Grid container>
            <Grid item xs={6} md={4} xl={3}>
              <ArrowIcon className={classes.arrowToBurger} />
              <Typography className={classes.textField} variant='subtitle2'>
                {t('text.infoBurgerMenu')}
              </Typography>
            </Grid>
            <Grid item md={4} xl={6}></Grid>
            <Grid item xs={6} md={4} xl={3} style={{ textAlign: 'right' }}>
              <ArrowIcon className={classes.arrowToProfile} />
              <Typography className={classes.textField} variant='subtitle2'>
                {t('text.infoProfileMenu')}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Grid container justifyContent='center'>
              <Grid item xs={6}>
                <IconButton
                  onClick={() => setPage(1)}
                  aria-label='back'
                  style={{ border: '1px solid', marginLeft: '1rem' }}
                >
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <IconButton
                  onClick={props.handleClose}
                  aria-label='close'
                  style={{ border: '1px solid', marginRight: '1rem' }}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <Grid container className={classes.bottomGridContainer}>
            <Grid item xl={2} />
            <Grid item xs={4} xl={3} style={{ textAlign: 'right' }}>
              <Typography className={classes.textField} variant='subtitle2'>
                {t('text.infoCalendar')}
              </Typography>
            </Grid>
            <Grid item xs={4} xl={2}>
              <Typography
                className={classes.textField}
                variant='subtitle2'
                style={{ textAlign: 'center' }}
              >
                {t('text.infoSessions')}
              </Typography>
            </Grid>
            <Grid item xs={4} xl={3}>
              <Typography className={classes.textField} variant='subtitle2'>
                {t('text.infoChat')}
              </Typography>
            </Grid>
            <Grid item xl={2} />

            <Grid item xs={3} xl={5} md={4} style={{ textAlign: 'right' }}>
              <ArrowIcon className={classes.arrowToCalendar} />
            </Grid>
            <Grid item xs={6} xl={2} md={4} style={{ textAlign: 'center' }}>
              <ArrowIcon className={classes.arrowToSessions} />
            </Grid>
            <Grid item xs={3} xl={5} md={4}>
              <ArrowIcon className={classes.arrowToChat} />
            </Grid>
          </Grid>
        </>
      )}
    </Dialog>
  );
};

import { Badge, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

interface IProps {
  disabled: boolean;
  count: number;
  voted: boolean;
  handleClick: () => void;
}
export const ButtonThumbUp = (props: IProps) => {
  const [disabled, setDisabled] = useState(false);
  const [voted, setVoted] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setDisabled(props.disabled);
    setCount(props.count);
    setVoted(props.voted);
  }, [props]);

  return (
    <IconButton
      style={{ padding: '2px' }}
      disabled={disabled}
      onClick={props.handleClick}
    >
      <Badge badgeContent={count} color='primary'>
        {voted ? (
          <ThumbUpIcon style={{ color: disabled ? '#a8cf9c' : '#64A70B' }} />
        ) : (
          <ThumbUpOutlinedIcon/>
        )}
      </Badge>
    </IconButton>
  );
};

import { API, graphqlOperation } from 'aws-amplify';
import { GetEventQuery, ListEventsQuery } from '../API';
import { getEvent, listEvents } from '../graphql/queries';
import { IEvent } from '../models/interfaces/event-interface';
import { GraphQLResult } from '@aws-amplify/api';
import { deleteEvent, createEvent, updateEvent } from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import * as RoomService from './room-service';
import * as SessionService from './session-service';

export const listEventsByOrgaId = async (orgaId: string): Promise<IEvent[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listEvents, {
      filter: {
        OrganisationId: {
          eq: orgaId,
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListEventsQuery>;

  const events: IEvent[] = [];

  if (retrievedList.data !== undefined && retrievedList.data !== null) {
    await retrievedList.data?.listEvents!.items!.forEach((event) => {
      if (event?.Id) {
        events.push({
          id: event!.Id,
          eventStatus: event!.EventStatus!,
          date: event!.Date!,
          organizationId: event!.OrganisationId,
          starttime: event!.Starttime!,
          endtime: event!.Endtime!,
          votingClosed: event!.VotingClosed!,
          submitClosed: event!.SubmitClosed!,
          archived: event!.Archived!,
        });
      }
    });
  }

  return events;
};

export const deleteEventById = async (eventId: string, orgaId: string) => {
  // first casc delete rooms (roomLinks deleted by AppSync PipelineResolver)
  await RoomService.listRoomsByEventId(eventId).then(
    (data) => {
      data.forEach(async (room) => {
        await RoomService.deleteRoomById(room.id, room.eventId).then(
          () => {},
          (error) => {
            console.log(error);
            alert('Cascade Delete Rooms failed!');
          }
        );
      });
    },
    (error) => {
      console.log(error);
      alert('Cascade Delete Rooms failed!');
    }
  );

  // second casc delete sessions (comments & votes deleted by AppSync PipelineResolver)
  await SessionService.listSessionsByEventId(eventId).then(
    (data) => {
      data.forEach(async (session) => {
        await SessionService.deleteSessionById(
          session.id,
          session.eventId
        ).then(
          () => {},
          (error) => {
            console.log(error);
            alert('Cascade Delete Sessions failed!');
          }
        );
      });
    },
    (error) => {
      console.log(error);
      alert('Cascade Delete Sessions failed!');
    }
  );

  // third delete the event (blocker & crossRoomLinks deleted by AppSync PipelineResolver)
  await API.graphql(
    graphqlOperation(deleteEvent, {
      input: { Id: eventId, OrganisationId: orgaId },
    })
  );
};

export const createNewEvent = async (event: IEvent) => {
  await API.graphql(
    graphqlOperation(createEvent, {
      input: {
        Archived: event.archived,
        Date: event.date,
        Endtime: event.endtime,
        EventStatus: event.eventStatus,
        Id: event.id,
        OrganisationId: event.organizationId,
        Starttime: event.starttime,
        SubmitClosed: event.submitClosed,
        VotingClosed: event.votingClosed,
      },
    })
  );
};

export const updateEventFull = async (event: IEvent) => {
  await API.graphql(
    graphqlOperation(updateEvent, {
      input: {
        Archived: event.archived,
        Date: event.date,
        Endtime: event.endtime,
        EventStatus: event.eventStatus,
        Id: event.id,
        OrganisationId: event.organizationId,
        Starttime: event.starttime,
        SubmitClosed: event.submitClosed,
        VotingClosed: event.votingClosed,
      },
    })
  );
};

export const getEventById = async (
  id: string,
  orgaId: string
): Promise<IEvent> => {
  const response = (await API.graphql(
    graphqlOperation(getEvent, {
      Id: id,
      OrganisationId: orgaId,
    })
  )) as GraphQLResult<GetEventQuery>;
  const event: IEvent = {
    id: response.data?.getEvent?.Id!,
    eventStatus: response.data?.getEvent?.EventStatus!,
    date: response.data?.getEvent?.Date!,
    organizationId: response.data?.getEvent?.OrganisationId!,
    starttime: response.data?.getEvent?.Starttime!,
    endtime: response.data?.getEvent?.Endtime!,
    votingClosed: response.data?.getEvent?.VotingClosed!,
    submitClosed: response.data?.getEvent?.SubmitClosed!,
    archived: response.data?.getEvent?.Archived!,
  };
  return event;
};

export const subscriptionCreateEventByOrga = (OrganisationId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onCreateEvent, { OrganisationId })
  );
};

export const subscriptionDeleteEventByOrga = (OrganisationId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onDeleteEvent, { OrganisationId })
  );
};

export const subscriptionUpdateEventByOrga = (OrganisationId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onUpdateEvent, { OrganisationId })
  );
};

export const subscriptionDeleteEventById = (Id: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onDeleteEventIdFilter, { Id })
  );
};

export const subscriptionUpdateEventById = (Id: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onUpdateEventIdFilter, { Id })
  );
};

import React, {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useGlobalContext} from '../libs/context-lib';
import * as OrgaService from '../services/organisation-service';
import * as UserService from '../services/user-service';

function querystring(name: string, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function OrgaUndefinedRoute({children}: any) {
    const {search} = useLocation();
    const {
        isAuthenticated,
        currentOrga,
        setCurrentOrga,
        userInfo,
        setCurrContainer,
    } = useGlobalContext();
    const redirect = querystring('redirect');

    useEffect(() => {
        const query = new URLSearchParams(search);
        if (query.get('inviteKey')) {
            const key = query.get('inviteKey');
            let alreadyIn = false;
            userInfo.groups.forEach((group) => {
                if (group.substring(0, 36) === key) {
                    alreadyIn = true;
                    OrgaService.getOrgaByGroup(group).then(
                        (data) => {
                            if (data) {
                                setCurrentOrga(data);
                                setCurrContainer(1);
                            }
                        },
                        (e) => console.log(e, 'FetchOrga failed')
                    );
                }
            });
            if (!alreadyIn) {
                UserService.userRequestToGroup(key!, userInfo.sub);
            }
        }
    }, [search, setCurrContainer, setCurrentOrga, userInfo.groups, userInfo.sub]);


    return isAuthenticated && currentOrga.id === '' ? children :
        <Navigate to={redirect === '' || redirect === null ? '/' : redirect}/>;
}

import { GraphQLResult } from '@aws-amplify/api';
import { OnCreateVoteSubscription } from '../../API';
import { IVote } from '../../models/interfaces/vote-interface';

export const voteCreateSubMapper = (
  createdVote: GraphQLResult<OnCreateVoteSubscription>
): IVote => {
  const newVote = {
    id: createdVote.data?.onCreateVote?.Id!,
    sessionId: createdVote.data?.onCreateVote?.SessionId!,
    userId: createdVote.data?.onCreateVote?.UserId!,
    eventId: createdVote.data?.onCreateVote?.EventId!,
  };

  return newVote;
};

export const voteToEntityMapper = (vote: IVote) => {
  const mappedVote = {
    Id: vote.id,
    SessionId: vote.sessionId,
    UserId: vote.userId
  };

  return mappedVote;
}

export const voteListToEntityListMapper = (voteList: IVote[]) => {
  const mappedVotes: any = [];

  voteList.forEach((vote) => {
    mappedVotes.push({
      Id: vote.id,
      SessionId: vote.sessionId,
      UserId: vote.userId
    })
  })

  return mappedVotes;
}
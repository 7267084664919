/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSession = /* GraphQL */ `
  mutation CreateSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession($input: DeleteSessionInput!) {
    deleteSession(input: $input) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const createBlocker = /* GraphQL */ `
  mutation CreateBlocker($input: CreateBlockerInput!) {
    createBlocker(input: $input) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const updateBlocker = /* GraphQL */ `
  mutation UpdateBlocker($input: UpdateBlockerInput!) {
    updateBlocker(input: $input) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const deleteBlocker = /* GraphQL */ `
  mutation DeleteBlocker($input: DeleteBlockerInput!) {
    deleteBlocker(input: $input) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const createOrga = /* GraphQL */ `
  mutation CreateOrga($input: CreateOrgaInput!) {
    createOrga(input: $input) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const updateOrga = /* GraphQL */ `
  mutation UpdateOrga($input: UpdateOrgaInput!) {
    updateOrga(input: $input) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const deleteOrga = /* GraphQL */ `
  mutation DeleteOrga($input: DeleteOrgaInput!) {
    deleteOrga(input: $input) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom($input: CreateRoomInput!) {
    createRoom(input: $input) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom($input: DeleteRoomInput!) {
    deleteRoom(input: $input) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const createRoomLink = /* GraphQL */ `
  mutation CreateRoomLink($input: CreateRoomLinkInput!) {
    createRoomLink(input: $input) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const updateRoomLink = /* GraphQL */ `
  mutation UpdateRoomLink($input: UpdateRoomLinkInput!) {
    updateRoomLink(input: $input) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const deleteRoomLink = /* GraphQL */ `
  mutation DeleteRoomLink($input: DeleteRoomLinkInput!) {
    deleteRoomLink(input: $input) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const createVote = /* GraphQL */ `
  mutation CreateVote($input: CreateVoteInput!) {
    createVote(input: $input) {
      Id
      SessionId
      UserId
      EventId
    }
  }
`;
export const deleteVote = /* GraphQL */ `
  mutation DeleteVote($input: DeleteVoteInput!) {
    deleteVote(input: $input) {
      Id
      SessionId
      UserId
      EventId
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input)
  }
`;
export const createSchedulePipeline = /* GraphQL */ `
  mutation CreateSchedulePipeline($input: String!) {
    createSchedulePipeline(input: $input)
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;

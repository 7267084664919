import {Avatar, IconButton, Menu, MenuItem} from '@mui/material';
import React from 'react';
import {Auth} from 'aws-amplify';
import {useGlobalContext} from '../../libs/context-lib';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface IProps {
    setHomeIndex: Function;
}

export const ProfileMenu = (props: IProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const location = useLocation();

    const open = Boolean(anchorEl);

    const {userHasAuthenticated, userInfo} = useGlobalContext();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNav = (path: string) => {
        setAnchorEl(null);
        if (location.pathname !== path) {
            props.setHomeIndex(null);
            navigate(path);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        setAnchorEl(null);
        await Auth.signOut();
        userHasAuthenticated(false);
    };
    return (
        <div>
            <IconButton
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'
            >
                <Avatar
                    style={{
                        borderStyle: 'solid',
                        borderColor: 'grey',
                        borderWidth: '2px',
                    }}
                    src={userInfo.picture}
                />
            </IconButton>
            <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleNav('profile')} id='profile'>
                    {t('label.myProfile')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleNav('/change-orga');
                    }}
                    id='orgas'
                >
                    {t('label.orgas')}
                </MenuItem>
                {/* <MenuItem onClick={handleClose} id='orgas'>
          {t('label.updateApp')}
        </MenuItem> */}
                <MenuItem onClick={handleLogout}>{t('label.logout')}</MenuItem>
            </Menu>
        </div>
    );
};

import { ISession } from '../../models/interfaces/session-interface';
import {
  ListSessionsQuery,
  OnCreateSessionSubscription,
  OnUpdateSessionSubscription,
} from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { ICalendarEvent } from '../../models/interfaces/calendar-event-interface';
import { IRoomLink } from '../../models/interfaces/room-link-interface';
import { parseTimeToISODate } from '../../utils/iso-timepicker-converter';
import { IUser } from '../../models/interfaces/user-interface';

export const listSessionsMapper = (
  listedSessions: GraphQLResult<ListSessionsQuery>
): ISession[] => {
  const newSessions: ISession[] = [];

  listedSessions.data?.listSessions?.items?.forEach((session) => {
    newSessions.push({
      id: session!.Id,
      eventId: session!.EventId,
      creator: session?.Creator!,
      description: session?.Description!,
      title: session?.Title!,
      type: session?.Type!,
      starttime: session?.Starttime!,
      endtime: session?.Endtime!,
      roomId: session?.RoomId!,
      speaker: session?.Speaker! as string[],
      tags: session?.Tags! as string[],
      duration: session?.Duration!,
      extern: session?.Extern!,
      mandatory: session?.Mandatory!,
      canceled: session?.Canceled!,
    });
  });
  return newSessions;
};

export const sessionCreateSubMapper = (
  createdSession: GraphQLResult<OnCreateSessionSubscription>
): ISession => {
  const newSession = {
    id: createdSession.data?.onCreateSession?.Id!,
    eventId: createdSession.data?.onCreateSession?.EventId!,
    creator: createdSession.data?.onCreateSession?.Creator!,
    description: createdSession.data?.onCreateSession?.Description!,
    title: createdSession.data?.onCreateSession?.Title!,
    type: createdSession.data?.onCreateSession?.Type!,
    starttime: createdSession.data?.onCreateSession?.Starttime!,
    endtime: createdSession.data?.onCreateSession?.Endtime!,
    roomId: createdSession.data?.onCreateSession?.RoomId!,
    speaker: createdSession.data?.onCreateSession?.Speaker! as string[],
    tags: createdSession.data?.onCreateSession?.Tags! as string[],
    duration: createdSession.data?.onCreateSession?.Duration!,
    extern: createdSession.data?.onCreateSession?.Extern!,
    mandatory: createdSession.data?.onCreateSession?.Mandatory!,
    canceled: createdSession.data?.onCreateSession?.Canceled!,
  };

  return newSession;
};

export const sessionUpdateSubMapper = (
  createdSession: GraphQLResult<OnUpdateSessionSubscription>
): ISession => {
  const newSession = {
    id: createdSession.data?.onUpdateSession?.Id!,
    eventId: createdSession.data?.onUpdateSession?.EventId!,
    creator: createdSession.data?.onUpdateSession?.Creator!,
    description: createdSession.data?.onUpdateSession?.Description!,
    title: createdSession.data?.onUpdateSession?.Title!,
    type: createdSession.data?.onUpdateSession?.Type!,
    starttime: createdSession.data?.onUpdateSession?.Starttime!,
    endtime: createdSession.data?.onUpdateSession?.Endtime!,
    roomId: createdSession.data?.onUpdateSession?.RoomId!,
    speaker: createdSession.data?.onUpdateSession?.Speaker! as string[],
    tags: createdSession.data?.onUpdateSession?.Tags! as string[],
    duration: createdSession.data?.onUpdateSession?.Duration!,
    extern: createdSession.data?.onUpdateSession?.Extern!,
    mandatory: createdSession.data?.onUpdateSession?.Mandatory!,
    canceled: createdSession.data?.onUpdateSession?.Canceled!,
  };

  return newSession;
};

export const mapSessionsToCalendarEvents = (
  eventDay: string,
  sessionList: ISession[],
  roomLinks: IRoomLink[],
  users: IUser[]
) => {
  let newCalendarSessionEvents: ICalendarEvent[] = [];

  sessionList.forEach((session) => {
    newCalendarSessionEvents.push({
      id: session.id,
      title: calenderEventTitleGenerator(session.title, session.speaker, users),
      start: parseTimeToISODate(eventDay, session.starttime),
      end: parseTimeToISODate(eventDay, session.endtime),
      editable: false,
      startEditable: false,
      durationEditable: false,
      resourceEditable: false,
      roomId: session.roomId,
      roomLinks: roomLinks,
      speaker: session.speaker,
      type: session.type,
      description: session.description,
      tags: session.tags,
      userId: '',
      canceled: session.canceled,
      backgroundColor: 'rgb(139, 191, 118, 0.9)',
      borderColor: 'rgb(139, 191, 118, 0.9)',
      mandatory: session.mandatory,
      textColor: 'white',
    });
  });

  return newCalendarSessionEvents;
};

export const mapSessionsToCalendarEventsGeneral = (
  eventDay: string,
  sessionList: ISession[],
  users: IUser[]
) => {
  let newCalendarSessionEvents: ICalendarEvent[] = [];

  sessionList.forEach((session) => {
    newCalendarSessionEvents.push({
      id: session.id,
      title: calenderEventTitleGenerator(session.title, session.speaker, users),
      start: parseTimeToISODate(eventDay, session.starttime),
      end: parseTimeToISODate(eventDay, session.endtime),
      editable: false,
      startEditable: false,
      durationEditable: false,
      resourceEditable: false,
      roomId: session.roomId,
      roomLinks: [],
      speaker: session.speaker,
      type: session.type,
      description: session.description,
      tags: session.tags,
      userId: '',
      canceled: session.canceled,
      backgroundColor: 'rgb(139, 191, 118, 0.9)',
      borderColor: 'rgb(139, 191, 118, 0.9)',
      mandatory: session.mandatory,
      textColor: 'white',
    });
  });

  return newCalendarSessionEvents;
};

export const sessionToEntityMapper = (session: ISession) => {
  const mappedSession = {
    EventId: session.eventId,
    Id: session.id,
    Creator: session.creator,
    Description: session.description,
    Starttime: session.starttime,
    Endtime: session.endtime,
    RoomId: session.roomId,
    Speaker: session.speaker,
    Tags: session.tags,
    Title: session.title,
    Type: session.type,
    Duration: session.duration,
    Extern: session.extern,
    Mandatory: session.mandatory,
    Canceled: session.canceled,
  };

  return mappedSession;
};

export const sessionListToEntityListMapper = (sessionList: ISession[]) => {
  const mappedSessions: any = [];

  sessionList.forEach((session) => {
    mappedSessions.push({
      EventId: session.eventId,
      Id: session.id,
      Creator: session.creator,
      Description: session.description,
      Starttime: session.starttime,
      Endtime: session.endtime,
      RoomId: session.roomId,
      Speaker: session.speaker,
      Tags: session.tags,
      Title: session.title,
      Type: session.type,
      Duration: session.duration,
      Extern: session.extern,
      Mandatory: session.mandatory,
      Canceled: session.canceled,
    });
  });

  return mappedSessions;
};

const calenderEventTitleGenerator = (
  sessionTitle: string,
  referents: string[],
  users: IUser[]
) => {
  let result: string = '';
  let currentRefCount = 0;

  referents.forEach((referentId) => {
    if (result === '') {
      //TODO: add HTML tag for new line
      result += sessionTitle + ' (';
    }

    let userIndex = users.findIndex((user) => user.sub === referentId);

    if (userIndex > -1) {
      const referentUser: IUser = users[userIndex];
      result += `${referentUser.given_name} ${referentUser.family_name}`;
      currentRefCount++;
    }

    if (currentRefCount === referents.length) {
      result += ')';
    } else if (currentRefCount < referents.length){
      result += ', ';
    }
  });

  return result;
};

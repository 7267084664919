import React, { useEffect, useState } from 'react';
import { UserManagement } from '../../components/user-management/user-management';
import { useGlobalContext } from '../../libs/context-lib';
import { IUser } from '../../models/interfaces/user-interface';
import * as UserService from '../../services/user-service';

export const UserManageContainer = () => {
  const { 
    userInfo, 
    currentOrga, 
    users, 
    admins, 
    owners, 
    setUsers, 
    setAdmins, 
    setOwners 
  } = useGlobalContext();
  const [userRequests, setUserRequests] = useState<IUser[]>([]);

  useEffect(() => {
    // Polling refresh Users in each group
    const refreshUsersInGroups = () => {
      const fetchUserRequests = async () => {
        UserService.getUserInGroupAsAdmin(
          currentOrga.id,
          userInfo.sub,
          'request'
        ).then((data) => {
          setUserRequests(data);
        });
      };

      fetchUserRequests();

      setTimeout(function () {
        // pull only if active tab
        if (document.visibilityState === 'visible') {
          refreshUsersInGroups();
        }
      }, 5000);
    };

    refreshUsersInGroups();
  }, [userInfo, currentOrga.id]);

  const handleAcceptUser = async (addedUserId: string) => {
    UserService.addUserToGroupAsAdmin(
      `${currentOrga.id}_user`,
      addedUserId,
      userInfo.sub
    );

    // remove user from request-list & add to users
    const newRequestList: IUser[] = [];
    const newUsers = users;
    userRequests.forEach((user) => {
      if (user.sub !== addedUserId) {
        newRequestList.push(Object.assign({}, user));
      } else {
        newUsers.push(user);
        setUsers(newUsers);
      }
    });
    setUserRequests(newRequestList);
  };

  const handleDeclineUser = async (declinedUserId: string) => {
    UserService.deleteUserFromGroup(
      `${currentOrga.id}_request`,
      userInfo.sub,
      declinedUserId
    );

    // remove user from request-list
    const newRequestList: IUser[] = [];
    userRequests.forEach((user) => {
      if (user.sub !== declinedUserId) {
        newRequestList.push(Object.assign({}, user));
      }
    });
    setUserRequests(newRequestList);
  };

  const handleRemoveUser = (deletedUserId: string) => {
    UserService.deleteUserFromGroup(
      `${currentOrga.id}_user`,
      userInfo.sub,
      deletedUserId
    );

    // remove user from user-list
    const newUserList: IUser[] = [];
    users.forEach((user) => {
      if (user.sub !== deletedUserId) {
        newUserList.push(Object.assign({}, user));
      }
    });
    setUsers(newUserList);
  };

  const handleSetUserToAdmin = (upgradedUserId: string) => {
    UserService.addUserToGroupAsAdmin(
      `${currentOrga.id}_admin`,
      upgradedUserId,
      userInfo.sub
    );

    // remove user from user-list & add to admins
    const newUserList: IUser[] = [];
    const newAdmins = admins;
    users.forEach((user) => {
      if (user.sub !== upgradedUserId) {
        newUserList.push(Object.assign({}, user));
      } else {
        newAdmins.push(user);
        setAdmins(newAdmins);
      }
    });
    setUsers(newUserList);
  };

  const handleSetUserToOwner = (upgradedUserId: string) => {
    UserService.addUserToGroupAsAdmin(
      `${currentOrga.id}_owner`,
      upgradedUserId,
      userInfo.sub
    );

    // remove user from user-list & add to admins
    const newAdminList: IUser[] = [];
    const newOwners = owners;
    admins.forEach((user) => {
      if (user.sub !== upgradedUserId) {
        newAdminList.push(Object.assign({}, user));
      } else {
        newOwners.push(user);
        setOwners(newOwners);
      }
    });
    setAdmins(newAdminList);
  };

  const handleRemoveUserFromAdmin = (removedUserId: string) => {
    UserService.addUserToGroupAsAdmin(
      `${currentOrga.id}_user`,
      removedUserId,
      userInfo.sub
    );

    // remove user from admin-list & add to users
    const newadminList: IUser[] = [];
    const newUsers = users;
    admins.forEach((user) => {
      if (user.sub !== removedUserId) {
        newadminList.push(Object.assign({}, user));
      } else {
        newUsers.push(user);
        setUsers(newUsers);
      }
    });
    setAdmins(newadminList);
  };

  const handleRemoveUserFromOwner = (removedUserId: string) => {
    UserService.addUserToGroupAsAdmin(
      `${currentOrga.id}_admin`,
      removedUserId,
      userInfo.sub
    );

    // remove user from owner-list & add to admins
    const newOwnerList: IUser[] = [];
    const newAdmins = admins;
    owners.forEach((user) => {
      if (user.sub !== removedUserId) {
        newOwnerList.push(Object.assign({}, user));
      } else {
        newAdmins.push(user);
        setAdmins(newAdmins);
      }
    });
    setOwners(newOwnerList);
  };

  return (
    <UserManagement
      admins={admins}
      users={users}
      userRequest={userRequests}
      owners={owners}
      handleAcceptUser={handleAcceptUser}
      handleDeclineUser={handleDeclineUser}
      handleRemoveUser={handleRemoveUser}
      handleSetUserToAdmin={handleSetUserToAdmin}
      handleRemoveUserFromAdmin={handleRemoveUserFromAdmin}
      handleSetUserToOwner={handleSetUserToOwner}
      handleRemoveUserFromOwner={handleRemoveUserFromOwner}
    />
  );
};

import {
  Paper,
  TableContainer,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../models/interfaces/user-interface';
import { checkPrivilegOwner } from '../../utils/check-privilege';
import { useStyles } from './user-manage-styles';
import { UserTableWithPagination } from './user-management-table';

interface IProps {
  userRequest: IUser[];
  admins: IUser[];
  users: IUser[];
  owners: IUser[];
  handleAcceptUser: (toAddUserId: string) => void;
  handleDeclineUser: (declinedUserId: string) => void;
  handleRemoveUser: (deletedUserId: string) => void;
  handleSetUserToAdmin: (upgradedUserId: string) => void;
  handleRemoveUserFromAdmin: (removedUserId: string) => void;
  handleSetUserToOwner: (upgradedUserId: string) => void;
  handleRemoveUserFromOwner: (removedUserId: string) => void;
}
export const UserManagement = (props: IProps) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [admins, setAdmins] = useState<IUser[]>([]);
  const [owners, setOwners] = useState<IUser[]>([]);
  const [usersRequests, setUsersRequests] = useState<IUser[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setUsersRequests(props.userRequest);
  }, [props.userRequest]);

  useEffect(() => {
    setOwners(props.owners);
  }, [props.owners]);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  useEffect(() => {
    setAdmins(props.admins);
  }, [props.admins]);

  return (
    <div className={classes.root}>
      <Typography
        variant='h5'
        style={{ textAlign: 'center', margin: '1rem 2rem 0rem' }}
      >
        {t('label.manageUsers')}
      </Typography>
      <div className={classes.tableHeader}>{t('label.requests')}</div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <UserTableWithPagination
          data={usersRequests}
          rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
          tableType={'request'}
          handleAcceptUser={props.handleAcceptUser}
          handleDeclineUser={props.handleDeclineUser}
          handleRemoveUser={props.handleRemoveUser}
          handleSetUserToAdmin={props.handleSetUserToAdmin}
          handleRemoveUserFromAdmin={props.handleRemoveUserFromAdmin}
          handleSetUserToOwner={props.handleSetUserToOwner}
          handleRemoveUserFromOwner={props.handleRemoveUserFromOwner}
          privilegeCheck={checkPrivilegOwner}
        />
      </TableContainer>

      <div className={classes.tableHeader}>{t('label.users')}</div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <UserTableWithPagination
          data={users}
          rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
          tableType={'user'}
          handleAcceptUser={props.handleAcceptUser}
          handleDeclineUser={props.handleDeclineUser}
          handleRemoveUser={props.handleRemoveUser}
          handleSetUserToAdmin={props.handleSetUserToAdmin}
          handleRemoveUserFromAdmin={props.handleRemoveUserFromAdmin}
          handleSetUserToOwner={props.handleSetUserToOwner}
          handleRemoveUserFromOwner={props.handleRemoveUserFromOwner}
          privilegeCheck={checkPrivilegOwner}
        />
      </TableContainer>

      <div className={classes.tableHeader}>{t('label.admins')}</div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <UserTableWithPagination
          data={admins}
          rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
          tableType={'admin'}
          handleAcceptUser={props.handleAcceptUser}
          handleDeclineUser={props.handleDeclineUser}
          handleRemoveUser={props.handleRemoveUser}
          handleSetUserToAdmin={props.handleSetUserToAdmin}
          handleRemoveUserFromAdmin={props.handleRemoveUserFromAdmin}
          handleSetUserToOwner={props.handleSetUserToOwner}
          handleRemoveUserFromOwner={props.handleRemoveUserFromOwner}
          privilegeCheck={checkPrivilegOwner}
        />
      </TableContainer>

      <div className={classes.tableHeader}>{t('label.owners')}</div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <UserTableWithPagination
          data={owners}
          rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
          tableType={'owner'}
          handleAcceptUser={props.handleAcceptUser}
          handleDeclineUser={props.handleDeclineUser}
          handleRemoveUser={props.handleRemoveUser}
          handleSetUserToAdmin={props.handleSetUserToAdmin}
          handleRemoveUserFromAdmin={props.handleRemoveUserFromAdmin}
          handleSetUserToOwner={props.handleSetUserToOwner}
          handleRemoveUserFromOwner={props.handleRemoveUserFromOwner}
          privilegeCheck={checkPrivilegOwner}
        />
      </TableContainer>
    </div>
  );
};

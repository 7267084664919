import AuthenticatedRoute from './authentificated-route';
import React from 'react';
import UnauthenticatedRoute from './unauthentificated-route';
import {HomeContainer} from '../container/home-container/home-container';
import {LoginContainer} from '../container/login-container/login-container';
import {Route, Routes} from 'react-router-dom';
import {OrgaManageListContainer} from '../container/orga-container/orga-manage-list-container';
import {OrgaManageContainer} from '../container/orga-container/orga-manage-container';
import OrgaUndefinedRoute from './orga-undefined-route';
import {OrgaForceChangeContainer} from '../container/orga-container/orga-force-change-container';
import {OrgaChangeContainer} from '../container/orga-container/orga-change-container';
import {EventListContainer} from '../container/event-container/event-list-container';
import {useGlobalContext} from '../libs/context-lib';
import {ExampleETcontainer} from '../container/example-et-container';
import {RoomListContainer} from '../container/room-container/room-list-container';
import {CurrentEventContainer} from '../container/event-container/current-event-container';
import {ProfileContainer} from '../container/profile-container/profile-container';
import {RegisterContainer} from '../container/login-container/register-container';
import {UserManageContainer} from '../container/user-manage-container/user-manage-container';
import {ProfileNoOrgaContainer} from '../container/profile-container/profile-no-orga-container';
import {PrivacyPolicy} from '../components/shared/privacy-policy';

export default function AppRoutes() {
    const {currentOrga} = useGlobalContext();

    return (
        <Routes>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/example' element={<ExampleETcontainer/>}/>
            <Route path='/login' element={
                <UnauthenticatedRoute> <LoginContainer/> </UnauthenticatedRoute>
            }
            />
            <Route path='/register' element={
                <UnauthenticatedRoute> <RegisterContainer/> </UnauthenticatedRoute>
            }
            />
            <Route path='/select-orga' element={
                <OrgaUndefinedRoute> <OrgaForceChangeContainer/> </OrgaUndefinedRoute>
            }
            />
            <Route path='/' element={
                <AuthenticatedRoute> <HomeContainer/> </AuthenticatedRoute>
            }
            />
            <Route path='/manage-orga' element={
                <AuthenticatedRoute> <OrgaManageContainer/> </AuthenticatedRoute>
            }
            />
            <Route path='/current-event' element={
                <AuthenticatedRoute> <CurrentEventContainer/> </AuthenticatedRoute>
            }
            />
            <Route path='/change-orga' element={
                <AuthenticatedRoute> <OrgaChangeContainer/> </AuthenticatedRoute>
            }
            />
            <Route path='/events' element={
                <AuthenticatedRoute> <EventListContainer/> </AuthenticatedRoute>
            }
            />
            <Route path='/rooms' element={
                <AuthenticatedRoute> <RoomListContainer/> </AuthenticatedRoute>
            }
            />
            <Route path='/manage-users' element={
                <AuthenticatedRoute> <UserManageContainer/> </AuthenticatedRoute>
            }
            />
            {currentOrga.id === '' ? (
                <>
                    <Route path='/manage-orgas' element={
                        <OrgaUndefinedRoute> <OrgaManageListContainer/> </OrgaUndefinedRoute>
                    }
                    />
                    <Route path='/profile' element={
                        <OrgaUndefinedRoute> <ProfileNoOrgaContainer/> </OrgaUndefinedRoute>
                    }
                    />
                </>
            ) : (
                <>
                    <Route path='/manage-orgas' element={
                        <AuthenticatedRoute> <OrgaManageListContainer/> </AuthenticatedRoute>
                    }
                    />
                    <Route path='/profile' element={
                        <AuthenticatedRoute> <ProfileContainer/> </AuthenticatedRoute>
                    }
                    />
                </>
            )}
            <Route path='/' element={
                <AuthenticatedRoute> <HomeContainer/> </AuthenticatedRoute>
            }
            />
        </Routes>
    );
}

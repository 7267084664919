import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      '& *': {
        color: 'white',
      },
    },
    appBar: {
      position: 'relative',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    title: {
      flex: 1,
      textAlign: 'center',
      paddingRight: '3rem',
      marginLeft: '3rem',
    },
    arrowToBurger: {
      transform: 'rotate(120deg)',
      marginLeft: '2rem',
      fontSize: '40px',
    },
    arrowToProfile: {
      transform: 'rotate(160deg)',
      marginRight: '3rem',
      fontSize: '40px',
    },
    textField: {
      borderRadius: '0.5rem',
      margin: '0px 0.5rem',
      padding: '0px 0.5rem',
      backgroundColor: 'rgba(63, 68, 68,0.9)',
    },
    bottomGridContainer: {
      position: 'absolute',
      bottom: '2rem',
      alignItems: 'flex-end',
    },
    buttonContainer: {
      margin: '20%',
      padding: '3%',
      borderRadius: '6px',
      backgroundColor: '#3F4444',
    },
    welcomeContainer: {
      padding: '10%',
      backgroundColor: '#3F4444',
    },
    arrowToCalendar: {
      transform: 'rotate(-45deg)',
      marginRight: '0.5rem',
      [theme.breakpoints.up('md')]: {
        marginRight: '0rem',
        transform: 'rotate(-90deg)',
      },
      fontSize: '40px',
    },
    arrowToSessions: {
      transform: 'rotate(-45deg)',
      fontSize: '40px',
    },
    arrowToChat: {
      transform: 'rotate(-45deg)',
      marginLeft: '0.5rem',
      //[theme.breakpoints.up('md')]: {
      //  marginLeft: '0rem',
      //  transform: 'rotate(0deg)',
      //},
      fontSize: '40px',
    },
  })
}
);

import {createStyles, makeStyles} from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
    return createStyles({
        root: {
            zIndex: 200,
            flexGrow: 1,
            bottom: 0,
            position: 'fixed',
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
        icons: {
            color: theme.palette.secondary.contrastText,
        },
    })
  }
);

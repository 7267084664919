import { API, graphqlOperation } from 'aws-amplify';
import { ListBlockersQuery } from '../API';
import { listBlockers } from '../graphql/queries';
import { IBlocker } from '../models/interfaces/blocker-interface';
import { GraphQLResult } from '@aws-amplify/api';
import {
  createBlocker,
  deleteBlocker,
  updateBlocker,
} from '../graphql/mutations';
import { listBlockersMapper } from './mapper/blocker-mapper';

export const listBlockerByEventId = async (
  eventId: string
): Promise<IBlocker[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listBlockers, {
      filter: {
        EventId: {
          eq: eventId,
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListBlockersQuery>;

  const blockers: IBlocker[] = listBlockersMapper(retrievedList);

  return blockers;
};

export const listBlockerByEventIdUserId = async (
  eventId: string,
  userId: string
): Promise<IBlocker[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listBlockers, {
      filter: {
        EventId: {
          eq: eventId,
        },
        UserId: {
          eq: userId,
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListBlockersQuery>;

  const blockers: IBlocker[] = listBlockersMapper(retrievedList);

  return blockers;
};

export const listBlockerByEventIdWithoutPersonal = async (eventId: string) => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listBlockers, {
      filter: {
        EventId: {
          eq: eventId,
        },
        UserId: {
          eq: '',
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListBlockersQuery>;

  const blockers: IBlocker[] = listBlockersMapper(retrievedList);

  return blockers;
};

export const deleteBlockerById = async (id: string, eventId: string) => {
  await API.graphql(
    graphqlOperation(deleteBlocker, {
      input: { Id: id, EventId: eventId },
    })
  );
};

export const updateBlockerFull = async (blocker: IBlocker) => {
  await API.graphql(
    graphqlOperation(updateBlocker, {
      input: {
        Id: blocker.id,
        EventId: blocker.eventId,
        Name: blocker.name,
        Starttime: blocker.starttime,
        Endtime: blocker.endtime,
        UserId: blocker.userId,
      },
    })
  );
};

export const createNewBlocker = async (blocker: IBlocker) => {
  await API.graphql(
    graphqlOperation(createBlocker, {
      input: {
        Id: blocker.id,
        EventId: blocker.eventId,
        Name: blocker.name,
        Starttime: blocker.starttime,
        Endtime: blocker.endtime,
        UserId: blocker.userId,
      },
    })
  );
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession($EventId: String) {
    onCreateSession(EventId: $EventId) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession($EventId: String) {
    onUpdateSession(EventId: $EventId) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession($EventId: String) {
    onDeleteSession(EventId: $EventId) {
      EventId
      Id
      Creator
      Description
      Starttime
      Endtime
      RoomId
      Speaker
      Tags
      Title
      Type
      Duration
      Extern
      Mandatory
      Canceled
    }
  }
`;
export const onCreateBlocker = /* GraphQL */ `
  subscription OnCreateBlocker(
    $EventId: String
    $Id: String
    $UserId: String
    $Name: String
    $Starttime: String
  ) {
    onCreateBlocker(
      EventId: $EventId
      Id: $Id
      UserId: $UserId
      Name: $Name
      Starttime: $Starttime
    ) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const onUpdateBlocker = /* GraphQL */ `
  subscription OnUpdateBlocker(
    $EventId: String
    $Id: String
    $UserId: String
    $Name: String
    $Starttime: String
  ) {
    onUpdateBlocker(
      EventId: $EventId
      Id: $Id
      UserId: $UserId
      Name: $Name
      Starttime: $Starttime
    ) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const onDeleteBlocker = /* GraphQL */ `
  subscription OnDeleteBlocker(
    $EventId: String
    $Id: String
    $UserId: String
    $Name: String
    $Starttime: String
  ) {
    onDeleteBlocker(
      EventId: $EventId
      Id: $Id
      UserId: $UserId
      Name: $Name
      Starttime: $Starttime
    ) {
      EventId
      Id
      UserId
      Name
      Starttime
      Endtime
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($OrganisationId: String) {
    onCreateEvent(OrganisationId: $OrganisationId) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($OrganisationId: String) {
    onUpdateEvent(OrganisationId: $OrganisationId) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($OrganisationId: String) {
    onDeleteEvent(OrganisationId: $OrganisationId) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const onUpdateEventIdFilter = /* GraphQL */ `
  subscription OnUpdateEventIdFilter($Id: String) {
    onUpdateEventIdFilter(Id: $Id) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const onDeleteEventIdFilter = /* GraphQL */ `
  subscription OnDeleteEventIdFilter($Id: String) {
    onDeleteEventIdFilter(Id: $Id) {
      Id
      OrganisationId
      EventStatus
      Date
      Starttime
      Endtime
      SubmitClosed
      VotingClosed
      Archived
    }
  }
`;
export const onCreateOrga = /* GraphQL */ `
  subscription OnCreateOrga(
    $Id: String
    $CurrentEvent: String
    $Name: String
    $PrimaryColor: String
    $SecondaryColor: String
  ) {
    onCreateOrga(
      Id: $Id
      CurrentEvent: $CurrentEvent
      Name: $Name
      PrimaryColor: $PrimaryColor
      SecondaryColor: $SecondaryColor
    ) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const onUpdateOrga = /* GraphQL */ `
  subscription OnUpdateOrga($Id: String) {
    onUpdateOrga(Id: $Id) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const onDeleteOrga = /* GraphQL */ `
  subscription OnDeleteOrga($Id: String) {
    onDeleteOrga(Id: $Id) {
      Id
      CurrentEvent
      Name
      PrimaryColor
      SecondaryColor
    }
  }
`;
export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom($EventId: String) {
    onCreateRoom(EventId: $EventId) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom($EventId: String) {
    onUpdateRoom(EventId: $EventId) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom($EventId: String) {
    onDeleteRoom(EventId: $EventId) {
      EventId
      Id
      Name
      Seats
    }
  }
`;
export const onCreateRoomLink = /* GraphQL */ `
  subscription OnCreateRoomLink($ReferenceId: String) {
    onCreateRoomLink(ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const onUpdateRoomLink = /* GraphQL */ `
  subscription OnUpdateRoomLink($ReferenceId: String) {
    onUpdateRoomLink(ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const onDeleteRoomLink = /* GraphQL */ `
  subscription OnDeleteRoomLink($ReferenceId: String) {
    onDeleteRoomLink(ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Label
      Link
      AcrossRoom
      ActivityDetail
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag(
    $Id: String
    $OrganisationId: String
    $Label: String
    $UseCounter: Int
  ) {
    onCreateTag(
      Id: $Id
      OrganisationId: $OrganisationId
      Label: $Label
      UseCounter: $UseCounter
    ) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag(
    $Id: String
    $OrganisationId: String
    $Label: String
    $UseCounter: Int
  ) {
    onUpdateTag(
      Id: $Id
      OrganisationId: $OrganisationId
      Label: $Label
      UseCounter: $UseCounter
    ) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag(
    $Id: String
    $OrganisationId: String
    $Label: String
    $UseCounter: Int
  ) {
    onDeleteTag(
      Id: $Id
      OrganisationId: $OrganisationId
      Label: $Label
      UseCounter: $UseCounter
    ) {
      Id
      OrganisationId
      Label
      UseCounter
    }
  }
`;
export const onCreateVote = /* GraphQL */ `
  subscription OnCreateVote($EventId: String) {
    onCreateVote(EventId: $EventId) {
      Id
      SessionId
      UserId
      EventId
    }
  }
`;
export const onDeleteVote = /* GraphQL */ `
  subscription OnDeleteVote($EventId: String) {
    onDeleteVote(EventId: $EventId) {
      Id
      SessionId
      UserId
      EventId
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($ReferenceId: String) {
    onCreateMessage(ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($ReferenceId: String) {
    onUpdateMessage(ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($ReferenceId: String) {
    onDeleteMessage(ReferenceId: $ReferenceId) {
      Id
      ReferenceId
      Content
      Timestamp
      UserId
    }
  }
`;

import {Slide} from '@mui/material';
import React from 'react';
import {TransitionProps} from '@mui/material/transitions';

/**
 * Slide in animation for dialogs
 */
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default Transition;

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = (theme: Theme) => makeStyles(() => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      textAlign: 'center',
      '& div': {
        margin: '0.2rem',
      },
      '& button': {
        margin: '1rem',
      },
    },
    googleButton: {
      margin: '0px',
      padding: '0px',
      borderWidth: '0px',
      border: 'none',
      background: 'none',
      boxShadow: 'none',
      boxSizing: 'content-box',
      display: 'inline-flex',
    },
    googleImg: {
      margin: '0px',
      padding: '0px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    buttonContainer: {
      marginTop: '3rem !IMPORTANT',
      marginLeft: 'auto !IMPORTANT',
      marginRight: 'auto !IMPORTANT',
      paddingTop: '1rem',
      width: 'max-content',
      textAlign: 'center',
    },
    registerContainer: {
      display: 'inline-grid',
      textAlign: 'right',
      margin: '0px',
    },
    registerLink: {
      paddingRight: '0.5rem',
      color: '#0000EE',
    },
  })
 }
);

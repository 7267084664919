import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
      circularProgress: {
        color: 'white',
        margin: 'auto',
        height: '8rem !IMPORTANT',
        width: '8rem !IMPORTANT'
      },
      progressContainer: {
        display: 'contents'
      }
    })
);

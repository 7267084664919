import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  // used in OrgaForm & OrgaManage
  createStyles({
    root: {
      textAlign: 'center',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    colors: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '2rem',
    },
    logo: {
      maxWidth: '70vw',
    },
    container: {
      margin: 'auto',
      width: 'max-content',
    },
    button: {
      margin: '1rem',
    },
    requestKey: {
      marginTop: '2rem',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& *': {
        color: 'black',
      },
    },
  })
);

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { IEvent } from '../../../models/interfaces/event-interface';
import { useTranslation } from 'react-i18next';
import Transition from '../../../utils/transition';


interface IProps {
  open: boolean;
  handleClose: (value: boolean, eventId: string) => void;
  row: IEvent;
}

export const EventDeleteDialog = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        {t('header.deleteEvent')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          {t('text.deleteEvent')} Möchtest du das ausgewählte Event wirklich
          löschen? Alle dazugehörigen Sessions und Votes werden ebenfalls
          gelöscht!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.handleClose(true, props.row.id)}
          variant='outlined'
          style={{ color: 'red' }}
        >
          {t('label.delete')}
        </Button>
        <Button
          onClick={() => props.handleClose(false, props.row.id)}
          variant='outlined'
        >
          {t('label.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: 'auto',
    justifyContent: 'center',
  },
  innerGrid: {
    width: '90%',
  },
  card: {
    margin: '1rem 3% 0.5rem 3%',
    backgroundColor: 'lightgrey',
  },
  cContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.5rem !important' ,
  },
  radioIcon: {
    float: 'right',
    marginRight: '1%',
  },
});

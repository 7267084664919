import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { IMessage } from '../models/interfaces/message-interface';
import { listMessages } from '../graphql/queries';
import { ListMessagesQuery } from '../API';
import { createMessage, deleteMessage } from '../graphql/mutations';
import { listMessagesMapper } from './mapper/message-mapper';
import * as subscriptions from '../graphql/subscriptions';

export const listMessagesByRefId = async (
  ReferenceId: string
): Promise<IMessage[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listMessages, {
      filter: {
        ReferenceId: {
          eq: ReferenceId,
        },
      },
      limit: 100,
    })
  )) as GraphQLResult<ListMessagesQuery>;

  const messageList: IMessage[] = listMessagesMapper(retrievedList);
  messageList.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
  return messageList;
};

export const createNewMessage = async (message: IMessage) => {
  await API.graphql(
    graphqlOperation(createMessage, {
      input: {
        Content: message.content,
        Id: message.id,
        ReferenceId: message.referenceId,
        Timestamp: message.timestamp,
        UserId: message.userId,
      },
    })
  );
};

export const deleteMessageByIdRefId = async (
  Id: string,
  ReferenceId: string
) => {
  await API.graphql(
    graphqlOperation(deleteMessage, {
      input: { Id, ReferenceId },
    })
  );
};

export const subscriptionCreateMessageByRefId = (ReferenceId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onCreateMessage, { ReferenceId })
  );
};

import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import GroupIcon from '@mui/icons-material/Group';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IUser } from '../../models/interfaces/user-interface';
import { Avatar, Grid } from '@mui/material';
import { useStyles } from './chat-member-styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface IProps {
  orgaUsers: IUser[];
}

export const ChatMembers = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [orgaUsers, setOrgaUsers] = useState<IUser[]>([]);

  useEffect(() => {
    props.orgaUsers && setOrgaUsers(props.orgaUsers);
  }, [props.orgaUsers]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.rootDiv}>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}
      >
        <ListItem
          button
          onClick={handleClick}
          style={{ backgroundColor: 'lightgrey', borderRadius: '0.3rem' }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={`(${orgaUsers.length}) ${t('label.users')}`} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <Grid container>
              {orgaUsers.map((user) => (
                <Grid item xs={12} sm={4} md={4} lg={3} xl={3} key={user.sub}>
                  <ListItem className={classes.nested} key={user.sub}>
                    <ListItemIcon style={{ minWidth: '30px' }}>
                      <Avatar className={classes.avatar} src={user.picture} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${user.given_name} ${user.family_name}`}
                    />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </List>
        </Collapse>
      </List>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { EventContext, useGlobalContext } from '../../libs/context-lib';
import { HomeCalendarContainer } from './home-calendar-container';
import { HomeChatContainer } from './home-chat-container';
import { HomeSessionsContainer } from './home-sessions-container';
import * as VoteService from '../../services/vote-service';
import { IVote } from '../../models/interfaces/vote-interface';
import { OnCreateVoteSubscription, OnDeleteVoteSubscription } from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { voteCreateSubMapper } from '../../services/mapper/vote-mapper';

export const HomeContainer = () => {
  const {
    currContainer,
    setCurrContainer,
    currentOrga,
    users,
    admins
  } = useGlobalContext();
  const orgaUsers = [...users, ...admins];
  const [eventVotes, setEventVotes] = useState<IVote[]>([]);


  useEffect(() => {
    currContainer === null && setCurrContainer(1);
  }, [currContainer, setCurrContainer]);

  useEffect(() => {
    let newVotes: IVote[] = [];
    const fetchVotes = async () => {
      await VoteService.listVotesByEventId(currentOrga.currentEvent!).then(
        (data) => {
          setEventVotes(data);
          newVotes = data;
        },
        (error: any) => {
          console.log(error);
          console.log('Get votes failed!');
        }
      );
    };
    fetchVotes();

    const createVoteSubscription = currentOrga.currentEvent
      ? VoteService.subscriptionCreateVote(
          currentOrga.currentEvent!
          // @ts-ignore
        ).subscribe({
          next: (createdVote: {
            value: GraphQLResult<OnCreateVoteSubscription>;
          }) => {
            const newVote = voteCreateSubMapper(createdVote.value);
            // deep copy
            const newData: IVote[] = [];
            newVotes!.forEach((val) => newData.push(Object.assign({}, val)));
            newData.push(newVote);
            newVotes = newData;
            setEventVotes(newData);
          },
        })
      : undefined;

    const deleteVoteSubscription = currentOrga.currentEvent
      ? VoteService.subscriptionDeleteVote(
          currentOrga.currentEvent!
          // @ts-ignore
        ).subscribe({
          next: (deletedVote: {
            value: GraphQLResult<OnDeleteVoteSubscription>;
          }) => {
            const toRemVote = deletedVote.value.data?.onDeleteVote;
            const newData: IVote[] = [];
            // deep copy without to remove vote
            newVotes!.forEach((val) => {
              val.id !== toRemVote?.Id && newData.push(Object.assign({}, val));
            });
            newVotes = newData;
            setEventVotes(newData);
          },
        })
      : undefined;

    return () => {
      createVoteSubscription && createVoteSubscription.unsubscribe();
      deleteVoteSubscription && deleteVoteSubscription.unsubscribe();
    };
  }, [currentOrga.currentEvent]);

  return (
    <div>
      <EventContext.Provider value={{ eventVotes }}>
        <div className='homeContainer'>
          {currContainer === 0 ? (
            <HomeCalendarContainer orgaUsers={orgaUsers} />
          ) : currContainer === 1 ? (
            <HomeSessionsContainer orgaUsers={orgaUsers} />
          ) : (
            currContainer === 2 && <HomeChatContainer orgaUsers={orgaUsers} />
          )}
        </div>
      </EventContext.Provider>
    </div>
  );
};

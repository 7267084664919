import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useStyles } from './room-link-styles';
// import { useTranslation } from 'react-i18next';
import { IRoomLink } from '../../../models/interfaces/room-link-interface';
import { EditableTable } from '../../shared/editable-table';
import { IRoom } from '../../../models/interfaces/room-interface';
import { v4 as uuidv4 } from 'uuid';
import Transition from '../../../utils/transition';
import { useTheme } from '@mui/material/styles';


interface IProps {
  open: boolean;
  handleClose: () => void;
  roomLinks: IRoomLink[];
  room: IRoom;
  createRoomLink: (roomLink: IRoomLink) => void;
  updateRoomLink: (roomLink: IRoomLink) => void;
  deleteRoomLink: (linkId: string, referenceId: string) => void;
}

const cols = [
  { prop: 'label' },
  { prop: 'link', colSpan: 2 },
  { prop: 'activityDetail', type: 'checkbox' },
];

function replaceWithValidUrl(link: IRoomLink): IRoomLink {
  const newLink = {...link};
  if (!newLink.link.startsWith("http://") && !newLink.link.startsWith("https://")) {
    newLink.link = `https://${link.link}`;
  }

  return newLink;
}

export const RoomLinkDialog = (props: IProps) => {
  const classes = useStyles(useTheme())();
  // const { t } = useTranslation();
  const [roomLinks, setRoomLinks] = useState<IRoomLink[]>();

  useEffect(() => {
    setRoomLinks(props.roomLinks);
  }, [props.roomLinks]);

  const handleAddLinkRow = (): IRoomLink => {
    const newData = {
      id: uuidv4(),
      referenceId: props.room.id,
      label: '',
      link: '',
      acrossRoom: false,
      activityDetail: false,
    } as IRoomLink;

    setRoomLinks([...roomLinks!, newData]);
    return newData;
  };

  const handleUpdateLink = (link: IRoomLink, oldLink: IRoomLink) => {
    const replacedLink = replaceWithValidUrl(link);
    props.updateRoomLink(replacedLink);
    updateDataSource(replacedLink, oldLink);
  };

  const handleCreateLink = (link: IRoomLink, oldLink: IRoomLink) => {
    const replacedLink = replaceWithValidUrl(link);
    props.createRoomLink(replacedLink);
    updateDataSource(replacedLink, oldLink);
  };

  const handleDeleteLink = (link: IRoomLink) => {
    props.deleteRoomLink(link.id, link.referenceId);
  };

  const updateDataSource = (newRow: IRoomLink, oldRow: IRoomLink) => {
    const newData: any = [];
    // deep copy
    roomLinks!.forEach((val) => newData.push(Object.assign({}, val)));
    const index = newData.findIndex((x: IRoomLink) => x.id === oldRow.id);
    if (index !== -1) {
      newData[index] = newRow;
      setRoomLinks(newData);
    }
  };

  return (
    <>
      {roomLinks !== undefined ? (
        <Dialog
          fullScreen
          open={props.open}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
                style={{ border: '1px solid' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6' className={classes.title}>
                {props.room?.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <div className={classes.content}>
              <EditableTable
                dataSource={roomLinks!}
                columns={cols}
                addDataRow={handleAddLinkRow}
                updateDataSource={setRoomLinks}
                handleUpdateData={handleUpdateLink}
                handleCreateData={handleCreateLink}
                handleDeleteData={handleDeleteLink}
              />
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={props.open}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
              >
                <ArrowBackIcon />
              </IconButton>
            </Toolbar>
            <div>...waiting for data</div>
          </AppBar>
        </Dialog>
      )}
    </>
  );
};

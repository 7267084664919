import { CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../../libs/context-lib';
import { userMapper } from '../../../services/mapper/user-mapper';
import Transition from '../../../utils/transition';
import { DialogDeleteConfirm } from '../../shared/confirm-delete-dialog';
import { useStyles } from '../profile-styles';
import { useTheme } from '@mui/material/styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleDeleteProfile: () => void;
}

export const DialogChangeUsrData = (props: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles(useTheme())();
  const { userInfo, setUserInfo } = useGlobalContext();
  const [prename, setPrename] = useState('');
  const [lastname, setLastname] = useState('');
  const [picture, setPicture] = useState('');
  const [submittingData, setSubmittingData] = useState(false);
  const [dialogDeleteProfile, setDialogDeleteProfile] = useState(false);

  useEffect(() => {
    userInfo?.given_name && setPrename(userInfo?.given_name);
    userInfo?.family_name && setLastname(userInfo?.family_name);
    userInfo?.picture && setPicture(userInfo?.picture);
  }, [userInfo]);

  const changeData = async () => {
    setSubmittingData(true);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      given_name: prename,
      family_name: lastname,
      picture: picture,
    }).then(async () => {
      const newUser = await Auth.currentAuthenticatedUser();
      setUserInfo(userMapper(newUser));
      setSubmittingData(false);
      props.handleClose();
    });
  };

  const handleCloseDeleteDialog = (value: boolean) => {
    if (value) {
      props.handleDeleteProfile();
    }
    setDialogDeleteProfile(false);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        Persönliche Daten ändern
      </DialogTitle>
      <DialogContent className={classes.usrEditDialogContent}>
        <div>
          <TextField
            label={t('label.prename')}
            variant='outlined'
            autoFocus
            type='text'
            value={prename}
            autoComplete='prename'
            onChange={(e) => setPrename(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label={t('label.lastname')}
            variant='outlined'
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            autoComplete='lastname'
            type='text'
          />
        </div>
        <div>
          <TextField
            label={t('label.profilePictureUrl')}
            variant='outlined'
            value={picture}
            onChange={(e) => setPicture(e.target.value)}
            autoComplete='picture-url'
            type='text'
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '2rem',
            marginBottom: '3rem',
          }}
        >
          <Button
            variant='contained'
            onClick={() => {
              setDialogDeleteProfile(true);
            }}
            style={{ backgroundColor: 'red' }}
          >
            {t('label.deleteProfile')}
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        {submittingData ? (
          <CircularProgress />
        ) : (
          <>
            <Button variant='contained' onClick={() => changeData()}>
              {t('label.submit')}
            </Button>
            <Button
              style={{ color: 'red' }}
              variant='contained'
              onClick={() => props.handleClose()}
            >
              {t('label.cancel')}
            </Button>
          </>
        )}
      </DialogActions>
      {dialogDeleteProfile && (
        <DialogDeleteConfirm
          title={t('title.deleteProfile')}
          text={t('text.deleteProfile')}
          open={true}
          handleClose={handleCloseDeleteDialog}
        />
      )}
    </Dialog>
  );
};

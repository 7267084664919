import React, { useEffect, useState } from 'react';
import { OrgaManageList } from '../../components/organization/orga-list-manage';
import * as OrgaService from '../../services/organisation-service';
import * as UserService from '../../services/user-service';
import { OrgaForm } from '../../components/organization/orga-form';
import { IOrganization } from '../../models/interfaces/organization-interface';
import { useGlobalContext } from '../../libs/context-lib';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const OrgaManageListContainer = () => {
  const [orgaList, setOrgaList] = useState<IOrganization[]>();
  const [onCreate, setOnCreate] = useState(false);
  const [creating, setCreating] = useState(false);
  const { userInfo } = useGlobalContext();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrgas = async () => {
      const fetchedOrgas: IOrganization[] = [];
      if (userInfo.groups) {
        for (let i = 0; i < userInfo.groups.length; i++) {
          if (
            userInfo.groups[i].length > 35 &&
            userInfo.groups[i].substring(37, 42) === 'admin'
          ) {
            await OrgaService.getOrgaByGroupAsAdmin(userInfo.groups[i]).then(
              (data) => {
                if (data) {
                  fetchedOrgas.push(data);
                }
              },
              (e) => console.log(e, 'FetchOrga failed')
            );
          }
        }
        setOrgaList(fetchedOrgas);
      }
    };

    fetchOrgas();
  }, [userInfo.groups]);

  const handleCreateSubmit = async (newOrga: IOrganization) => {
    setCreating(true);
    await OrgaService.createNewOrga(newOrga).then(
      () => {
        UserService.createOrgaGroups(newOrga.id, userInfo.sub).catch((e) =>
          alert(
            'Creating IdentityPools for your Organisation failed, contact a Administrator:' +
              e
          )
        );
        setCreating(false);
        setOnCreate(false);
      },
      (error) => {
        console.log(error);
        alert('Create failed!');
      }
    );
  };

  return (
    <div>
      <Typography
        variant='h5'
        style={{ textAlign: 'center', margin: '2rem 2rem 1rem' }}
      >
        {t('label.myOrgaList')}
      </Typography>
      {onCreate ? (
        <OrgaForm
          handleSubmit={handleCreateSubmit}
          setOnEdit={setOnCreate}
          submitting={creating}
        />
      ) : (
        <OrgaManageList orgaList={orgaList} setOnCreate={setOnCreate} />
      )}
    </div>
  );
};

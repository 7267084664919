import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Register } from '../../components/login/register';
import * as UserService from '../../services/user-service';

export const RegisterContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [registerComplete, setRegisterComplete] = useState(false);

  /**
   *
   * @param email
   * @param password
   * @param family_name lastname
   * @param given_name firstname
   */
  const submitRegister = async (
    email: string,
    password: string,
    family_name: string,
    given_name: string,
    orgaKey?: string
  ) => {
    setIsLoading(true);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          family_name,
          given_name,
          // other custom attributes
        },
      }).then((response) => {
        if (orgaKey !== undefined && orgaKey !== '') {
          UserService.userRequestToGroup(orgaKey, response.userSub);
        }
      });

      setRegisterComplete(true);
    } catch (error: any) {
      setIsLoading(false);
      error?.message ? alert(error.message) : alert(error);
    }
  };

  return (
    <>
      <Register
        isLoading={isLoading}
        handleSubmit={submitRegister}
        registerComplete={registerComplete}
      />
    </>
  );
};

export const getUsersByGroup = `query getUsersByGroup($groupName: String!, $sub: String!) {
   getUsersByGroup(GroupName: $groupName, sub: $sub){
      Username
      email
      given_name
      family_name
   }
}`;

export const adminAddUserToGroup = `query adminAddUserToGroup($sub: String!, $requestSub: String!, $groupName: String!) {
   adminAddUserToGroup(sub: $sub, requestSub: $requestSub, groupName: $groupName){
      Username
   }
  }`;

export const addUserToRequestGroup = `query addUserToRequestGroup($orga_id: String!, $sub: String!) {
   addUserToRequestGroup(orga_id: $orga_id, sub: $sub){
      Username
   }
}`;

export const adminRemoveUserFromGroup = `query adminRemoveUserFromGroup($sub: String!, $requestSub: String!, $groupName: String!) {
   adminRemoveUserFromGroup(sub: $sub, requestSub: $requestSub, groupName: $groupName){
      Username
   }
}`;

export const adminCreateOrga = `query adminCreateOrga($sub: String!, $orga_id: String!) {
   adminCreateOrga(sub: $sub, orga_id: $orga_id){
      GroupName
   }
}`;

export const getUsersByOrga = `query getUsersByOrga ($requestSub: String!, $orga_id: String!) {
   getUsersByOrga(orga_id: $orga_id, requestSub: $requestSub){
      Username
      email
      given_name
      family_name
      picture
   }
}`;

export const removeSelfFromGroup = `query removeSelfFromGroup ($sub: String!, $requestSub: String!, $groupName: String!) {
   removeSelfFromGroup(sub: $sub, requestSub: $requestSub, groupName: $groupName){
      Username
   }
}`;

export const deleteSelf = `query deleteSelf ($sub: String!, $requestSub: String!){
   deleteSelf(sub: $sub, requestSub: $requestSub){
      Username
      given_name
   }
}`;

import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {},
    content: {
        "& > *": {
            marginTop: "2rem",
            marginBottom: "2rem",
        },
    },
    span: {
        paddingRight: '2rem',
    },
}));

import { Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogMyBlocker } from '../../components/blocker/dialogs/my-blocker-dialog';
import { SessionList } from '../../components/session/session-list';
import { useGlobalContext } from '../../libs/context-lib';
import { IBlocker } from '../../models/interfaces/blocker-interface';
import { ISession } from '../../models/interfaces/session-interface';
import * as SessionService from '../../services/session-service';
import * as BlockerService from '../../services/blocker-service';
import * as MessageService from '../../services/message-service';
import {
  OnCreateMessageSubscription,
  OnCreateSessionSubscription,
  OnDeleteSessionSubscription,
  OnUpdateSessionSubscription,
} from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import {
  sessionCreateSubMapper,
  sessionUpdateSubMapper,
} from '../../services/mapper/session-mapper';
import { IMessage } from '../../models/interfaces/message-interface';
import { messageCreateSubMapper } from '../../services/mapper/message-mapper';
import { IUser } from '../../models/interfaces/user-interface';

interface IProps {
  orgaUsers: IUser[];
}

export const HomeSessionsContainer = (props: IProps) => {
  const { currentOrga, userInfo, currentEvent } = useGlobalContext();
  const { t } = useTranslation();
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [blockerDialog, setBlockerDialog] = useState(false);
  const [blockers, setBlockers] = useState<IBlocker[]>([]);
  const [fetchSessionMessages, setFetchSessionMessages] = useState<string>('');
  const [fetchingMessages, setFetchingMessages] = useState<boolean>(false);
  const [sessionMessages, setSessionMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    let newMessages: IMessage[] = [];
    setFetchingMessages(true);

    let createMessageSubscription: any;
    if (fetchSessionMessages) {
      setSessionMessages([]);
      const fetchMessages = async () => {
        await MessageService.listMessagesByRefId(fetchSessionMessages).then(
          (data) => {
            setSessionMessages(data);
            newMessages = data;
            setFetchingMessages(false);
          },
          (error: any) => {
            console.log(error);
            alert('Get Messages failed!');
            setFetchingMessages(false);
          }
        );
      };
      fetchMessages();

      createMessageSubscription = MessageService.subscriptionCreateMessageByRefId(
        fetchSessionMessages
        // @ts-ignore
      ).subscribe({
        next: (createdMessage: {
          value: GraphQLResult<OnCreateMessageSubscription>;
        }) => {
          const newMessage = messageCreateSubMapper(createdMessage.value);
          // deep copy
          const newData: IMessage[] = [];
          newMessages!.forEach((val) => newData.push(Object.assign({}, val)));
          newData.push(newMessage);
          newMessages = newData;
          setSessionMessages([...newData]);
        },
      });
    }

    return () => {
      createMessageSubscription?.unsubscribe();
    };
  }, [fetchSessionMessages]);

  useEffect(() => {
    if (currentOrga.currentEvent) {
      let newSessions: ISession[] = [];
      const getBlockers = async () => {
        await BlockerService.listBlockerByEventIdUserId(
          currentOrga.currentEvent!,
          userInfo.sub
        ).then(
          (data) => {
            setBlockers(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get failed!');
          }
        );
      };
      const fetchSessions = async () => {
        await SessionService.listSessionsByEventId(
          currentOrga.currentEvent!
        ).then(
          (data: ISession[]) => {
            newSessions = data;
            setSessions(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get sessions failed!');
          }
        );
      };

      fetchSessions();
      getBlockers();

      /**
       * Subscriptions
       */
      const createSessionSubscription = SessionService.subscriptionCreateSession(
        currentOrga.currentEvent!
        // @ts-ignore
      ).subscribe({
        next: (createdSession: {
          value: GraphQLResult<OnCreateSessionSubscription>;
        }) => {
          const newSession = sessionCreateSubMapper(createdSession.value);
          // deep copy
          const newData: ISession[] = [];
          newSessions!.forEach((val) => newData.push(Object.assign({}, val)));
          newData.push(newSession);
          newSessions = newData;
          setSessions([...newData]);
        },
      });

      const updateSessionSubscription = SessionService.subscriptionUpdateSession(
        currentOrga.currentEvent!
        // @ts-ignore
      ).subscribe({
        next: (updatedSession: {
          value: GraphQLResult<OnUpdateSessionSubscription>;
        }) => {
          const newSession = sessionUpdateSubMapper(updatedSession.value);
          // deep copy & replace update
          const newData: ISession[] = [];
          newSessions!.forEach((val) => {
            val.id === newSession.id
              ? newData.push(newSession)
              : newData.push(Object.assign({}, val));
          });
          newSessions = newData;
          setSessions([...newData]);
        },
      });

      const deleteSessionSubscription = SessionService.subscriptionDeleteSession(
        currentOrga.currentEvent!
        // @ts-ignore
      ).subscribe({
        next: (deletedSession: {
          value: GraphQLResult<OnDeleteSessionSubscription>;
        }) => {
          const toRemSession = deletedSession.value.data?.onDeleteSession;
          const newData: ISession[] = [];
          // deep copy without to rm session
          newSessions!.forEach((val) => {
            val.id !== toRemSession?.Id && newData.push(Object.assign({}, val));
          });
          newSessions = newData;
          setSessions([...newData]);
        },
      });

      return () => {
        createSessionSubscription.unsubscribe();
        updateSessionSubscription.unsubscribe();
        deleteSessionSubscription.unsubscribe();
      };
    }
  }, [currentOrga.currentEvent, userInfo.sub]);

  const handleSessionCreate = async (session: ISession) => {
    SessionService.createNewSession(session).then(
      () => {
        setSessions([...sessions, session]);
      },
      (error: any) => {
        console.log(error);
        alert('Create failed!');
      }
    );
  };

  const handleDeleteSession = async (sessionId: string, eventId: string) => {
    SessionService.deleteSessionById(sessionId, eventId).then(
      () => {
        const index = sessions.findIndex((x: ISession) => x.id === sessionId);
        const newList = [...sessions];
        newList.splice(index, index >= 0 ? 1 : 0);
        setSessions(newList);
      },
      (error: any) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  const handleSessionUpdate = async (session: ISession) => {
    SessionService.updateSessionFull(session).then(
      () => {
        const index = sessions.findIndex((x: ISession) => x.id === session.id);
        const newList = [...sessions];
        newList[index] = session;
        setSessions(newList);
      },
      (error: any) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const updateBlocker = async (newRow: IBlocker) => {
    BlockerService.updateBlockerFull(newRow).then(
      () => {
        const index = blockers.findIndex((x: IBlocker) => x.id === newRow.id);
        const newList = [...blockers];
        newList[index] = newRow;
        setBlockers(newList);
      },
      (error: any) => {
        console.log(error);
        alert('Update failed!');
      }
    );
  };

  const createBlocker = async (newRow: IBlocker) => {
    BlockerService.createNewBlocker(newRow).then(
      () => {
        setBlockers([...blockers, newRow]);
      },
      (error: any) => {
        console.log(error);
        alert('Create Blocker failed!');
      }
    );
  };

  const handleCreateMessage = async (message: IMessage) => {
    MessageService.createNewMessage(message).then(
      () => {},
      (error: any) => {
        console.log(error);
        alert('Create Message failed!');
      }
    );
  };

  const deleteBlocker = async (row: IBlocker) => {
    BlockerService.deleteBlockerById(row.id, row.eventId).then(
      () => {
        const newData: any = [];
        // deep copy
        blockers.forEach((val) => newData.push(Object.assign({}, val)));
        const index = newData!.findIndex((x: IBlocker) => x.id === row.id);
        newData!.splice(index, index >= 0 ? 1 : 0);
        setBlockers([...newData]);
      },
      (error: any) => {
        console.log(error);
        alert('Delete failed!');
      }
    );
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Paper
        style={{
          maxWidth: '80rem',
          display: 'inline-grid',
          marginTop: '1%',
          paddingBottom: '3%',
          marginBottom: '4rem',
        }}
      >
        {currentOrga.currentEvent !== '' ? (
          <>
            <h2>{t('label.sessionList')}</h2>
            <div style={{ width: '96%', textAlign: 'right' }}>
              <Button
                variant='contained'
                onClick={() => setBlockerDialog(true)}
                disabled={
                  currentEvent.archived ||
                  currentEvent.eventStatus === 'canceled'
                }
              >
                {t('label.myBlocker')}
              </Button>
            </div>
            <SessionList
              fetchingMessages={fetchingMessages}
              handleCreateMessage={handleCreateMessage}
              handleDelete={handleDeleteSession}
              handleSubmitCreate={handleSessionCreate}
              handleSubmitUpdate={handleSessionUpdate}
              messages={sessionMessages}
              orgaUsers={props.orgaUsers}
              sessions={sessions}
              setFetchSessionMessages={setFetchSessionMessages}
            />
            {blockerDialog && (
              <DialogMyBlocker
                blockers={blockers}
                handleClose={() => setBlockerDialog(false)}
                handleUpdateData={updateBlocker}
                handleCreateData={createBlocker}
                handleDeleteData={deleteBlocker}
              />
            )}
          </>
        ) : (
          <>
            <h2>{t('label.noCurrentSession')}</h2>
            <h2>{t('text.noCurrentSession')}</h2>
          </>
        )}
      </Paper>
    </div>
  );
};

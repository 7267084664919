import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import Transition from '../../utils/transition';

interface IProps {
  open: boolean;
  handleClose: (value: boolean) => void;
  title: string;
  text: string;
}

export const ConfirmDialog = (props: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!loading ? (
          <>
            <Button
              onClick={() => {
                setLoading(true);
                props.handleClose(false);
              }}
              variant='outlined'
              style={{ color: 'red' }}
            >
              {t('label.cancel')}
            </Button>
            <Button
              onClick={() => {
                setLoading(true);
                props.handleClose(true);
              }}
              variant='outlined'
            >
              {t('label.confirm')}
            </Button>
          </>
        ) : (
          <div style={{ textAlign: 'center', width: '-webkit-fill-available' }}>
            <CircularProgress />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

import { useTranslation } from 'react-i18next';
import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Transition from '../../../utils/transition';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export const RegisterComplDialog = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        {t('label.registrationComplete')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          {t('text.registrationComplete')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleClose();
          }}
          variant='outlined'
          color='primary'
        >
          {t('label.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

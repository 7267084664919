/**
 * Convert Timepicker string "hh:mm" to an ISO Date string for the reffered event day"
 * @param event current event
 * @param time timepicker time string
 * @output ISO Date string for the given time at the given event day
 */

export const parseTimeToISODate = (event: string, time: string) => {
  //receives 'hh:mm' entry and returns an ISO Date String with the current event date as day
  let splitHoursMinutes: string[] = time.split(':');
  let eventDate: Date = new Date(event);

  let resultDate: Date = new Date(
    eventDate.getFullYear(),
    eventDate.getMonth(),
    eventDate.getDate(),
    parseInt(splitHoursMinutes[0]),
    parseInt(splitHoursMinutes[1]),
    0,
    0
  );
  return resultDate.toISOString();
};

export const parseISODateToTime = (time: string) => {
  //receives ISO date and returns a time in 'hh:mm' format
  let givenDate = new Date(time);
  let dateHours = givenDate.getHours();
  let dateMinutes = givenDate.getMinutes();

  let result: string = dateHours.toString() + ':' + dateMinutes.toString();

  return result;
}
import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useStyles} from './session-list-styles';
import {useEventContext, useGlobalContext} from '../../libs/context-lib';
import {ISession} from '../../models/interfaces/session-interface';
import {Button, FormControl, MenuItem, Select, SelectChangeEvent, Tooltip,} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {DialogSessionDetails} from './dialogs/session-details-dialog';
import {DialogSessionForm} from './dialogs/session-form-dialog';
import {ITag} from '../../models/interfaces/tag-interface';
import {VoteContainer} from '../../container/vote-container/vote-container';
import {IMessage} from '../../models/interfaces/message-interface';
import {IUser} from '../../models/interfaces/user-interface';

const tags: ITag[] = [
    {label: 'UI', id: 'id1', orgaId: 'orga1', useCount: 2},
    {label: 'UX', id: 'id2', orgaId: 'orga2', useCount: 3},
];

interface IProps {
    fetchingMessages: boolean;
    handleCreateMessage: (message: IMessage) => void;
    handleDelete: (sessionId: string, eventId: string) => void;
    handleSubmitCreate: (session: ISession) => void;
    handleSubmitUpdate: (session: ISession) => void;
    messages: IMessage[];
    orgaUsers: IUser[];
    sessions: ISession[];
    setFetchSessionMessages: (sessionId: string) => void;
}

export const SessionList = (props: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {userInfo, currentEvent} = useGlobalContext();
    const {eventVotes} = useEventContext();
    const [sessions, setSessions] = useState<ISession[]>([]);
    const [mySessions, setMySessions] = useState<ISession[]>([]);
    const [onlyMySessions, setOnlyMySessions] = useState('all');
    const [selectedSession, setSelectedSession] = useState<ISession>();
    const [orgaUsers, setOrgaUsers] = useState<IUser[]>([]);
    const [createDialog, setCreateDialog] = useState(false);

    useEffect(() => {
        setOrgaUsers(props.orgaUsers);
    }, [props.orgaUsers]);

    useEffect(() => {
        selectedSession && props.setFetchSessionMessages(selectedSession.id);
        // disable react hook to prevent multiple api calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession]);

    useEffect(() => {
        const myData = [] as ISession[];
        props.sessions.forEach((element: ISession) => {
            if (selectedSession) {
                if (selectedSession.id === element.id) {
                    setSelectedSession(element);
                }
            }
            element.speaker.forEach((speaker) => {
                if (speaker === userInfo.sub) {
                    myData.push(element);
                }
            });
        });
        setMySessions(myData);
        setSessions(props.sessions);
    }, [props.sessions, selectedSession, userInfo]);

    const handleFilterSelectChange = (
        event: SelectChangeEvent
    ) => {
        setOnlyMySessions(event.target.value as string);
    };

    const handleCloseDetails = () => {
        setSelectedSession(undefined);
    };

    const handleRowClick = (row: ISession) => {
        setSelectedSession(row);
    };

    const handleCreateSession = () => {
        setCreateDialog(!createDialog);
    };

    const handleEditSession = () => {
        setCreateDialog(true);
    };


    const getSpeakers = (speakerIds: string[]): string => {
        let returnValue = '';

        // TODO hack entfernen um Doppelte User in Sessions zu filtern, Eigentliches Problem angehen (UI Komonenten & User Service)
        new Set(speakerIds).forEach((speakerId) => {
            let found = false;
            orgaUsers.forEach((user) => {
                if (user.sub === speakerId && !found) {
                    found = true;
                    returnValue === ''
                        ? (returnValue = `${user.given_name} ${user.family_name}`)
                        : (returnValue = `${returnValue}, ${user.given_name} ${user.family_name}`);
                }
            });

            // add ananymous data if User ist not anymore in Orga
            if (!found) {
                returnValue === ''
                    ? (returnValue = `${t('label.removedUser')}`)
                    : (returnValue = `${returnValue},  ${t('label.removedUser')}`);
            }
        });
        return returnValue;
    };

    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl} variant='outlined'>
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={onlyMySessions}
                    onChange={handleFilterSelectChange}
                    className={classes.select}
                >
                    <MenuItem value={'all'}>{t('label.allSessions')}</MenuItem>
                    <MenuItem value={'my'}>{t('label.mySessions')}</MenuItem>
                </Select>
            </FormControl>
            <div style={{width: '96%', textAlign: 'right'}}>
                <Tooltip title={currentEvent.submitClosed ? t('tooltip.submitSessionsClosed') : ''} arrow>
          <span>
            <Button
                variant='contained'
                onClick={handleCreateSession}
                disabled={currentEvent.submitClosed}
            >
              {t('label.createSession')}
            </Button>
          </span>
                </Tooltip>
            </div>
            <div className={classes.scrollContainer}>
                <TableContainer component={Paper} className={classes.container}>
                    <Table aria-label='simple table' className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('label.session')}</TableCell>
                                <TableCell>{t('label.speaker')}</TableCell>
                                <TableCell style={{width: '5rem'}}>
                                    {t('label.votes')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(onlyMySessions === 'all' ? sessions : mySessions).map((row) => (
                                <TableRow
                                    key={row.id}
                                    style={row.canceled ? {backgroundColor: '#ec9488'} : {}}
                                >
                                    <TableCell onClick={() => handleRowClick(row)}>
                                        {row.title} {row.canceled && ` (${t('label.canceled')})`}
                                    </TableCell>
                                    <TableCell onClick={() => handleRowClick(row)}>
                                        {getSpeakers(row.speaker)}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        style={{textAlign: 'center', padding: '0px'}}
                                    >
                                        <VoteContainer
                                            sessionId={row.id}
                                            votes={eventVotes.filter(
                                                (vote) => vote.sessionId === row.id
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {selectedSession !== undefined && (
                <DialogSessionDetails
                    orgaUsers={orgaUsers}
                    session={selectedSession!}
                    handleClose={handleCloseDetails}
                    handleDelete={(sessionId: string, eventId: string) => {
                        props.handleDelete(sessionId, eventId);
                        setSelectedSession(undefined);
                    }}
                    handleEdit={handleEditSession}
                    handleStateChange={props.handleSubmitUpdate}
                    messages={props.messages}
                    handleNewMessage={props.handleCreateMessage}
                    fetchingMessages={props.fetchingMessages}
                />
            )}
            {createDialog && (
                <DialogSessionForm
                    orgaUsers={orgaUsers}
                    session={selectedSession}
                    handleClose={handleCreateSession}
                    tags={tags}
                    handleSubmitCreate={(session: ISession) => {
                        props.handleSubmitCreate(session);
                        setCreateDialog(false);
                    }}
                    handleSubmitUpdate={(session: ISession) => {
                        props.handleSubmitUpdate(session);
                        setCreateDialog(false);
                    }}
                />
            )}
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { TabPanel } from '../../components/shared/tab-panel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ISession } from '../../models/interfaces/session-interface';
import { IBlocker } from '../../models/interfaces/blocker-interface';
import { useGlobalContext } from '../../libs/context-lib';
import { IRoom } from '../../models/interfaces/room-interface';
import { IRoomLink } from '../../models/interfaces/room-link-interface';
import * as BlockerService from '../../services/blocker-service';
import * as SessionService from '../../services/session-service';
import * as RoomService from '../../services/room-service';
import * as RoomLinkService from '../../services/room-link-service';
import * as VoteService from '../../services/vote-service';
import { EventCalendar } from '../../components/calendar/event-calendar';
import { IVote } from '../../models/interfaces/vote-interface';
import { IUser } from '../../models/interfaces/user-interface';
import { CircularProgress } from '@mui/material';

interface IProps {
  orgaUsers: IUser[];
}

export const HomeCalendarContainer = (props: IProps) => {
  const { currentOrga, currentEvent, userInfo } = useGlobalContext();
  const { t } = useTranslation();
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [blockers, setBlockers] = useState<IBlocker[]>([]);
  const [personalBlockers, setPersonalBlockers] = useState<IBlocker[]>([]);
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [crossRoomlinks, setCrossRoomlinks] = useState<IRoomLink[]>([]);
  const [roomlinks, setRoomlinks] = useState<IRoomLink[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [votes, setVotes] = useState<IVote[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const colorCollection: string[] = [
    'rgb(139, 191, 118, 0.7)',
    'rgb(176,224,230, 0.7)',
    'rgb(240,230,140, 0.7)',
    '#000000',
    '#0000ff',
    '#a52a2a',
    '#00ffff',
    '#00008b',
    '#008b8b',
    '#a9a9a9',
    '#006400',
  ];

  useEffect(() => {
    if (currentOrga.currentEvent) {
      const fetchGeneralBlockers = async () => {
        await BlockerService.listBlockerByEventIdWithoutPersonal(
          currentOrga.currentEvent!
        ).then(
          (data: IBlocker[]) => {
            setBlockers(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get failed!');
          }
        );
      };

      const fetchPersonalizedBlockers = async () => {
        await BlockerService.listBlockerByEventIdUserId(
          currentOrga.currentEvent!,
          userInfo.sub
        ).then(
          (data: IBlocker[]) => {
            setPersonalBlockers(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get failed');
          }
        );
      };

      const fetchSessions = async () => {
        await SessionService.listSessionsByEventIdScheduled(
          currentOrga.currentEvent!
        ).then(
          (data: ISession[]) => {
            setSessions(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get failed');
          }
        );
      };

      const fetchRooms = async () => {
        await RoomService.listRoomsByEventId(currentOrga.currentEvent!).then(
          (data: IRoom[]) => {
            setRooms(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get failed');
          }
        );
      };

      const fetchVotes = async () => {
        await VoteService.listVotesByEventId(currentOrga.currentEvent!).then(
          (data: IVote[]) => {
            setVotes(data);
          },
          (error: any) => {
            console.log(error);
            alert('Get failed');
          }
        );
      };

      setIsLoading(true);
      fetchGeneralBlockers();
      fetchPersonalizedBlockers();
      fetchSessions();
      fetchRooms();
      fetchVotes();
    }
  }, [currentOrga.currentEvent, userInfo.sub]);

  useEffect(() => {
    if (rooms.length !== 0 && rooms !== undefined) {
      const fetchRoomLinks = async () => {
        let newRooms = rooms;
        let roomLinks: IRoomLink[] = [];

        for (let i = 0; i < newRooms.length; i++) {
          await RoomLinkService.listRoomLinksByRoomId(newRooms[i].id).then(
            (data: IRoomLink[]) => {
              data.forEach((roomlink) =>
                roomLinks.push(Object.assign({}, roomlink))
              );
            },
            (e) => console.log(e, 'FetchLinks failed')
          );
        }
        setRoomlinks(roomLinks);
      };
      fetchRoomLinks();
      setIsLoading(false);
    }
  }, [rooms]);

  useEffect(() => {
    if (currentEvent?.id) {
      const fetchCrossRoomLinks = async () => {
        const newCrossRoomLinks: IRoomLink[] = [];

        await RoomLinkService.listRoomLinksByEventId(currentEvent.id).then(
          (data: IRoomLink[]) => {
            data.forEach((roomlink) =>
              newCrossRoomLinks.push(Object.assign({}, roomlink))
            );
          },
          (error: any) => {
            console.log(error);
            alert('Get failed');
          }
        );
        setCrossRoomlinks(newCrossRoomLinks);
      };
      fetchCrossRoomLinks();
    }
  }, [currentEvent]);

  useEffect(() => {
    if (rooms.length !== 0) {
      setActiveTab(rooms[0].id);
    }
  }, [rooms]);

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
  };

  //return tabs only if rooms exists since room count = tab count
  return (
    <div style={{ textAlign: 'center', padding: '1rem 3% 5rem' }}>
      {currentOrga.currentEvent !== '' &&
      rooms.length !== 0 &&
      activeTab !== '' &&
      !isLoading ? (
        <div>
          <div>
            {crossRoomlinks.map((roomlink) => (
              <Button
                style={{ margin: '0.5rem 0.3rem 0rem' }}
                key={roomlink.id}
                variant='contained'
                href={roomlink.link}
                target='_blank'
              >
                {roomlink.label}
              </Button>
            ))}
          </div>
          <Paper square style={{ margin: '0.3rem 2%', overflowX: 'auto' }}>
            <Tabs
              value={activeTab}
              indicatorColor='primary'
              textColor='primary'
              style={{ width: 'max-content' }}
              onChange={(e, newTab) => {
                e.preventDefault();
                handleTabChange(newTab);
              }}
              aria-label='disabled tabs example'
            >
              {rooms.map((room) => (
                <Tab key={room.id} value={room.id} label={room.name} />
              ))}
              <Tab key='allRoomCalendar' value='allRoomCalendar' label='Alle' />
              <Tab
                key='personalCalendar'
                value='personalCalendar'
                label= {t('label.personalCalendar')}
              />
            </Tabs>
          </Paper>
          {rooms.map((room) => (
            <TabPanel key={room.id} value={activeTab} index={room.id}>
              <div>
                {roomlinks
                  ?.filter((roomlink) => roomlink.referenceId === room.id)
                  .map((roomlink) => (
                    <Button
                      style={{ margin: '0.5rem 0.3rem 0rem' }}
                      key={roomlink.id}
                      variant='contained'
                      href={roomlink.link}
                      target='_blank'
                    >
                      {roomlink.label}
                    </Button>
                  ))}
              </div>
              <EventCalendar
                oragUsers={props.orgaUsers}
                editMode={false}
                sessions={sessions.filter(
                  (session) => session.roomId === room.id
                )}
                blockers={[...blockers, ...personalBlockers].filter(
                  (blocker) =>
                    blocker.userId === '' || blocker.userId === userInfo.sub
                )}
                room={room}
                rooms={rooms}
                roomlinks={roomlinks.filter(
                  (roomlink) =>
                    roomlink.referenceId === room.id || roomlink.activityDetail
                )}
              />
            </TabPanel>
          ))}
          <TabPanel
            key='allRoomCalendar'
            value={activeTab}
            index='allRoomCalendar'
          >
            <div>
              {roomlinks?.map((roomlink) => (
                <Button
                  style={{
                    margin: '0.5rem 0.3rem 0rem',
                    backgroundColor:
                      colorCollection[
                        rooms.findIndex(
                          (room) => room.id === roomlink.referenceId
                        )
                      ],
                  }}
                  key={roomlink.id}
                  variant='contained'
                  href={roomlink.link}
                  target='_blank'
                >
                  {roomlink.label}
                </Button>
              ))}
            </div>
            <EventCalendar
              oragUsers={props.orgaUsers}
              editMode={false}
              sessions={sessions}
              blockers={[...blockers, ...personalBlockers].filter(
                (blocker) =>
                  blocker.userId === '' || blocker.userId === userInfo.sub
              )}
              rooms={rooms}
              roomlinks={roomlinks}
            />
          </TabPanel>
          <TabPanel
            key='personalCalender'
            value={activeTab}
            index='personalCalendar'
          >
            <div>
              {roomlinks?.map((roomlink) => (
                <Button
                  style={{
                    margin: '0.5rem 0.3rem 0rem',
                    backgroundColor:
                      colorCollection[
                        rooms.findIndex(
                          (room) => room.id === roomlink.referenceId
                        )
                      ],
                  }}
                  key={roomlink.id}
                  variant='contained'
                  href={roomlink.link}
                  target='_blank'
                >
                  {roomlink.label}
                </Button>
              ))}
            </div>
            <EventCalendar
              oragUsers={props.orgaUsers}
              editMode={false}
              sessions={sessions}
              blockers={[...blockers, ...personalBlockers].filter(
                (blocker) =>
                  blocker.userId === '' || blocker.userId === userInfo.sub
              )}
              rooms={rooms}
              roomlinks={roomlinks}
              votes={votes.filter((vote) => vote.userId === userInfo.sub)}
              filterVotes={true}
            />
          </TabPanel>
        </div>
      ) : isLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <h2>{t('label.noCurrentSession')}</h2>
          <h2>{t('text.noCurrentSession')}</h2>
        </>
      )}
    </div>
  );
};

import {makeStyles} from '@mui/styles';

import React from 'react';
import {Paper} from "@mui/material";

const useStyles = makeStyles({
    root: {
        margin: '2%',
        padding: '2% 2% 3rem',
        '& h2': {
            marginTop: '3.5rem',
            whiteSpace: 'pre-line',
        },
        '& h3': {
            marginTop: '2.6rem',
            whiteSpace: 'pre-line',
        },
        '& h4': {
            margin: '1rem 1% 1rem',
            whiteSpace: 'pre-line',
        },
    },
});

export const PrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <h1>Datenschutzerklärung</h1>
            <h2>1. Allgemeines</h2>
            <h3>1.1 Personenbezogene Daten (Art. 4 Nr. 1 DS-GVO)</h3>
            <h4>
                Gegenstand des Datenschutzes sind personenbezogene Daten (nachfolgend
                auch Daten). Dies sind alle Informationen, die sich auf eine
                identifizierte oder identifizierbare natürliche Person beziehen.
                Hierunter fallen z.B. Angaben wie Name, Adresse, Beruf, E-Mail-Adresse,
                Gesundheitszustand, Einkommen, Familienstand, genetische Merkmale,
                Telefonnummer und gegebenenfalls auch Nutzerdaten wie die IP-Adresse.
            </h4>
            <h3>1.2 Verantwortlicher (Art. 4 Nr. 7 DS-GVO)</h3>
            <h4>
                Der Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten
                im Rahmen der Nutzung der Webseite https://campapp.esentri.com
                (nachfolgend Webseite) ist die esentri AG (nachfolgend Betreiber oder
                Verantwortlicher). Die Kontaktdaten sind:
            </h4>
            <h4>
                esentri AG <br/>
                Pforzheimer Str. 128b <br/>
                76275 Ettlingen <br/>
                Vorstand Frank Szilinski, Robert Szilinski (Vorsitzender) <br/>
                Telefon: 07243-354900 <br/>
                Telefax: 07243-3549099 <br/>
                E-Mail: info@esentri.com
            </h4>
            <h3>1.3 Widerspruchsmöglichkeit</h3>
            <h4>
                Sofern Sie der Verarbeitung Ihrer Daten durch den Betreiber nach Maßgabe
                dieser Datenschutzerklärung insgesamt oder für einzelne Maßnahmen
                widersprechen wollen, können Sie dies unter den im Impressum angegebenen
                Kontaktdaten tun. Bitte beachten Sie, dass im Falle eines solchen
                Widerspruchs die Nutzung der Webseite und der Abruf der hierüber
                angebotenen Leistungen unter Umständen nur eingeschränkt oder überhaupt
                nicht möglich sind.
            </h4>
            <h2>2. Umfang und Zwecke der Datenverarbeitung</h2>
            <h3>2.1 Registrierung</h3>
            <h4>
                Auf unseren Websites können Sie sich registrieren. Wenn Sie sich
                registrieren, erheben und speichern wir die Daten, die Sie in die
                Eingabemaske eingeben (z.B. Nachname, Vorname, E-Mail-Adresse). Eine
                Weitergabe an Dritte findet nicht statt.
            </h4>
            <h4>
                Die Registrierung ist für die Nutzung der Inhalte und Leistungen auf
                unseren Websites erforderlich.
            </h4>
            <h4>
                Die personenbezogenen Daten werden nach austreten einer
                Veranstaltungsreihe anonymisiert. Jeder Nutzer kann sein Profil in der
                Applikation selbst löschen, mit dieser Aktion werden auch alle
                personenbezogenen Daten anonymisiert oder entfernt. Es werden in jedem
                Fall nur diejenigen Daten weiterhin gespeichert, die für die Erreichung
                des entsprechenden Zwecks auch wirklich zwingend benötigt werden.
            </h4>
            <h3>2.2 Werbung</h3>
            <h4>
                Die Webseite schaltet keine Werbebanner und gibt keine Nutzerdaten an
                Werbetreiber weiter.
            </h4>
            <h3>2.3 Verwendung von Cookies</h3>
            <h4>Die Webseite verwendet keine Cookies.</h4>
            <h3>2.4 Amazon Web Services</h3>
            <h4>
                Wir hosten unsere Systeme bei Amazon Web Services, Inc., 410 Terry
                Avenue North, Seattle WA 98109, USA („AWS“) ein. Aus technischen Gründen
                kann es vorkommen, dass die Infrastruktur von den USA aus gewartet wird.
                AWS hat sich dem EU-US Privacy Shield unterworfen.
            </h4>
            <h4>
                Rechtsgrundlage der vorgenannten Datenverarbeitung ist Art. 6 Abs. 1 f)
                DSGVO beruhend auf unserem berechtigten Interesse. Wir wollen Ihnen
                damit die technische Infrastruktur bereitstellen, um unsere Produkte und
                Dienstleistungen anbieten zu können.
            </h4>
            <h4>
                Nähere Informationen hierzu finden Sie in der Datenschutzerklärung von
                AWS.
            </h4>
            <h2>
                3. Recht auf Auskunft, Berichtigung, Löschung, Einschränkung,
                Widerspruch und Datenübertragbarkeit
            </h2>
            <h3>3.1 Recht auf Auskunft (Art. 15 DS-GVO)</h3>
            <h4>
                Der Betreiber erteilt Ihnen auf Antrag Auskunft darüber, ob er Sie
                betreffende Daten verarbeitet. Der Betreiber bemüht sich, Anfragen auf
                Auskunft zügig zu bearbeiten.
            </h4>
            <h3>3.2 Recht auf Berichtigung (Art. 16 DS-GVO)</h3>
            <h4>
                Sie haben das Recht, von dem Verantwortlichen unverzüglich die
                Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu
                verlangen.
            </h4>
            <h3>3.3 Recht auf Löschung (Art. 17 DS-GVO)</h3>
            <h4>
                Sie haben das Recht, von dem Betreiber zu verlangen, dass Sie
                betreffende personenbezogene Daten unverzüglich gelöscht werden, und der
                Betreiber ist verpflichtet, personenbezogene Daten unverzüglich zu
                löschen, sofern einer der in Art. 17 Abs. 1 a)-f) DS-GVO genannten
                Gründe zutrifft.
            </h4>
            <h3>3.4 Recht auf Einschränkung(Art. 18 DS-GVO)</h3>
            <h4>
                Sie haben das Recht, vom Betreiber die Einschränkung der Verarbeitung zu
                verlangen, wenn eine der Voraussetzungen des Art. 18 Abs. 1 a)-d) DS-GVO
                gegeben ist.
            </h4>
            <h3>3.5 Recht auf Widerspruch(Art. 21 DS-GVO)</h3>
            <h4>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
                personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 e) oder f)
                DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling. Der Betreiber verarbeitet Ihre
                personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
                schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
                dient der Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen.
            </h4>
            <h4>
                Werden Ihre personenbezogene Daten verarbeitet, um Direktwerbung zu
                betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
                Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
                derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
                es mit solcher Direktwerbung in Verbindung steht.
            </h4>
            <h4>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                Situation ergeben, gegen die Sie betreffende Verarbeitung Sie
                betreffender personenbezogener Daten, die zu wissenschaftlichen oder
                historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art.
                89 Abs. 1 DS-GVO erfolgt, Widerspruch einzulegen, es sei denn, die
                Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden
                Aufgabe erforderlich.
            </h4>
            <h4>
                Für Ihre Mitteilung verwenden Sie bitte die in Allgemeines angegebene
                Kontaktadresse.
            </h4>
            <h3>3.6 Recht auf Datenübertragbarkeit (Art. 20 DS-GVO)</h3>
            <h4>
                Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
                Sie dem Betreiber bereitgestellt haben, in einem strukturierten,
                gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das
                Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch
                den Betreiber, dem die personenbezogenen Daten bereitgestellt wurden, zu
                übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäß Art. 6
                Abs. 1 a) DS-GVO, Art. 9 Abs. 2 a) DS-GVO oder auf einem Vertrag gemäß
                Art. 6 Abs. 1 b) DS-GVO beruht und die Verarbeitung mithilfe
                automatisierter Verfahren erfolgt.
            </h4>
            <h2>4. Widerruf Ihrer Einwilligung</h2>
            <h4>
                Sofern Sie eine Einwilligung in die Verarbeitung Ihrer personenbezogenen
                Daten erteilt haben und diese widerrufen, bleibt die bis zum Zeitpunkt
                dieses Widerrufs erfolgte Verarbeitung hiervon unberührt.
            </h4>
            <h2>5. Beschwerderecht</h2>
            <h4>
                Sie haben jederzeit das Recht, sich bei der zuständigen Aufsichtsbehörde
                zu beschweren.
            </h4>
            <h2>6. Empfänger</h2>
            <h4>
                Die bei Aufruf und Nutzung der Webseite erhobenen Daten und die von
                Ihnen bei der Regestrierung gemachten Angaben werden an den Server des
                Betreibers übermittelt und dort gespeichert. Im Übrigen können Ihre
                Daten an folgende Kategorien von Empfängern weitergegeben werden:
            </h4>
            <h4>
                Personen beim Verantwortlichen, die mit der Verarbeitung befasst sind /
                interne Stellen
                <br/>
                Auftragsverarbeiter (z.B. Rechenzentrum, Softwaresupport)
            </h4>
            <h2>7. Links zu Drittseiten</h2>
            <h4>
                Beim Besuch der Webseite könnten Inhalte angezeigt werden, die mit den
                Webseiten Dritter verlinkt sind. Der Betreiber hat keinen Zugang zu den
                Cookies oder anderen Funktionen, die von Drittseiten eingesetzt werden,
                noch kann der Betreiber diese kontrollieren. Solche Drittseiten
                unterliegen nicht den Datenschutzbestimmungen des Betreibers.
            </h4>
        </Paper>
    );
};

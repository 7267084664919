import { API, graphqlOperation } from 'aws-amplify';
import { ListSessionsQuery } from '../API';
import { listSessions } from '../graphql/queries';
import { ISession } from '../models/interfaces/session-interface';
import { GraphQLResult } from '@aws-amplify/api';
import {
  createSession,
  deleteSession,
  updateSession,
} from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import { listSessionsMapper } from './mapper/session-mapper';

export const listSessionsByEventId = async (
  eventId: string
): Promise<ISession[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listSessions, {
      filter: {
        EventId: {
          eq: eventId,
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListSessionsQuery>;

  const sessions: ISession[] = await listSessionsMapper(retrievedList);

  return sessions;
};

export const listSessionsByEventIdScheduled = async (
  eventId: string
): Promise<ISession[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listSessions, {
      filter: {
        EventId: {
          eq: eventId,
        },
        Starttime: {
          ne: '',
        },
        Endtime: {
          ne: '',
        },
      },
      limit: 5000,
    })
  )) as GraphQLResult<ListSessionsQuery>;

  const sessions: ISession[] = await listSessionsMapper(retrievedList);

  return sessions;
};

export const listSessionsBySpeakerId = async (
  speakerId: string
  ): Promise<ISession[]> => {
    let retrievedList = (await API.graphql(
      graphqlOperation(listSessions, {
        filter: {
          Speaker: {
            contains: speakerId,
          },
        },
        limit: 5000,
      })
    )) as GraphQLResult<ListSessionsQuery>;
  
    const sessions: ISession[] = await listSessionsMapper(retrievedList);
  
    return sessions;
  };

export const createNewSession = async (session: ISession) => {
  await API.graphql(
    graphqlOperation(createSession, {
      input: {
        Canceled: session.canceled,
        Creator: session.creator,
        Description: session.description,
        Duration: session.duration,
        Endtime: session.endtime,
        EventId: session.eventId,
        Extern: session.extern,
        Id: session.id,
        Mandatory: session.mandatory,
        RoomId: session.roomId,
        Speaker: session.speaker,
        Starttime: session.starttime,
        Tags: session.tags,
        Title: session.title,
        Type: session.type,
      },
    })
  );
};

export const deleteSessionById = async (sessionId: string, eventId: string) => {
  await API.graphql(
    graphqlOperation(deleteSession, {
      input: { Id: sessionId, EventId: eventId },
    })
  );
};

export const updateSessionFull = async (session: ISession) => {
  await API.graphql(
    graphqlOperation(updateSession, {
      input: {
        Canceled: session.canceled,
        Creator: session.creator,
        Description: session.description,
        Duration: session.duration,
        Endtime: session.endtime,
        EventId: session.eventId,
        Extern: session.extern,
        Id: session.id,
        Mandatory: session.mandatory,
        RoomId: session.roomId,
        Speaker: session.speaker,
        Starttime: session.starttime,
        Tags: session.tags,
        Title: session.title,
        Type: session.type,
      },
    })
  );
};

export const subscriptionCreateSession = (EventId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onCreateSession, { EventId })
  );
};

export const subscriptionDeleteSession = (EventId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onDeleteSession, { EventId })
  );
};

export const subscriptionUpdateSession = (EventId: string) => {
  return API.graphql(
    graphqlOperation(subscriptions.onUpdateSession, { EventId })
  );
};

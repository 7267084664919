import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '70rem',
    },
    container: {
      margin: '1% 2% 0% 2%',
      width: 'auto',
      overflow: 'hidden',
    },
    table: {
      tableLayout: 'fixed',
      '& th': {
        width: 'auto',
        padding: '16px 0px 16px 8px',
        letterSpacing: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& td': {
        textOverflow: 'ellipsis',
        width: 'auto',
        overflow: 'hidden',
        padding: '16px 0px 16px 8px',
        letterSpacing: 0,
        cursor: 'pointer',
      },
    },
    formControl: {
      minWidth: 120,
      display: 'block',
    },
    select: {
      height: '2.5rem',
      margin: '1rem 1rem 1rem 0rem',
    },
    scrollContainer: {
      display: 'block',
      minHeight: 'auto',
      height: 'auto',
    },
  })
);

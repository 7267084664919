import { createTheme } from '@mui/material/styles';

export const customTheme = (primaryColor: string, secondaryColor: string) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: primaryColor || '#64A70B',
                contrastText: '#fff',
            },
            secondary: {
                main: secondaryColor || '#3F4444',
            },
            background: {
                default: '#ffffff',
            },
        },
    });
    return theme;
};

export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#3F4444',
        },
        secondary: {
            main: '#64A70B',
        },
        background: {
            default: '#ffffff',
        },
    },
});

import { API, graphqlOperation } from 'aws-amplify';
import { getOrga } from '../graphql/queries';
import { createOrga, updateOrga } from '../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { GetOrgaQuery } from '../API';
import { IOrganization } from '../models/interfaces/organization-interface';
import * as subscriptions from '../graphql/subscriptions';
import { orgaGetByIdMapper } from './mapper/orga-mapper';

export const getOrgaByGroup = async (
  groupId: string
): Promise<IOrganization | null> => {
  if (
    groupId.substring(37, 42) === 'admin' ||
    groupId.substring(37, 41) === 'user' ||
    groupId.substring(37, 42) === 'owner'
  ) {
    const newOrga = (await API.graphql(
      graphqlOperation(getOrga, { Id: groupId.substring(0, 36) })
    )) as GraphQLResult<GetOrgaQuery>;

    return orgaGetByIdMapper(newOrga);
  } else {
    return null;
  }
};

/**
 * Service return null if no permission
 * @param groupId
 */
export const getOrgaByGroupAsAdmin = async (
  groupId: string
): Promise<IOrganization | null> => {
  if (groupId.substring(37, 42) !== 'admin') {
    return null;
  } else {
    const newOrga = (await API.graphql(
      graphqlOperation(getOrga, { Id: groupId.substring(0, 36) })
    )) as GraphQLResult<GetOrgaQuery>;
    return orgaGetByIdMapper(newOrga);
  }
};

export const updateOrgaFull = async (newData: IOrganization) => {
  await API.graphql(
    graphqlOperation(updateOrga, {
      input: {
        Id: newData.id,
        Name: newData.name,
        PrimaryColor: newData.primaryColor,
        SecondaryColor: newData.secondaryColor,
        CurrentEvent: newData.currentEvent,
      },
    })
  );
};

export const createNewOrga = async (newData: IOrganization) => {
  // TODO create groups
  await API.graphql(
    graphqlOperation(createOrga, {
      input: {
        Id: newData.id,
        Name: newData.name,
        PrimaryColor: newData.primaryColor,
        SecondaryColor: newData.secondaryColor,
        CurrentEvent: newData.currentEvent,
      },
    })
  );
};

export const subscriptionUpdateOrgaById = (Id: string) => {
  return API.graphql(graphqlOperation(subscriptions.onUpdateOrga, { Id }));
};

import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../images/CopeLogo.png';
import { useStyles } from './header-styles';
import { useTheme } from '@mui/material/styles';

interface IProps {
    setForcePWchange: (value: boolean) => void;
}

export const UnauthHeader = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await Auth.signOut();
    props.setForcePWchange(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            <img className={classes.logo} src={logo} alt='logo' />
          </Typography>
          <IconButton
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            color='inherit'
          >
            <AccountCircle fontSize='large' />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>{t('label.logout')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

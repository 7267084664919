import { GraphQLResult } from '@aws-amplify/api';
import {
  OnCreateRoomLinkSubscription,
  OnUpdateRoomLinkSubscription,
} from '../../API';
import { IRoomLink } from '../../models/interfaces/room-link-interface';

export const roomLinkCreateSubMapper = (
  input: GraphQLResult<OnCreateRoomLinkSubscription>
): IRoomLink => {
  const newRoomLink = {
    id: input.data?.onCreateRoomLink?.Id!,
    referenceId: input.data?.onCreateRoomLink?.ReferenceId!,
    label: input.data?.onCreateRoomLink?.Label!,
    link: input.data?.onCreateRoomLink?.Link!,
    acrossRoom: input.data?.onCreateRoomLink?.AcrossRoom!,
    activityDetail: input.data?.onCreateRoomLink?.ActivityDetail!,
  };
  return newRoomLink;
};

export const roomLinkUpdateSubMapper = (
  input: GraphQLResult<OnUpdateRoomLinkSubscription>
): IRoomLink => {
  const newRoomLink = {
    id: input.data?.onUpdateRoomLink?.Id!,
    referenceId: input.data?.onUpdateRoomLink?.ReferenceId!,
    label: input.data?.onUpdateRoomLink?.Label!,
    link: input.data?.onUpdateRoomLink?.Link!,
    acrossRoom: input.data?.onUpdateRoomLink?.AcrossRoom!,
    activityDetail: input.data?.onUpdateRoomLink?.ActivityDetail!,
  };
  return newRoomLink;
};

import {Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {IOrganization} from '../../models/interfaces/organization-interface';
import {useStyles} from './orga-list-manage-styles';

interface IProps {
    orgaList: IOrganization[] | undefined;
    setOnCreate: (onCreate: boolean) => void;
}

export const OrgaManageList = (props: IProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const [orgaList, setOrgaList] = useState<IOrganization[]>();

    useEffect(() => {
        props.orgaList && setOrgaList(props.orgaList);
    }, [props.orgaList]);

    const handleDetails = (orga: IOrganization) => {
        navigate('/manage-orga', {state: {orga: orga}});
    };

    return (
        <div>
            {orgaList !== undefined ? (
                <Grid container spacing={0} className={classes.root}>
                    {props.orgaList!.map((orga) => (
                        <Grid item key={orga!.id} className={classes.innerGrid}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography gutterBottom variant='h6'>
                                        {orga?.name}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        onClick={() => handleDetails(orga)}
                                    >
                                        {t('label.details')}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <div>
                    <CircularProgress/>
                </div>
            )}
            <div className={classes.create}>
                <Button variant='contained' onClick={() => props.setOnCreate(true)}>
                    {t('label.createOrga')}
                </Button>
            </div>
        </div>
    );
};

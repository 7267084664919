import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as SessionMapper from '../../services/mapper/session-mapper';
import * as BlockerMapper from '../../services/mapper/blocker-mapper';
import { IRoom } from '../../models/interfaces/room-interface';
import { ISession } from '../../models/interfaces/session-interface';
import { IBlocker } from '../../models/interfaces/blocker-interface';
import { IRoomLink } from '../../models/interfaces/room-link-interface';
import { ICalendarEvent } from '../../models/interfaces/calendar-event-interface';
import { useGlobalContext } from '../../libs/context-lib';
import { DialogMeetingDetails } from './dialogs/dialog-meeting-details';
import { IUser } from '../../models/interfaces/user-interface';
import * as BlockerService from '../../services/blocker-service';
import * as SessionService from '../../services/session-service';
import { parseISODateToTime } from '../../utils/iso-timepicker-converter';
import { IVote } from '../../models/interfaces/vote-interface';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import BoxIcon from '@mui/icons-material/Stop';

interface IProps {
  oragUsers: IUser[];
  editMode: boolean;
  sessions: ISession[];
  blockers: IBlocker[];
  room?: IRoom;
  rooms: IRoom[];
  roomlinks: IRoomLink[];
  filterVotes?: boolean;
  votes?: IVote[];
}

const CalendarStyleWrapper = styled('div')({
  '& .fc-event': {
    cursor: 'pointer',
  },
});

export const EventCalendar = (props: IProps) => {
  const { currentEvent, userInfo } = useGlobalContext();
  const [roomLinks, setRoomLinks] = useState<IRoomLink[]>([]);
  const [sortedRooms, setSortedRooms] = useState<IRoom[]>([]);
  const [sessionEvents, setSessionEvents] = useState<ICalendarEvent[]>([]);
  const [blockerEvents, setBlockerEvents] = useState<ICalendarEvent[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvent[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<ISession>();
  const [filterView, setFilterView] = useState<boolean>(false);
  const colorCollection: string[] = [
    'rgb(139, 191, 118, 0.7)',
    'rgb(176,224,230, 0.7)',
    'rgb(240,230,140, 0.7)',
    '#000000',
    '#0000ff',
    '#a52a2a',
    '#00ffff',
    '#00008b',
    '#008b8b',
    '#a9a9a9',
    '#006400',
  ];

  //useEffect block handles mapping from DynamoDB entries to calendar entries
  useEffect(() => {
    let aggregatedEvents: ICalendarEvent[] = [];

    sessionEvents.forEach((sessionEvent) =>
      aggregatedEvents.push(Object.assign({}, sessionEvent))
    );
    blockerEvents.forEach((blockerEvent) =>
      aggregatedEvents.push(Object.assign({}, blockerEvent))
    );
    setCalendarEvents(aggregatedEvents);
  }, [sessionEvents, blockerEvents]);

  useEffect(() => {
    if (props.room !== undefined) {
      aggregateEventsFromBlockers(props.blockers, props.room);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.blockers, props.room]);

  useEffect(() => {
    if (props.rooms !== undefined) {
      setSortedRooms(props.rooms.sort((n1,n2) => n1.id > n2.id ? 1 : -1));
    }
  }, [props.rooms]);

  useEffect(() => {
    aggregateEventsFromSessions(props.sessions, roomLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sessions, roomLinks]);

  useEffect(() => {
    setRoomLinks(props.roomlinks);
  }, [props.roomlinks, props.room]);

  useEffect(() => {
    if (props.filterVotes !== undefined && props.filterVotes === true) {
      setFilterView(true);
    } else if (props.filterVotes === undefined) {
      setFilterView(false);
    }
  }, [props.filterVotes]);

  useEffect(() => {
    aggregateAllEventsFromBlockers(props.blockers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterVotes]);

  useEffect(() => {
    if (filterView && props.votes !== undefined) {
      aggregateFilteredEventsFromSessions(props.sessions, props.votes);
    } else {
      aggregateAllEventsFromSessions(props.sessions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterView]);

  useEffect(() => {}, [calendarEvents]);
  

  const aggregateEventsFromSessions = (
    sessions: ISession[],
    roomLinks: IRoomLink[]
  ) => {
    let mappedSessionEvents: ICalendarEvent[] = [];

    mappedSessionEvents = SessionMapper.mapSessionsToCalendarEvents(
      currentEvent.date,
      sessions,
      roomLinks,
      props.oragUsers
    );
    setSessionEvents(mappedSessionEvents);
  };

  const aggregateAllEventsFromSessions = (sessions: ISession[]) => {
    let mappedSessionEvents: ICalendarEvent[] = [];
    mappedSessionEvents = SessionMapper.mapSessionsToCalendarEventsGeneral(
      currentEvent.date,
      sessions,
      props.oragUsers
    );

    setSessionEvents(mappedSessionEvents);
  };

  const aggregateFilteredEventsFromSessions = (
    sessions: ISession[],
    votes: IVote[]
  ) => {
    let mappedSessionEvents: ICalendarEvent[] = [];
    let relevantSessions: ISession[] = [];

    sessions.forEach((session) => {
      votes.forEach((vote) => {
        if (session.id === vote.sessionId && userInfo.sub === vote.userId) {
          relevantSessions.push(session);
        }
      });
      if (
        !relevantSessions.includes(session) &&
        session.speaker.includes(userInfo.sub)
      ) {
        relevantSessions.push(session);
      }
    });

    mappedSessionEvents = SessionMapper.mapSessionsToCalendarEventsGeneral(
      currentEvent.date,
      relevantSessions,
      props.oragUsers
    );

    setSessionEvents(mappedSessionEvents);
  };

  const aggregateEventsFromBlockers = (blockers: IBlocker[], room: IRoom) => {
    let currentRoomBlockerEvents: ICalendarEvent[] = [];
    currentRoomBlockerEvents = BlockerMapper.mapBlockersToCalendarEvents(
      currentEvent.date,
      blockers,
      room.id
    );

    setBlockerEvents(currentRoomBlockerEvents);
  };

  const aggregateAllEventsFromBlockers = (blockers: IBlocker[]) => {
    let allBlockerEvents: ICalendarEvent[] = [];
    allBlockerEvents = BlockerMapper.mapBlockersToCalendarEventsGeneral(
      currentEvent.date,
      blockers
    );

    setBlockerEvents(allBlockerEvents);
  };

  const personalizeEvents = (eventList: ICalendarEvent[]) => {
    eventList.forEach((event) => {
      event.speaker.forEach((speaker) => {
        if (speaker === userInfo.sub) {
          event.backgroundColor = 'rgb(139, 191, 118, 0.7)';
          event.borderColor = 'rgb(139, 191, 118, 0.7)';
          event.textColor = 'black';
        }
      });
      if (props.editMode || props.room === undefined) {
        event.editable = props.editMode;
        event.startEditable = props.editMode;
        event.backgroundColor =
          colorCollection[
            props.rooms.map((room) => room.id).indexOf(event.roomId) %
              colorCollection.length
          ];
        event.borderColor =
          colorCollection[
            props.rooms.map((room) => room.id).indexOf(event.roomId) %
              colorCollection.length
          ];
        event.textColor = 'black';

        if (event.mandatory) {
          event.textColor = 'white';
        }
      } else {
        if (event.mandatory) {
          event.backgroundColor = 'rgb(255, 143, 3)';
          event.borderColor = 'rgb(255, 143, 3)';
          event.textColor = 'white';
        }
      }
      if (event.canceled) {
        event.backgroundColor = 'rgb(236, 148, 136)';
        event.borderColor = 'rgb(236, 148, 136)';
        event.textColor = 'black';
      }
    });

    return eventList;
  };

  const handleOpenDetails = (eventInfo: any) => {
    setSelectedSession(
      props.sessions.filter((session) => session.id === eventInfo.event.id)[0]
    );
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const handleUpdateChangedTimes = (eventInfo: any) => {
    const updatedEvent = eventInfo.event;

    if (updatedEvent.extendedProps.speaker.length === 0) {
      const updatedBlocker = props.blockers.filter(
        (blocker) => blocker.id === updatedEvent.id
      )[0];
      updatedBlocker.starttime = parseISODateToTime(updatedEvent.start);
      updatedBlocker.endtime = parseISODateToTime(updatedEvent.end);
      BlockerService.updateBlockerFull(updatedBlocker);
    } else {
      const updatedSession = props.sessions.filter(
        (session) => session.id === updatedEvent.id
      )[0];
      updatedSession.starttime = parseISODateToTime(updatedEvent.start);
      updatedSession.endtime = parseISODateToTime(updatedEvent.end);
      SessionService.updateSessionFull(updatedSession);
    }
  };

  const handleUpdatedSessionRoom = (session: ISession) => {
    SessionService.updateSessionFull(session);

    let updatedCalendarSessions: ICalendarEvent[] = [];
    sessionEvents.forEach((sessionEvent) => {
      if (sessionEvent.id === session.id) {
        sessionEvent.roomId = session.roomId;
      }
      updatedCalendarSessions.push(Object.assign({}, sessionEvent));
    });
    setSessionEvents(updatedCalendarSessions);
  };

  return (
    <div>
      {props.sessions.length !== 0 && props.sessions !== undefined ? (
        <CalendarStyleWrapper>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              //left: '',
              center: '',
              right: '',
            }}
            initialView='timeGridDay'
            snapDuration='00:05:00'
            slotDuration='00:10:00'
            slotLabelInterval='01:00'
            slotMinTime={
              currentEvent.starttime ? currentEvent.starttime : '07:00:00'
            }
            slotMaxTime={
              currentEvent.endtime ? currentEvent.endtime : '18:00:00'
            }
            allDaySlot={false}
            height='auto'
            nowIndicator={true}
            displayEventTime={true}
            initialDate={
              currentEvent.date
                ? new Date(currentEvent.date).toISOString()
                : new Date().toISOString()
            }
            events={personalizeEvents(calendarEvents)}
            eventClick={handleOpenDetails}
            eventDrop={handleUpdateChangedTimes}
            eventOrder={'roomId'}
          />
          {selectedSession !== undefined ? (
            <DialogMeetingDetails
              oragUsers={props.oragUsers}
              editMode={props.editMode}
              open={showDetails}
              rooms={props.rooms}
              handleClose={() => handleCloseDetails()}
              handleRoomChange={handleUpdatedSessionRoom}
              session={selectedSession}
              buttonLinks={props.roomlinks.filter(
                (roomlink) =>
                  roomlink.activityDetail &&
                  roomlink.referenceId === selectedSession.roomId
              )}
            />
          ) : (
            <></>
          )}
          {(props.editMode || props.room === undefined) && (
            <div style={{ display: 'inline-flex' }}>
              {sortedRooms.map((roomItem) => (
                <React.Fragment key={roomItem.id + '-fragment'}>
                  <Typography style={{ marginRight: '1rem' }}>
                    {roomItem.name}{' '}
                    <BoxIcon
                      style={{
                        color:
                          colorCollection[
                            props.rooms.findIndex(
                              (room) => room.id === roomItem.id
                            )
                          ],
                      }}
                    />
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          )}
        </CalendarStyleWrapper>
      ) : (
        <></>
      )}
    </div>
  );
};

import { GraphQLResult } from '@aws-amplify/api';
import {
  OnCreateEventSubscription,
  OnUpdateEventIdFilterSubscription,
  OnUpdateEventSubscription,
} from '../../API';
import { IEvent } from '../../models/interfaces/event-interface';

export const eventUpdateSubMapper = (
  input: GraphQLResult<OnUpdateEventIdFilterSubscription>
): IEvent => {
  const newEvent = {
    id: input.data?.onUpdateEventIdFilter?.Id!,
    eventStatus: input.data?.onUpdateEventIdFilter?.EventStatus!,
    date: input.data?.onUpdateEventIdFilter?.Date!,
    organizationId: input.data?.onUpdateEventIdFilter?.OrganisationId!,
    starttime: input.data?.onUpdateEventIdFilter?.Starttime!,
    endtime: input.data?.onUpdateEventIdFilter?.Endtime!,
    votingClosed: input.data?.onUpdateEventIdFilter?.VotingClosed!,
    submitClosed: input.data?.onUpdateEventIdFilter?.SubmitClosed!,
    archived: input.data?.onUpdateEventIdFilter?.Archived!,
  };
  return newEvent;
};

export const eventUpdateSubByOrgaMapper = (
  input: GraphQLResult<OnUpdateEventSubscription>
): IEvent => {
  const newEvent = {
    id: input.data?.onUpdateEvent?.Id!,
    eventStatus: input.data?.onUpdateEvent?.EventStatus!,
    date: input.data?.onUpdateEvent?.Date!,
    organizationId: input.data?.onUpdateEvent?.OrganisationId!,
    starttime: input.data?.onUpdateEvent?.Starttime!,
    endtime: input.data?.onUpdateEvent?.Endtime!,
    votingClosed: input.data?.onUpdateEvent?.VotingClosed!,
    submitClosed: input.data?.onUpdateEvent?.SubmitClosed!,
    archived: input.data?.onUpdateEvent?.Archived!,
  };
  return newEvent;
};

export const eventCreateSubByOrgaMapper = (
  input: GraphQLResult<OnCreateEventSubscription>
): IEvent => {
  const newEvent = {
    id: input.data?.onCreateEvent?.Id!,
    eventStatus: input.data?.onCreateEvent?.EventStatus!,
    date: input.data?.onCreateEvent?.Date!,
    organizationId: input.data?.onCreateEvent?.OrganisationId!,
    starttime: input.data?.onCreateEvent?.Starttime!,
    endtime: input.data?.onCreateEvent?.Endtime!,
    votingClosed: input.data?.onCreateEvent?.VotingClosed!,
    submitClosed: input.data?.onCreateEvent?.SubmitClosed!,
    archived: input.data?.onCreateEvent?.Archived!,
  };
  return newEvent;
};

export const eventToEntityMapper = (event: IEvent) => {
  const mappedEvent = {
    Id: event.id,
    OrganisationId: event.organizationId,
    EventStatus: event.eventStatus,
    Date: event.date,
    Starttime: event.starttime,
    Endtime: event.endtime,
    SubmitClosed: event.submitClosed,
    VotingClosed: event.votingClosed,
    Archived: event.archived
  };

  return mappedEvent;
}

export const eventListToEntityListMapper = (eventList: IEvent[]) => {
  const mappedEvents: any = [];

  eventList.forEach((event) => {
    mappedEvents.push({
      Id: event.id,
      OrganisationId: event.organizationId,
      EventStatus: event.eventStatus,
      Date: event.date,
      Starttime: event.starttime,
      Endtime: event.endtime,
      SubmitClosed: event.submitClosed,
      VotingClosed: event.votingClosed,
      Archived: event.archived
    })
  })

  return mappedEvents;
}
import React, { FormEvent, useState } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useStyles } from './change-password-styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface IProps {
  isLoading: boolean;
  handleSubmit: (oldPassword: string, password: string) => void;
}

export const ChangePassword = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();

  const [values, setValues] = useState({
    password: '',
    passwordConfirm: '',
    oldPassword: '',
    isLoading: false,
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.handleSubmit(values.oldPassword, values.password);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div>
          <TextField
            label={t('label.oldPassword')}
            variant='outlined'
            autoFocus
            type='password'
            value={values.oldPassword}
            autoComplete='old-password'
            onChange={(e) =>
              setValues({ ...values, oldPassword: e.target.value })
            }
          />
        </div>
        <div>
          <TextField
            label={t('label.newPassword')}
            variant='outlined'
            value={values.password}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            autoComplete='new-password'
            type='password'
          />
        </div>
        <div>
          <TextField
            label={t('label.confirmPassword')}
            variant='outlined'
            value={values.passwordConfirm}
            onChange={(e) =>
              setValues({ ...values, passwordConfirm: e.target.value })
            }
            autoComplete='password-confirm'
            type='password'
          />
        </div>
        <div className={classes.confirmButton}>
          {props.isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              type='submit'
              disabled={values.password !== values.passwordConfirm}
            >
              {t('label.changePassword')}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

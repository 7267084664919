import {
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useStyles } from './orga-list-styles';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow } from './orga-list-styles';
import { useGlobalContext } from '../../libs/context-lib';
import { IOrganization } from '../../models/interfaces/organization-interface';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import { DialogDeleteConfirm } from '../shared/confirm-delete-dialog';

interface IProps {
  orgas: IOrganization[];
  handleRemoveFromOrga: (orgaPool: string) => Promise<boolean>;
}

export const OrgaListTable = (props: IProps) => {
  const classes = useStyles();
  const { userInfo } = useGlobalContext();
  const { t } = useTranslation();
  const [orgas, setOrgas] = useState<IOrganization[]>([]);
  const [toRemoveGroup, setToRemoveGroup] = useState('');

  useEffect(() => {
    setOrgas([...props.orgas]);
  }, [props.orgas]);

  const handleCloseLeaveDialog = (value: boolean) => {
    value && props.handleRemoveFromOrga(toRemoveGroup);
    setToRemoveGroup('');
  };

  return (
    <div
      style={{
        textAlign: 'center',
        position: 'relative',
        paddingTop: '2rem',
        paddingBottom: '3rem',
      }}
    >
      {orgas.length > 0 && (
        <>
          <div>Meine Mitgliedschaften</div>
          <Grid container className={classes.root}>
            <Grid item md={2} lg={2}></Grid>
            <Grid xs={12} md={8} lg={8} item>
              <TableContainer component={Paper}>
                <Table aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {t('label.organization')}
                      </StyledTableCell>
                      <StyledTableCell>{t('label.role')}</StyledTableCell>
                      <StyledTableCell>{t('label.requestKey')}</StyledTableCell>
                      <StyledTableCell
                        style={{ width: '3rem' }}
                      ></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userInfo.groups.map((group) => (
                      <React.Fragment key={group + '-fragment'}>
                        {group.substring(37, 44) === 'admin' ||
                        group.substring(37, 44) === 'user' ? (
                          <StyledTableRow key={group}>
                            <StyledTableCell component='th' scope='row'>
                              {
                                orgas.filter(
                                  (orga) => orga.id === group.substring(0, 36)
                                )[0]?.name
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {t(`label.${group.substring(37, 44)}`)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {group.substring(0, 36)}
                            </StyledTableCell>
                            <StyledTableCell>
                              <IconButton
                                onClick={() => {
                                  setToRemoveGroup(group);
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={2} lg={2}></Grid>
          </Grid>
          {toRemoveGroup !== '' && (
            <DialogDeleteConfirm
              open={true}
              handleClose={handleCloseLeaveDialog}
              title={t('label.deleteParticipation')}
              text={t('text.deleteParticipation')}
            />
          )}
        </>
      )}
      <div>
        <Link href='#/privacy-policy' variant="body2" target='_blank'>
          {t('label.privacyPolicy')}
        </Link>
      </div>
    </div>
  );
};

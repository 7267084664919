import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useStyles } from './my-blocker-styles';
import { useTranslation } from 'react-i18next';
import { IBlocker } from '../../../models/interfaces/blocker-interface';
import Transition from '../../../utils/transition';
import { EditableTable } from '../../shared/editable-table';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalContext } from '../../../libs/context-lib';
import { useTheme } from '@mui/material/styles';

interface IProps {
  blockers: IBlocker[];
  handleClose: () => void;
  handleUpdateData: (newRow: IBlocker) => void;
  handleCreateData: (newRow: IBlocker) => void;
  handleDeleteData: (row: IBlocker) => void;
}

const cols = [
  { prop: 'name', type: 'text' },
  { prop: 'starttime', type: 'time' },
  { prop: 'endtime', type: 'time' },
];

export const DialogEventBlocker = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { t } = useTranslation();
  const { currentOrga } = useGlobalContext();
  const [blockers, setBlockers] = useState<IBlocker[]>([]);

  useEffect(() => {
    setBlockers(props.blockers);
  }, [props.blockers]);

  const handleAddRow = (): IBlocker => {
    const newData = {
      id: uuidv4(),
      eventId: currentOrga.currentEvent,
      name: '',
      starttime: '12:00',
      endtime: '13:00',
      userId: '',
    } as IBlocker;

    setBlockers([...blockers!, newData]);

    return newData;
  };

  const updateBlockerData = (newRow: IBlocker, oldRow: IBlocker) => {
    const newData: any = [];
    // deep copy
    blockers.forEach((val) => newData.push(Object.assign({}, val)));
    const index = newData.findIndex((x: IBlocker) => x.id === oldRow.id);
    if (index !== -1) {
      newData[index] = newRow;
      setBlockers(newData);
    }
  };

  return (
    <>
      {props.blockers !== undefined ? (
        <Dialog
          fullScreen
          open={true}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
                style={{ border: '1px solid' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6' className={classes.title}>
                {t('label.blocker')}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <div className={classes.content}>
              <EditableTable
                dataSource={blockers}
                columns={cols}
                addDataRow={handleAddRow}
                updateDataSource={setBlockers}
                handleUpdateData={(newRow: IBlocker, oldRow: IBlocker) => {
                  props.handleUpdateData(newRow);
                  updateBlockerData(newRow, oldRow);
                }}
                handleCreateData={(newRow: IBlocker, oldRow: IBlocker) => {
                  props.handleCreateData(newRow);
                  updateBlockerData(newRow, oldRow);
                }}
                handleDeleteData={props.handleDeleteData}
              />
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={true}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.handleClose}
                aria-label='close'
              >
                <ArrowBackIcon />
              </IconButton>
            </Toolbar>
            <div>...waiting for data</div>
          </AppBar>
        </Dialog>
      )}
    </>
  );
};

import {
  Avatar,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyIcon from '@mui/icons-material/VpnKey';
// import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import { useStyles } from './profile-styles';
import { useGlobalContext } from '../../libs/context-lib';
import { ProfilePwChangeDialog } from './dialogs/profile-pw-change-dialog';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EditIcon from '@mui/icons-material/Edit';
import { DialogChangeUsrData } from './dialogs/change-usr-data-dialog';
import { useTheme } from '@mui/material/styles';

interface IProps {
  setSubmittingNewPw: (value: boolean) => void;
  submittingNewPw: boolean;
  handleChangePw: (oldPassword: string, newPassword: string) => void;
  handleDeleteProfile: () => void;
  pwRefreshFinished: boolean;
  userTagsArray: string[] | undefined;
}

interface TagCount {
  label: string;
  count: number;
}

export const Profile = (props: IProps) => {
  const classes = useStyles(useTheme())();
  const { userInfo } = useGlobalContext();
  const [pwChangeDialog, setPwChangeDialog] = useState(false);
  const [userEditDialog, setUserEditDialog] = useState(false);
  const [canChangePw, setCanChangePw] = useState(true);
  const [tagMapArray, setTagMapArray] = useState<TagCount[]>();

  useEffect(() => {
    if (props.pwRefreshFinished) {
      setPwChangeDialog(false);
      alert('Das Passwort wurde geändert!');
    }
  }, [props.pwRefreshFinished]);

  useEffect(() => {
    userInfo.sub.substring(0, 6) === 'google' && setCanChangePw(false);
  }, [userInfo]);

  useEffect(() => {
    const newTagMapArr: TagCount[] = [];
    props.userTagsArray?.forEach((tag) => {
      let indexInArray = -1;
      for (let i = 0; i < newTagMapArr.length; i++) {
        if (newTagMapArr[i].label === tag) {
          indexInArray = i;
        }
      }

      indexInArray !== -1
        ? (newTagMapArr[indexInArray].count =
            newTagMapArr[indexInArray].count + 1)
        : newTagMapArr.push({ label: tag, count: 1 });
    });

    newTagMapArr.sort((a, b) => (a.count > b.count ? 1 : -1));

    const top5array: TagCount[] = [];

    if (newTagMapArr.length > 5) {
      for (let i = 0; i < 5; i++) {
        top5array.push({ ...newTagMapArr[i] });
      }
      setTagMapArray(top5array);
    } else {
      setTagMapArray(newTagMapArr);
    }
  }, [props.userTagsArray]);

  const handleClosePwChangeDialog = (oldPw: string, newPw: string) => {
    if (oldPw !== '') {
      props.setSubmittingNewPw(true);
      props.handleChangePw(oldPw, newPw);
    } else {
      setPwChangeDialog(false);
    }
  };

  return (
    <div style={{ textAlign: 'center', position: 'relative' }}>
      <Grid container>
        <Grid item sm={1}></Grid>
        <Grid item xs={12} md={canChangePw ? 7 : 10}>
          <Paper elevation={5} className={classes.paper_no_hover}>
            <Grid container>
              <Grid xs={12} md={4} item>
                <Avatar
                  src={userInfo.picture}
                  className={classes.avatar}
                ></Avatar>
              </Grid>
              <Grid xs={12} md={7} item className={classes.personalInfo}>
                <Typography variant='h5'>
                  {`${userInfo.given_name} ${userInfo.family_name}`}
                </Typography>
                <Typography variant='h6'>{userInfo.email}</Typography>
              </Grid>
              <Grid xs={12} md={1} item className={classes.editBtnGrid}>
                <IconButton onClick={() => setUserEditDialog(true)}>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {canChangePw && (
          <Grid item xs={12} md={3}>
            <Paper
              elevation={5}
              className={classes.paper}
              onClick={() => {
                setPwChangeDialog(true);
              }}
            >
              <p className={classes.p}>Change Pw label</p>
              <p>
                <KeyIcon style={{ fontSize: '4rem' }} />
              </p>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Grid item sm={1}></Grid>
      {props.userTagsArray !== undefined && (
        <Grid container>
          <Grid item sm={3}></Grid>
          {/* <Grid item xs={12} sm={5}>
            <Paper
              elevation={5}
              className={1 > 0 ? classes.paper : classes.paper_no_hover}
            >
              <Typography variant='h6'>Gehaltene Sessions</Typography>
              <Typography variant='h2'>Work in progress</Typography>
              {
                // 1 = spätere länge abfragen
              }
              {1 > 0 && (
                <ArrowRightSharpIcon
                  className={classes['paper-action-icon']}
                ></ArrowRightSharpIcon>
              )}
            </Paper>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Paper elevation={5} className={classes.paper_no_hover}>
              <Typography variant='h6'>Top Tags</Typography>
              <div style={{marginBottom: '1rem'}}>
                {tagMapArray?.map((tagMap) => (
                  <Chip
                    style={{ margin: '2px' }}
                    key={`${tagMap.label}-chip`}
                    label={tagMap.label + '(' + tagMap.count + ')'}
                    icon={<LocalOfferIcon />}
                  />
                ))}
              </div>
            </Paper>
            <Grid item sm={3}></Grid>
          </Grid>
        </Grid>
      )}
      <ProfilePwChangeDialog
        open={pwChangeDialog}
        handleClose={handleClosePwChangeDialog}
        submittingNewPw={props.submittingNewPw}
      />
      <DialogChangeUsrData
        open={userEditDialog}
        handleClose={() => setUserEditDialog(false)}
        handleDeleteProfile={props.handleDeleteProfile}
      />
    </div>
  );
};

import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import logo from '../../images/CopeLogo.png';
import { BurgerMenu } from './burger-menu';
import { useStyles } from './header-styles';
import { ProfileMenu } from './profile-menu';
import { useTheme } from '@mui/material/styles';

interface IProps {
  setHomeIndex: Function;
}

export const Header = (props: IProps) => {
  const classes = useStyles(useTheme())();

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <BurgerMenu {...props} />
          <Typography variant='h6' className={classes.title}>
            <img className={classes.logo} src={logo} alt='logo' />
          </Typography>
          <ProfileMenu {...props} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { IRoom } from '../models/interfaces/room-interface';
import { listRooms } from '../graphql/queries';
import { ListRoomsQuery } from '../API';
import { createRoom, deleteRoom, updateRoom } from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';

export const listRoomsByEventId = async (eventId: string): Promise<IRoom[]> => {
  let retrievedList = (await API.graphql(
    graphqlOperation(listRooms, {
      filter: {
        EventId: {
          eq: eventId,
        },
      },
      limit: 500,
    })
  )) as GraphQLResult<ListRoomsQuery>;

  const roomList: IRoom[] = [];

  // TODO call mapper
  if (retrievedList.data !== undefined && retrievedList.data !== null) {
    retrievedList.data?.listRooms!.items!.forEach((room) => {
      if (room?.Id)
        roomList.push({
          id: room!.Id,
          eventId: room!.EventId,
          name: room?.Name || '',
          seats: room?.Seats || 0,
        });
    });
  }

  roomList.sort((a, b) => (a.seats < b.seats ? 1 : -1));

  return roomList;
};

export const deleteRoomById = async (roomId: string, eventId: string) => {
  await API.graphql(
    graphqlOperation(deleteRoom, {
      input: { Id: roomId, EventId: eventId },
    })
  );
};

export const createNewRoom = async (room: IRoom) => {
  await API.graphql(
    graphqlOperation(createRoom, {
      input: {
        Id: room.id,
        EventId: room.eventId,
        Seats: room.seats,
        Name: room.name,
      },
    })
  );
};

export const updateRoomFull = async (room: IRoom) => {
  await API.graphql(
    graphqlOperation(updateRoom, {
      input: {
        Id: room.id,
        EventId: room.eventId,
        Seats: room.seats,
        Name: room.name,
      },
    })
  );
};

export const subscriptionDeleteRoomByEventId = (EventId: string) => {
  return API.graphql(graphqlOperation(subscriptions.onDeleteRoom, { EventId }));
};

export const subscriptionCreateRoomByEventId = (EventId: string) => {
  return API.graphql(graphqlOperation(subscriptions.onCreateRoom, { EventId }));
};

export const subscriptionUpdateRoomByEventId = (EventId: string) => {
  return API.graphql(graphqlOperation(subscriptions.onUpdateRoom, { EventId }));
};

import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '70rem',
      paddingBottom: '2rem',
    },
    tableContainer: {
      width: '96%',
      margin: '0.3rem 2% 4rem',
      backgroundColor: '#f6f6f6',
    },
    tableHeader: {
      marginTop: '2rem',
    },
    tableHead: {
      backgroundColor: '#D0D0D0'
    },
    deleteButton: {
      backgroundColor: 'red'
    },
    downgradeButton: {
      borderColor: 'red'
    },
    acceptButton: {
      borderColor: 'green'
    }
  })
);
